import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { ResponseError } from '../types/transaction-details';
import { AlertType, alertActions } from 'entities/Alert';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const sendCallback = createAsyncThunk<void, string, ThunkConfig<MyAxiosError<ResponseError>>>(
  'transactionDetails/sendCallback',
  async (id, { rejectWithValue, dispatch, extra }) => {
    try {
      const response = await extra.apiWithAuth.put(`/v1/callback/${id}/resend`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      dispatch(
        alertActions.setAlert({
          id: uuidv4(),
          message: 'Callback successfully sent!',
          timeout: 3000,
          type: AlertType.SUCCESS
        })
      );

      if (!response.data) {
        throw new Error();
      }
    } catch (e) {
      const error = e as MyAxiosError<ResponseError>;
      return rejectWithValue(error);
    }
  }
);
