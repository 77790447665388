interface GetFirstAndLastDaysOfMonth {
  firstDay: Date;
  lastDay: Date;
}

export const getFirstAndLastDaysOfMonth = (monthIndex: number, year: number): GetFirstAndLastDaysOfMonth | null => {
  if (monthIndex < 0 || monthIndex > 11) {
    return null;
  }

  const firstDate = new Date(year, monthIndex, 1);

  const lastDate = new Date(year, monthIndex + 1, 0);

  return {
    firstDay: firstDate,
    lastDay: lastDate
  };
};
