import { FC, useMemo } from 'react';
import styles from './TransactionStatus.module.scss';
import classNames from 'classnames';
import { Text, TextSize } from 'shared/ui/Text/Text';
import {
  TRANSACTIONS_STATUS,
  TRANSACTIONS_STATUS_CLASSES,
  TransactionsStatus
} from 'shared/constants/crypto/transactions-status';
import { WITHDRAWAL_STATUS, WITHDRAWAL_STATUS_CLASSES } from 'shared/constants/crypto/withdrawals-status';

export type TransactionStatusTheme = 'clear' | 'background';
export type TransactionStatusType = 'transactions' | 'withdrawal';

const themeClasses: Record<TransactionStatusTheme, string> = {
  background: styles.background,
  clear: styles.clear
};

interface TransactionStatusProps {
  className?: string;
  status?: string;
  size?: TextSize;
  theme?: TransactionStatusTheme;
  type?: TransactionStatusType;
}

const STATUSES: Record<TransactionStatusType, TransactionsStatus> = {
  transactions: TRANSACTIONS_STATUS,
  withdrawal: WITHDRAWAL_STATUS
};

const STATUS_CLASSES: Record<TransactionStatusType, TransactionsStatus> = {
  transactions: TRANSACTIONS_STATUS_CLASSES,
  withdrawal: WITHDRAWAL_STATUS_CLASSES
};

const TransactionStatus: FC<TransactionStatusProps> = (props) => {
  const { className, status, size = 'sizeM', theme = 'background', type = 'transactions' } = props;

  const mods = useMemo(() => {
    if (status) {
      return {
        [styles[STATUS_CLASSES[type][status]]]: status
      };
    }
  }, [status]);

  const classes = [className, themeClasses[theme]];

  return (
    <div className={classNames(styles.status, mods, classes)}>
      <Text size={size} className={styles.statusText}>
        {STATUSES[type][status!]}
      </Text>
    </div>
  );
};

export { TransactionStatus };
