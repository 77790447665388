import { FC, memo, useCallback, useEffect } from 'react';
import { Button } from 'shared/ui/Button/Button';
import classNames from 'classnames';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { AlertType, alertActions } from 'entities/Alert';
import { useSelector } from 'react-redux';
import { cryptoTransactionsActions } from 'pages/CryptoTransactionsPage/model/slices/cryptoTransaction';
import { downloadPaymentGateTransactionsCsv } from '../../model/services/downloadPaymentGateTransactionsCsv/downloadPaymentGateTransactionsCsv';
import styles from './DownloadCsvBtn.module.scss';
import {
  getIsPgTransactionsReporting,
  getIsPgTransactionsReportingSuccess,
  getPgTransactionsReportingError
} from 'pages/PaymentGatewayPage';

interface DownloadCsvButtonProps {
  disabled?: boolean;
  className?: string;
}

const DownloadCsvButton: FC<DownloadCsvButtonProps> = memo((props) => {
  const { disabled, className } = props;

  const dispatch = useAppDispatch();

  const isDownloading = useSelector(getIsPgTransactionsReporting);
  const isDownloadingSuccess = useSelector(getIsPgTransactionsReportingSuccess);
  const isDownloadingError = useSelector(getPgTransactionsReportingError);

  const downloadCsv = useCallback(() => {
    dispatch(downloadPaymentGateTransactionsCsv());
  }, [dispatch]);

  useEffect(() => {
    if (isDownloadingSuccess) {
      dispatch(cryptoTransactionsActions.resetCsvDownloading());
    } else if (isDownloadingError) {
      dispatch(
        alertActions.setAlert({
          id: crypto.randomUUID(),
          message: 'An error has occurred, please try again later',
          timeout: 5000,
          type: AlertType.ERROR
        })
      );
    }
  }, [isDownloadingSuccess, isDownloadingError, dispatch]);

  return (
    <Button
      size="sizeS"
      theme="third"
      radius="4px"
      className={classNames(styles.downloadCvs, [className])}
      onClick={downloadCsv}
      disabled={disabled || isDownloading}
      alignItems="center"
    >
      {disabled ? 'Downloading...' : 'Report'}
    </Button>
  );
});

export { DownloadCsvButton };
