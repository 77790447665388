import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import {
  getPaymentGatewayTransactionsHasMore,
  getPaymentGatewayTransactionsIsLoading,
  getPaymentGatewayTransactionsNumberOfPage
} from '../../selectors/paymentGatewayTransactions/payment-gateway';
import { getPaymentGatewayTransactions } from './getPaymentGatewayTransactions';
import { paymentGatewayTransactionsActions } from '../../slices/transactions/payment-gateway-transactions';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const fetchNextTransactionsPage = createAsyncThunk<void, { replace: boolean }, ThunkConfig<string>>(
  'fiatGateway/fetchNextTransactionsPage',
  async ({ replace }, { dispatch, getState }) => {
    const hasMore = getPaymentGatewayTransactionsHasMore(getState());
    const page = getPaymentGatewayTransactionsNumberOfPage(getState());
    const isLoading = getPaymentGatewayTransactionsIsLoading(getState());

    if (!isLoading && hasMore) {
      dispatch(paymentGatewayTransactionsActions.setPage(page + 1));
      dispatch(getPaymentGatewayTransactions({ page: page + 1, replace }));
    }
  }
);
