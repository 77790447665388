import { Option } from 'shared/ui/CustomSelect/CustomSelect';
import { GroupBase, StylesConfig } from 'react-select';

export const currencyFilterStyles: StylesConfig<Option, false, GroupBase<Option>> = {
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    position: 'initial',
    ':focus': {
      border: 0
    }
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    display: 'none'
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    '&>div': {
      padding: '0'
    }
  }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: 'transparent',
    border: 0,
    boxShadow: 'none',
    ':hover': {
      border: 0
    }
  }),
  singleValue: (provided, state) => ({
    ...provided,
    overflow: 'unset'
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0'
  }),
  menu: (provided, state) => ({
    ...provided,
    width: 'max-content',
    left: 0
  }),
  option: (provided, state) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  })
};
