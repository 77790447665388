import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CryptoTransactionsPageFiltrationSchema } from '../types/filtration';
import { DataFilterType } from 'entities/Transaction';

const initialState: CryptoTransactionsPageFiltrationSchema = {
  status: '',
  currency: '',
  date: {
    dateFrom: null,
    dateTo: null
  },
  isShowResetBtn: false,
  transactionIdSearch: ''
};

export const transactionFilters = createSlice({
  name: 'cryptoTransactions/filtration',
  initialState,
  reducers: {
    setResetBtnState: (state) => {
      if (state.currency || (state.date?.dateFrom && state.date.dateTo) || state.status) {
        state.isShowResetBtn = true;
      } else {
        state.isShowResetBtn = false;
      }
    },
    setStatusFilter: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
      transactionFilters.caseReducers.setResetBtnState(state);
    },
    setCurrencyFilter: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
      transactionFilters.caseReducers.setResetBtnState(state);
    },
    setDate: (state, action: PayloadAction<DataFilterType>) => {
      state.date = action.payload;
      transactionFilters.caseReducers.setResetBtnState(state);
    },
    setTransactionIdSearch: (state, action: PayloadAction<string>) => {
      state.transactionIdSearch = action.payload;
    },
    setResetFilters: (state) => {
      state.status = '';
      state.currency = '';
      state.date = undefined;
      state.isShowResetBtn = false;
      state.transactionIdSearch = '';
    }
  }
});

export const { actions: transactionFiltersActions } = transactionFilters;
export const { reducer: transactionFiltersReducer } = transactionFilters;
