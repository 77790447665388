import React, { ReactNode, memo } from 'react';
import { Button, ButtonSize, ButtonTheme } from 'shared/ui/Button/Button';
import styles from './ArrowButton.module.scss';
import classNames from 'classnames';

interface ArrowButtonProps {
  onClick?: () => void;
  disabled: boolean;
  children?: ReactNode;
  className?: string;
}

export const ArrowButton = memo((props: ArrowButtonProps) => {
  const { onClick, disabled, children, className } = props;

  return (
    <Button
      onClick={onClick}
      size="sizeM"
      theme="third"
      className={classNames(styles.button, className)}
      disabled={disabled}
    >
      {children}
    </Button>
  );
});
