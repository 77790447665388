import { FC, useCallback, useMemo } from 'react';
import { CustomSelect, Option } from 'shared/ui/CustomSelect/CustomSelect';
import { StatusFilterOption } from '../StatusFilterOption/StatusFilterOption';
import { ActionMeta } from 'react-select';
import { statusFilterStyles } from '../../lib/constants/select/styles/statusFilterStyles';
import {
  cryptoTransactionsStatuses,
  exchangeTransactionsStatuses,
  paymentGateWithdrawalsStatuses
} from 'entities/Transaction';
import { StatusSingleValue } from '../StatusSingleValue/StatusSingleValue';

export type TransactionsType = 'transactions' | 'withdrawals' | 'exchange' | 'paymentGateWithdrawals';

const transactionOptions: Record<TransactionsType, Option[]> = {
  transactions: cryptoTransactionsStatuses,
  withdrawals: cryptoTransactionsStatuses,
  paymentGateWithdrawals: paymentGateWithdrawalsStatuses,
  exchange: exchangeTransactionsStatuses
};

interface StatusFilterProps {
  className?: string;
  value?: string;
  onChange?: (status: string) => void;
  type?: TransactionsType;
}

const StatusFilter: FC<StatusFilterProps> = (props) => {
  const { value, onChange, type = 'transactions' } = props;

  const options = transactionOptions[type];

  const currentOption = useMemo(() => {
    return options.find((option) => option.value === value);
  }, [value, options]);

  const handleSelectChange = useCallback(
    (option: Option | null, actionMeta: ActionMeta<Option>) => {
      if (option) {
        onChange?.(option?.value);
      }
    },
    [onChange]
  );

  return (
    <CustomSelect<Option, false>
      id="status"
      isMulti={false}
      onChange={handleSelectChange}
      value={currentOption}
      options={options}
      styles={statusFilterStyles}
      components={{ Option: StatusFilterOption, SingleValue: StatusSingleValue }}
      isSearchable={false}
      placeholder=""
    />
  );
};

export { StatusFilter };
