import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const getNewExchangeEstimateState = (state: StateSchema) => state.newExchangeEstimate;

export const getIsNewExchangeEstimateLoading = createSelector(
  getNewExchangeEstimateState,
  (state) => state?.isLoading ?? false
);

export const getNewExchangeEstimateError = createSelector(
  getNewExchangeEstimateState,
  (state) => state?.error ?? undefined
);

export const getIsSuccessNewExchangeEstimate = createSelector(
  getNewExchangeEstimateState,
  (state) => state?.isSuccess ?? false
);

export const getNewExchangeEstimateData = createSelector(
  getNewExchangeEstimateState,
  (state) => state?.newExchangeEstimate
);
