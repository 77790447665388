import { FC } from 'react';
import { GroupBase, SingleValueProps, components } from 'react-select';
import { Option } from 'shared/ui/CustomSelect/CustomSelect';
import { CRYPTO_SHORT_NAME, CryptoName } from 'shared/constants/crypto/crypto-names';

interface CurrencySingleValueProps extends SingleValueProps<Option, false, GroupBase<Option>> {}

const AmountCurrencySingleValue: FC<CurrencySingleValueProps> = (props) => {
  const { data } = props;

  const currencyCode = data.value || 'All';

  return <components.SingleValue {...props}>&#40;{currencyCode}&#41;</components.SingleValue>;
};

export { AmountCurrencySingleValue };
