import { createSlice } from '@reduxjs/toolkit';
import { getTransactionById } from '../services/getTransactionById';
import { sendCallback } from '../services/sendCallback';
import { PaymentGatewayTransactionDetailsSchema } from '../types/transaction-details';
import { approveTransaction } from '../services/approveTransaction';
import { rejectTransaction } from '../services/rejectTransaction';

const initialState: PaymentGatewayTransactionDetailsSchema = {
  transactionData: undefined,
  error: undefined,
  isLoading: false,
  isSuccess: false,

  isCallbackSending: false,
  IsCallbackSuccess: false,
  callbackError: undefined,

  isTransactionApproving: false,
  isTransactionRejecting: false,
  isApprovingSuccess: false,
  transactionApproveError: undefined
};

export const paymentGatewayTransactionDetailsSlice = createSlice({
  name: 'paymentGatewayTransactionDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionById.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(getTransactionById.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.isSuccess = true;
        state.transactionData = action.payload;
      })
      .addCase(getTransactionById.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      })

      .addCase(sendCallback.pending, (state) => {
        state.isCallbackSending = true;
        state.IsCallbackSuccess = false;
        state.callbackError = undefined;
      })
      .addCase(sendCallback.fulfilled, (state) => {
        state.isCallbackSending = false;
        state.IsCallbackSuccess = true;
        state.callbackError = undefined;
      })
      .addCase(sendCallback.rejected, (state, action) => {
        state.isCallbackSending = false;
        state.IsCallbackSuccess = false;
        state.callbackError = action.payload;
      })

      .addCase(approveTransaction.pending, (state) => {
        state.isTransactionApproving = true;
        state.isApprovingSuccess = false;
        state.callbackError = undefined;
      })
      .addCase(approveTransaction.fulfilled, (state) => {
        state.isTransactionApproving = false;
        state.isApprovingSuccess = true;
        state.transactionApproveError = undefined;
      })
      .addCase(approveTransaction.rejected, (state, action) => {
        state.isTransactionApproving = false;
        state.isApprovingSuccess = false;
        state.transactionApproveError = action.payload;
      })

      .addCase(rejectTransaction.pending, (state) => {
        state.isTransactionRejecting = true;
        state.isApprovingSuccess = false;
        state.callbackError = undefined;
      })
      .addCase(rejectTransaction.fulfilled, (state) => {
        state.isTransactionRejecting = false;
        state.isApprovingSuccess = true;
        state.transactionApproveError = undefined;
      })
      .addCase(rejectTransaction.rejected, (state, action) => {
        state.isTransactionRejecting = false;
        state.isApprovingSuccess = false;
        state.transactionApproveError = action.payload;
      });
  }
});

export const { actions: paymentGatewayTransactionDetailsActions } = paymentGatewayTransactionDetailsSlice;
export const { reducer: paymentGatewayTransactionDetailsReducer } = paymentGatewayTransactionDetailsSlice;
