import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataToCreateNewInvoice, NewInvoiceSchema, ResponseError } from '../types/new-invoice';
import { createNewInvoice } from '../services/createNewInvoice';
import { MyAxiosError } from 'shared/types/axios';

const initialState: NewInvoiceSchema = {
  error: undefined,
  isLoading: false,
  dataCurrency: false,
  dataToCreateNewInvoice: undefined,
  isSuccess: false
};

export const newInvoiceSlice = createSlice({
  name: 'newInvoice',
  initialState,
  reducers: {
    setNewTransactionData: (state, action: PayloadAction<DataToCreateNewInvoice>) => {
      state.dataToCreateNewInvoice = {
        ...state.dataToCreateNewInvoice,
        ...action.payload
      };
    },
    setCurrency: (state, action: PayloadAction<{ dataCurrency: boolean }>) => {
      state.dataCurrency = action.payload.dataCurrency;
    },
    setResetData: (state) => {
      state.dataToCreateNewInvoice = undefined;
      state.newTransaction = undefined;
    },
    setError: (state, action: PayloadAction<MyAxiosError<ResponseError> | undefined>) => {
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewInvoice.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(createNewInvoice.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.isSuccess = true;
        state.newTransaction = action.payload;
      })
      .addCase(createNewInvoice.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      });
  }
});

export const { actions: newInvoiceActions } = newInvoiceSlice;
export const { reducer: newInvoiceReducer } = newInvoiceSlice;
