import React, { FC, useEffect, useState } from 'react';
import styles from './AppLayout.module.scss';
import classNames from 'classnames';
import { Navbar } from 'widgets/Navbar';
import { Sidebar, SidebarMobile } from 'widgets/Sidebar';
import { useWindowSize } from 'shared/lib/hooks/useWindowSize/useWindowSize';
import { getSignboardText } from 'shared/lib/utils';

interface AppLayoutProps {
  children?: React.ReactNode;
  title?: string;
}

const AppLayout: FC<AppLayoutProps> = ({ children, title }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const [windowWidth] = useWindowSize();

  useEffect(() => {
    if (title) {
      document.title = title;
    }
  }, [title]);

  const mods = {
    [styles.isSidebar]: !isSidebarOpen,
    [styles.isSticky]: isSticky
  };

  const overlayMods = {
    [styles.overlayOpened]: isSidebarOpen,
    [styles.overlayClosed]: !isSidebarOpen
  };

  const withSighboard = getSignboardText() ? styles.withSighboard : '';

  return (
    <div className={classNames(styles.appLayout, withSighboard)}>
      {windowWidth > 1400 ? (
        <Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      ) : (
        <SidebarMobile isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
      )}
      <div className={classNames(styles.wrapper, mods)}>
        <Navbar
          isSticky={isSticky}
          setIsSticky={setIsSticky}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        />
        <div className={classNames(styles.overlay, overlayMods)} onClick={() => setIsSidebarOpen(false)}></div>
        <main>
          <section className={styles.content}>{children}</section>
        </main>
      </div>
    </div>
  );
};

export { AppLayout };
