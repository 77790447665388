import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const getNewPaymentGatewayWithdrawalState = (state: StateSchema) => state.newPaymentGatewayWithdrawal;

export const getIsNewPaymentGatewayWithdrawalLoading = createSelector(
  getNewPaymentGatewayWithdrawalState,
  (state) => state?.isLoading ?? false
);

export const getNewPaymentGatewayWithdrawalError = createSelector(
  getNewPaymentGatewayWithdrawalState,
  (state) => state?.error ?? undefined
);

export const getIsSuccessNewPaymentGatewayWithdrawal = createSelector(
  getNewPaymentGatewayWithdrawalState,
  (state) => state?.isSuccess ?? false
);

export const getNewPaymentGatewayWithdrawalData = createSelector(
  getNewPaymentGatewayWithdrawalState,
  (state) => state?.newWithdrawal
);

export const getDataToCreateNewPaymentGatewayWithdrawal = createSelector(
  getNewPaymentGatewayWithdrawalState,
  (state) => state?.dataToCreateNewWithdrawal
);
