import { getRefreshTokens } from 'entities/User';
import {
  LOCAL_STORAGE_IS_REDIRECTED_TO_KEYCLOAK,
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_REFRESH_TOKEN,
  LOCAL_STORAGE_USER_DATA
} from 'shared/constants/localstorage/localstorage';
import { expiredTimeAccess } from 'shared/lib/jwt/expiredTimeAccess';
import { getAuthUrl } from './getAuthUrl';

export interface TokenChecker {
  accessToken: string | null;
  refreshToken: string | null;
}

export async function tokenChecker(): Promise<TokenChecker | null> {
  const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);

  if (accessToken && refreshToken) {
    const expTimeAccess = expiredTimeAccess(`${accessToken}`);
    const expTimeRefresh = expiredTimeAccess(`${refreshToken}`);

    // Check if accessToken is present and not expired
    if (accessToken && Date.now() < expTimeAccess!.exp * 1000) {
      return { accessToken, refreshToken };
    }

    // If accessToken is expired, check if refreshToken is present and not expired
    if (refreshToken && Date.now() < expTimeRefresh!.exp * 1000) {
      // Call function to update tokens

      const { access_token, refresh_token } = await getRefreshTokens();

      // Return new accessToken
      return {
        accessToken: access_token,
        refreshToken: refresh_token
      };
    }
  }
  // If neither token is present or both tokens are expired, clear localstorage and return null
  localStorage.removeItem(LOCAL_STORAGE_IS_REDIRECTED_TO_KEYCLOAK);
  localStorage.removeItem(LOCAL_STORAGE_USER_DATA);
  localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
  localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
  const authUrl = await getAuthUrl();
  window.location.href = authUrl;
  return null;
}
