import { FC, useCallback, useMemo } from 'react';
import { CustomSelect, Option } from 'shared/ui/CustomSelect/CustomSelect';
import { ActionMeta } from 'react-select';
import { currencyFilterStyles } from 'features/Table/lib/constants/select/styles/currencyFilterStyles';
import { TRANSACTIONS_PAYMENT_METHOD_NORMAL_NAME } from 'shared/constants/payment-methods/payment-method-types';
import { PaymentGatePaymentMethodSingleValue } from './PaymentGatePaymentMethodSingleValue/PaymentGatePaymentMethodSingleValue';

interface CurrencyFilterProps {
  className?: string;
  onChange?: (status: string) => void;
  value: string;
}

const PaymentGatePaymentMethodTableFilter: FC<CurrencyFilterProps> = (props) => {
  const { onChange, value } = props;

  const handleSelectChange = useCallback(
    (option: Option | null, actionMeta: ActionMeta<Option>) => {
      if (option) {
        onChange?.(option?.value);
      }
    },
    [onChange]
  );

  const selectOptions = useMemo<Option[]>(() => {
    return [
      { label: 'All', value: '' },
      ...Object.entries(TRANSACTIONS_PAYMENT_METHOD_NORMAL_NAME).map<Option>(([key, value]) => {
        return {
          label: value,
          value: key
        };
      })
    ];
  }, []);

  const currentOption = useMemo(() => {
    return selectOptions.find((option) => option.value === value);
  }, [value, selectOptions]);

  return (
    <CustomSelect<Option, false>
      id="status"
      isMulti={false}
      onChange={handleSelectChange}
      defaultValue={{ label: 'All', value: '' }}
      options={selectOptions}
      value={currentOption}
      styles={currencyFilterStyles}
      components={{ SingleValue: PaymentGatePaymentMethodSingleValue }}
      isSearchable={false}
      placeholder=""
    />
  );
};

export { PaymentGatePaymentMethodTableFilter };
