import { FC, memo, useCallback, useMemo } from 'react';
import { Table, TableFilters } from 'widgets/Table';
import { TableBody } from './ui/TableBody/TableBody';
import styles from './PaymentGatewayTransactionsTable.module.scss';
import { StatusFilter } from 'features/Table';
import { useSelector } from 'react-redux';
import {
  getPaymentGatewayTransactionsIsLoading,
  getPaymentGatewayTransactionsStatus,
  getPaymentGatewayTransactionsHasMore,
  getPaymentGatewayTransactionsNumberOfPage,
  getPaymentGatewayTransactionsCurrency,
  getPaymentGateTransactionPaymentMethodFilter
} from '../../model/selectors/paymentGatewayTransactions/payment-gateway';
import { TableLoader } from 'widgets/TableLoader';
import { getPaymentGatewayTransactionsData } from '../../model/slices/transactions/payment-gateway-transactions';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { transactionsHeaders } from '../../lib/constants/tableHeaders/transactions';
import { PaginationArrows } from 'widgets/PaginationArrows';
import { fetchNextTransactionsPage } from '../../model/services/transactions/fetchNextTransactionsPage';
import { fetchPrevTransactionsPage } from '../../model/services/transactions/fetchPrevTransactionsPage';
import { AmountCurrencyFilter } from 'features/Table/ui/AmountCurrencyFilter/AmountCurrencyFilter';
import { PaymentGatePaymentMethodTableFilter } from 'features/PaymentGatePaymentMethodTableFilter';

interface PaymentGatewayTransactionsTableProps {
  handleSwitchStatus: (status: string) => void;
  handleSwitchCurrency: (currency: string) => void;
  handleSwitchPaymentMethod: (paymentMethod: string) => void;
  className?: string;
}

const PaymentGatewayTransactionsTable: FC<PaymentGatewayTransactionsTableProps> = memo((props) => {
  const { handleSwitchStatus, handleSwitchCurrency, handleSwitchPaymentMethod } = props;

  const dispatch = useAppDispatch();

  const currency = useSelector(getPaymentGatewayTransactionsCurrency);
  const page = useSelector(getPaymentGatewayTransactionsNumberOfPage);
  const isDataLoading = useSelector(getPaymentGatewayTransactionsIsLoading);
  const data = useSelector(getPaymentGatewayTransactionsData.selectAll);
  const status = useSelector(getPaymentGatewayTransactionsStatus);
  const paymentMethod = useSelector(getPaymentGateTransactionPaymentMethodFilter);
  const hasMore = useSelector(getPaymentGatewayTransactionsHasMore);

  const filters = useMemo((): TableFilters => {
    return {
      amount_currency: {
        filter: <AmountCurrencyFilter onChange={handleSwitchCurrency} value={currency} />,
        mode: 'horizontal'
      },
      status: { filter: <StatusFilter onChange={handleSwitchStatus} value={status} />, mode: 'horizontal' },
      payment_method: {
        filter: <PaymentGatePaymentMethodTableFilter onChange={handleSwitchPaymentMethod} value={paymentMethod} />,
        mode: 'vertical'
      }
    };
  }, [handleSwitchCurrency, currency, handleSwitchStatus, status, handleSwitchPaymentMethod, paymentMethod]);

  const handleNextPage = useCallback(() => {
    dispatch(fetchNextTransactionsPage({ replace: true }));
  }, [dispatch]);

  const handlePrevPage = useCallback(() => {
    dispatch(fetchPrevTransactionsPage());
  }, [dispatch]);

  return (
    <div className={styles.tableWrapper}>
      <Table headers={transactionsHeaders} className={styles.table} filters={filters} layout="auto">
        {isDataLoading ? (
          <TableLoader />
        ) : (
          data.length > 0 && (
            <tbody className={styles.tbody}>
              {data.map((data, index) => {
                return <TableBody key={index} data={data} />;
              })}
            </tbody>
          )
        )}
      </Table>
      <PaginationArrows
        className={styles.pagination}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        nextDisabled={!hasMore || isDataLoading}
        prevDisabled={page === 1 || isDataLoading}
      />
    </div>
  );
});

export { PaymentGatewayTransactionsTable };
