import { FC, useCallback, useMemo } from 'react';
import styles from './TableBody.module.scss';
import { TableBodyItem } from 'widgets/Table';
import { getNormalDateFromISO } from 'shared/lib/timeFunctions/getNormalDateFromISO';
import { GET_MONTH } from 'shared/constants/time/month';
import { TRANSACTIONS_STATUS } from 'shared/constants/crypto/transactions-status';
import { round } from 'shared/lib/numbers/round';
import { getCorrectData } from 'shared/lib/timeFunctions/getCorrectData';
import { TRANSACTIONS_PAYMENT_METHOD_NORMAL_NAME } from 'shared/constants/payment-methods/payment-method-types';
import { useNavigate } from 'react-router-dom';
import { truncateString } from 'shared/lib/strings/truncateString/truncateString';
import { PaymentGatewayTransaction, SendingCallbackButton } from 'entities/PaymentGateway';

interface TableBodyProps {
  data: PaymentGatewayTransaction;
}

const TableBody: FC<TableBodyProps> = (props) => {
  const { data } = props;

  const Date = useMemo(() => {
    const normalDate = getNormalDateFromISO(data.created_at);

    const { date, hours, minutes, year } = getCorrectData(normalDate);

    const days = `${date} ${GET_MONTH[normalDate.month]} ${year}`;
    const time = `${hours}:${minutes}`;

    return (
      <>
        <TableBodyItem>
          <div className={styles.box}>
            <p>{days}</p>
            <p className={styles.time}>{time}</p>
          </div>
        </TableBodyItem>
      </>
    );
  }, [data.created_at]);

  const navigate = useNavigate();

  const handleOnClick = useCallback(
    (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      e.stopPropagation();
      navigate(`/fiat-gateway-transaction-details/${data.transaction_id}`);
    },
    [data.transaction_id, navigate]
  );

  return (
    <tr className={styles.tr} onClick={handleOnClick}>
      {Date}
      {/* Transaction ID */}
      <TableBodyItem>{data.transaction_id}</TableBodyItem>
      {/* Amount */}
      <TableBodyItem>{round(data.amount, 5)}</TableBodyItem>
      {/* Currency */}
      <TableBodyItem>{data.currency}</TableBodyItem>
      {/* Payment Method */}
      <TableBodyItem>{TRANSACTIONS_PAYMENT_METHOD_NORMAL_NAME[data.payment_method]}</TableBodyItem>
      {/* Description */}
      <TableBodyItem>{data.description && truncateString(data.description.trim(), 20)}</TableBodyItem>
      {/* Status */}
      <TableBodyItem status={data.status}>{TRANSACTIONS_STATUS[data.status]}</TableBodyItem>
      {/* Callback */}
      <TableBodyItem>
        <SendingCallbackButton id={data.transaction_id} />
      </TableBodyItem>
    </tr>
  );
};

export { TableBody };
