import BigNumber from 'bignumber.js';
import { NewExchangeFormErrors } from '../../types/form/form';
import { DataToCreateNewExchange, FormValues } from '../../types/newExchange/newExchange';

export const validateNewExchangeData = (
  prevErrors: NewExchangeFormErrors,
  values?: FormValues,
  dataToCreate?: DataToCreateNewExchange
) => {
  const errors: NewExchangeFormErrors = { ...prevErrors };

  if (values?.xamaxWalletBalance === '0') {
    errors.NOT_ENOUGH_XAMAX_MONEY = 'Your account has insufficient funds';
  } else {
    errors.NOT_ENOUGH_XAMAX_MONEY = null;
  }

  if (!dataToCreate?.fromAddress) {
    errors.NO_XAMAX_FROM_WALLET = "You don't have XAMAX wallet";
  } else {
    errors.NO_XAMAX_FROM_WALLET = null;
  }

  if (!dataToCreate?.destination?.[0].address) {
    errors.NO_XAMAX_TO_WALLET = "You don't have a XAMAX wallet where to withdraw money";
  } else {
    errors.NO_XAMAX_TO_WALLET = null;
  }

  if (!values?.sendValue && !values?.getValue) {
    errors.NO_AMOUNT_SPECIFIED = 'The amount of sending or receiving is not specified';
  } else {
    errors.NO_AMOUNT_SPECIFIED = null;
  }

  if (values?.xamaxWalletBalance && values.sendValue) {
    const xamaxWalletBalanceValue = new BigNumber(values.xamaxWalletBalance);
    const sendAmountValue = new BigNumber(values.sendValue);
    const result = xamaxWalletBalanceValue.minus(sendAmountValue);

    if (result.toNumber() < 0) {
      errors.NOT_ENOUGH_MONEY = 'Your account has insufficient funds';
    } else {
      errors.NOT_ENOUGH_MONEY = null;
    }
  }

  return errors;
};
