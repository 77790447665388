import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError, ResponseError } from 'shared/types/axios';
import { cryptoTransactionsActions } from '../slices/cryptoTransaction';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { getLinkHeader } from 'shared/lib/pagination/requests/getLinkHeader';
import {
  getTransactionsDateFilter,
  getTransactionsFilterCurrency,
  getTransactionsFilterStatus,
  getTransactionsIdSearch
} from '../selectors/filtration';
import { CryptoTransactionsResponse } from 'entities/Transaction';
import { getCryptoTransactionsPagePagination } from '../selectors/crypto-transactions';

export interface GetCryptoTransactionsProps {
  searchParams?: URLSearchParams;
  replace?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const getCryptoTransactions = createAsyncThunk<
  CryptoTransactionsResponse,
  GetCryptoTransactionsProps,
  ThunkConfig<MyAxiosError<ResponseError>>
>(
  'cryptoTransactions/getCryptoTransactions',
  async ({ searchParams }, { rejectWithValue, dispatch, extra, getState }) => {
    const status = getTransactionsFilterStatus(getState());
    const code = getTransactionsFilterCurrency(getState());
    const date = getTransactionsDateFilter(getState());
    const page = getCryptoTransactionsPagePagination(getState()).currentPage;
    const transactionIdSearch = getTransactionsIdSearch(getState());

    const sinceTime = searchParams?.get('since');
    const untilTime = searchParams?.get('until');

    const params = new URLSearchParams();

    if (status) {
      params.set('status', status);
    }

    if (page) {
      params.set('page', page);
    }

    if (code) {
      params.set('code', code);
    }

    if (transactionIdSearch) {
      params.set('idOrTxHash', transactionIdSearch.replace(/\s+/g, ''));
    }

    let dateFrom = '';
    let dateTo = '';

    if (date?.dateFrom && date.dateTo) {
      const { adjustTillDate } = await import('shared/lib/timeFunctions/dateFilter/ISOformat/adjustTillDate');
      const { since, till } = adjustTillDate(new Date(date.dateFrom), new Date(date.dateTo));

      dateFrom = since;
      dateTo = till;
      params.append('since', since);
      params.append('until', till);
    }

    try {
      const { addQueryParams } = await import('shared/lib/requests/params/addQueryParams/addQueryParams');

      if (!sinceTime && !untilTime) {
        addQueryParams({ since: dateFrom, until: dateTo });
      }

      addQueryParams({ status, code, idOrTxHash: transactionIdSearch });

      const response = await extra.apiWithAuth.get<CryptoTransactionsResponse>('/v1/transactions/incoming', {
        params,
        headers: {
          Accept: 'application/json'
        }
      });

      const linkHeader = response.headers.link as string;

      const pages = getLinkHeader(linkHeader);

      dispatch(cryptoTransactionsActions.setPaginationPages(pages));

      if (!response.data) {
        throw new Error();
      }

      return response.data;
    } catch (e) {
      const error = e as MyAxiosError<ResponseError>;
      return rejectWithValue(error);
    }
  }
);
