import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const getPaymentGatewayPageState = (state: StateSchema) => state?.paymentGateBalance;

export const getPaymentGatewayPageIsLoading = createSelector(
  getPaymentGatewayPageState,
  (state) => state?.isLoading ?? false
);

export const getPaymentGatewayBalanceError = createSelector(getPaymentGatewayPageState, (state) => state?.error);

export const getPaymentGatewayBalanceData = createSelector(getPaymentGatewayPageState, (state) => state?.balance);
