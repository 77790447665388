import { Option } from 'shared/ui/CustomSelect/CustomSelect';

const range = (start: number, end: number, step: number) => {
  const result = [];
  for (let i = start; i < end; i += step) {
    result.push(i);
  }
  return result;
};

export const getYear = (date: Date) => {
  return date.getFullYear();
};

export const years: Option[] = range(2021, getYear(new Date()) + 1, 1).map((year) => ({
  label: year.toString(),
  value: year.toString()
}));
