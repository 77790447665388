import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AlertSchema, TAlert } from '../types/alert';

const initialState: AlertSchema = {
  alerts: []
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    setAlert: (state, action: PayloadAction<TAlert>) => {
      state.alerts = [...state.alerts, action.payload];
    },
    removeAlert: (state) => {
      state.alerts = state.alerts?.slice(1);
    },
    clearAll: (state) => {
      state.alerts = [];
    }
  }
});

export const { actions: alertActions } = alertSlice;
export const { reducer: alertReducer } = alertSlice;
