import React, { FC, ReactNode, forwardRef, memo } from 'react';
import styles from './Label.module.scss';
import classNames from 'classnames';

export type LabelSize = 'sizeXS' | 'sizeS' | 'sizeM' | 'sizeL' | 'sizeXL';
export type LabelWeight = '300' | '400' | '500' | '600' | '700';
export type LabelTheme = 'primary' | 'secondary';
export type LabelDecoration = 'underline';

const decorationClasses: Record<LabelDecoration, string> = {
  underline: styles.underline
};

const weightClasses: Record<LabelWeight, string> = {
  300: styles.weight300,
  400: styles.weight400,
  500: styles.weight500,
  600: styles.weight600,
  700: styles.weight700
};

const sizeClasses: Record<LabelSize, string> = {
  sizeXS: styles.sizeXS,
  sizeS: styles.sizeS,
  sizeM: styles.sizeM,
  sizeL: styles.sizeL,
  sizeXL: styles.sizeXL
};

const themeClasses: Record<LabelTheme, string> = {
  primary: styles.primary,
  secondary: styles.secondary
};

interface LabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  className?: string;
  children?: ReactNode;
  size?: LabelSize;
  weight?: LabelWeight;
  theme?: LabelTheme;
  decoration?: LabelDecoration;
  hidden?: boolean;
}

const Label = forwardRef<HTMLLabelElement, LabelProps>((props, ref) => {
  const {
    className,
    children,
    size = 'sizeS',
    weight = '400',
    theme = 'primary',
    decoration,
    hidden,
    ...otherProps
  } = props;

  const classes = [
    className,
    sizeClasses[size],
    weightClasses[weight],
    themeClasses[theme],
    decoration && decorationClasses[decoration]
  ];

  const mods = {
    [styles.hidden]: hidden
  };

  return (
    <label className={classNames(styles.label, mods, classes)} ref={ref} {...otherProps}>
      {children}
    </label>
  );
});

export { Label };
