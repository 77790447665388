import { FC, SVGProps, memo, useCallback } from 'react';
import styles from './NewTransactionLink.module.scss';
import { AppLink } from 'shared/ui/AppLink/AppLink';
import { Icon } from 'shared/ui/Icon/Icon';
import { Text } from 'shared/ui/Text/Text';
import classNames from 'classnames';
import { ReactComponent as NewInvoiceLinkIcon } from 'app/assets/icons/new-invoice-icon.svg';
import { ReactComponent as NewExchangeLinkIcon } from 'app/assets/icons/sidebar/exchange.svg';
import { ReactComponent as NewWithdrawalLinkIcon } from 'app/assets/icons/new-withdrawals-icon.svg';
import { TransactionLinkType } from '../../model/types/types';

export type NewTransactionTheme = 'primary' | 'secondary';
export type NewTransactionLinkView = 'icon';

const themeClasses: Record<NewTransactionTheme, string> = {
  primary: styles.primary,
  secondary: styles.secondary
};

const SVG: Record<TransactionLinkType, FC<SVGProps<SVGSVGElement>>> = {
  invoice: NewInvoiceLinkIcon,
  exchange: NewExchangeLinkIcon,
  withdrawal: NewWithdrawalLinkIcon,
  paymentGateTransaction: NewInvoiceLinkIcon,
  paymentGateWithdrawal: NewWithdrawalLinkIcon
};

const text: Record<TransactionLinkType, string> = {
  invoice: 'New invoice',
  exchange: 'New exchange',
  withdrawal: 'New withdrawal',
  paymentGateTransaction: 'New transaction',
  paymentGateWithdrawal: 'New withdrawal'
};

const to: Record<TransactionLinkType, string> = {
  invoice: '/new-invoice',
  exchange: '/new-exchange',
  withdrawal: '/new-withdrawal',
  paymentGateTransaction: '/new-fiat-gateway-transaction',
  paymentGateWithdrawal: '/new-fiat-gateway-withdrawal'
};

const typeTheme: Record<TransactionLinkType, string> = {
  invoice: styles.newInvoice,
  exchange: styles.newExchange,
  withdrawal: styles.newWithdrawal,
  paymentGateTransaction: styles.newInvoice,
  paymentGateWithdrawal: styles.newWithdrawal
};

interface NewTransactionLinkProps {
  type: TransactionLinkType;
  className?: string;
  onClick?: () => void;
  theme?: NewTransactionTheme;
  view?: NewTransactionLinkView;
}

const NewTransactionLink: FC<NewTransactionLinkProps> = memo((props) => {
  const { className, onClick, type, theme = 'primary', view } = props;

  const handleClick = useCallback(() => {
    onClick?.();
  }, [onClick]);

  const classes = [className, typeTheme[type], themeClasses[theme]];

  const mods = {
    [styles.viewIcon]: view === 'icon'
  };

  return (
    <AppLink
      to={to[type]}
      replace
      className={classNames(styles.link, mods, classes)}
      onClick={handleClick}
      size="sizeM"
    >
      <Icon SVG={SVG[type]} className={styles.icon} />
      {view !== 'icon' && (
        <Text size="sizeM" lineHeight="1.2">
          {text[type]}
        </Text>
      )}
    </AppLink>
  );
});

export { NewTransactionLink };
