import { FC, ReactNode, useEffect, useRef } from 'react';
import styles from './MobileFilterWrapper.module.scss';
import { Portal } from 'shared/ui/Portal/Portal';
import classNames from 'classnames';
import FocusTrap from 'focus-trap-react';
import { Button } from 'shared/ui/Button/Button';

interface MobileFilterWrapperProps {
  isOpen: boolean;
  children: ReactNode;
  handleClose?: () => void;
}

const filterRoot = document.getElementById('filter-root');

const MobileFilterWrapper: FC<MobileFilterWrapperProps> = (props) => {
  const { isOpen, children, handleClose } = props;

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('lock-scroll');
    } else {
      document.body.classList.remove('lock-scroll');
    }
  }, [isOpen]);

  const overlayMods = {
    [styles.overlayOpened]: isOpen
  };

  return (
    <Portal element={filterRoot!}>
      {isOpen && (
        <>
          <div className={classNames(styles.overlay, overlayMods)} onClick={() => handleClose?.()}></div>
          <FocusTrap
            active
            focusTrapOptions={{
              initialFocus: false,
              allowOutsideClick: true,
              clickOutsideDeactivates: true
            }}
          >
            <div className={styles.wrapper}>
              {children}
              <Button type="button" onClick={handleClose} className={styles.closeBtn}>
                Close
              </Button>
            </div>
          </FocusTrap>
        </>
      )}
    </Portal>
  );
};

export { MobileFilterWrapper };
