export function getSignboardText() {
  let textBoard: string;

  if (import.meta.env.VITE_AUTH_STEP_SANDBOX) {
    textBoard = 'SANDBOX';
  } else if (import.meta.env.VITE_VERCEL_ENV === 'preview') {
    textBoard = 'PREPROD';
  } else return null;

  return textBoard;
}
