import BigNumber from 'bignumber.js';

export const round = (number: string | number, precision?: number) => {
  const num = new BigNumber(number);
  if (precision) {
    const formatted = num.toFormat(precision, BigNumber.ROUND_DOWN);
    const trimmed = formatted.replace(/\.?0+$/, '');
    return trimmed;
  } else {
    const strNumber = num.toFixed().toString();
    const parts = strNumber.split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
  }
};

export const roundCvsFormat = (number: string | number) => {
  const num = new BigNumber(number);

  const strNumber = num.toFixed().toString();
  const parts = strNumber.split('.');

  // Разделение тысячных разрядов в целой части числа
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  // Разделение десятичных разрядов через запятую
  if (parts[1]) {
    parts[1] = parts[1].replace('.', ',');
  }

  return parts.join(',');
};
