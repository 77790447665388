import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const getCryptoTransactionsPageState = (state: StateSchema) => state?.cryptoTransactionsPage;

export const getCryptoTransactionsPageIsLoading = createSelector(
  getCryptoTransactionsPageState,
  (state) => state?.isLoading ?? false
);

export const getCryptoTransactionsPageError = createSelector(getCryptoTransactionsPageState, (state) => state?.error);

export const getCryptoTransactionsPageData = createSelector(
  getCryptoTransactionsPageState,
  (state) => state?.transactions ?? []
);

export const getCryptoTransactionsPagePagination = createSelector(
  getCryptoTransactionsPageState,
  (state) => state?.paginationPages ?? {}
);

export const getCryptoTransactionsPageHasMore = createSelector(
  getCryptoTransactionsPageState,
  (state) => state?.hasMore ?? false
);

export const getIsCryptoTransactionsCsvDownloading = createSelector(
  getCryptoTransactionsPageState,
  (state) => state?.isTransactionsCsvDownLoading ?? false
);

export const getIsCryptoTransactionsCsvDownLoadingSuccess = createSelector(
  getCryptoTransactionsPageState,
  (state) => state?.isTransactionsCsvDownLoadingSuccess ?? false
);

export const getCryptoTransactionsCsvDownLoadingError = createSelector(
  getCryptoTransactionsPageState,
  (state) => state?.transactionsCsvDownLoadingError ?? undefined
);
