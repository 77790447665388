import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataToCreateNewExchange, FormValues, NewExchangeSchema } from '../../types/newExchange/newExchange';
import { validateNewExchangeData } from '../../services/validateNewExchange/validateNewExchange';
import { createNewExchange } from '../../services/newExchange/newExchange';

const initialState: NewExchangeSchema = {
  error: undefined,
  formErrors: {
    NO_XAMAX_FROM_WALLET: null,
    NO_XAMAX_TO_WALLET: null,
    NOT_ENOUGH_MONEY: null,
    NOT_ENOUGH_XAMAX_MONEY: null,
    NO_AMOUNT_SPECIFIED: null
  },
  isLoading: false,
  isSuccess: false,
  isDataValid: false,
  dataToCreateNewExchange: undefined,
  newExchange: undefined
};

export const newExchangeSlice = createSlice({
  name: 'newExchange',
  initialState,
  reducers: {
    setDataToCreateNewExchange: (state, action: PayloadAction<DataToCreateNewExchange>) => {
      state.dataToCreateNewExchange = {
        ...state.dataToCreateNewExchange,
        ...action.payload
      };
    },
    setResetData: (state) => {
      state.dataToCreateNewExchange = undefined;
      state.isSuccess = false;
      state.isLoading = false;
      state.isDataValid = false;
      state.newExchange = undefined;
      state.formErrors = {
        NO_XAMAX_FROM_WALLET: null,
        NO_XAMAX_TO_WALLET: null,
        NOT_ENOUGH_MONEY: null,
        NOT_ENOUGH_XAMAX_MONEY: null,
        NO_AMOUNT_SPECIFIED: null
      };
      state.formValues = undefined;
    },
    setFormValues: (state, action: PayloadAction<FormValues>) => {
      state.formValues = { ...state.formValues, ...action.payload };
    },
    setResetFormValues: (state) => {
      state.formValues = undefined;
    },
    setValidate: (state) => {
      const errors = validateNewExchangeData(state.formErrors, state.formValues, state.dataToCreateNewExchange);
      state.formErrors = errors;
      state.isDataValid = Object.values(errors).every((value) => value === null);
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewExchange.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(createNewExchange.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.isSuccess = true;
        state.newExchange = action.payload;
      })
      .addCase(createNewExchange.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      });
  }
});

export const { actions: newExchangeActions } = newExchangeSlice;
export const { reducer: newExchangeReducer } = newExchangeSlice;
