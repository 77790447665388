import React from 'react';

export interface Web3ContextProps {
  connectedAccount?: string;
  isAccountConnecting: boolean;
  isMetamaskInstalled: boolean;
  isTronLinkInstalled: boolean;
  isAccountChanged: boolean;
  connectMetamask: () => void;
  connectTronLink: () => void;
  walletType: 'metamask' | 'tronLink';
  isAccountConnected: boolean;
  trxAddresses: string[];
  ethAddresses: string[];
}

const Web3Context = React.createContext<Web3ContextProps>(undefined!);

export default Web3Context;
