import { FC, memo, useEffect, useRef, useState } from 'react';
import styles from './DatePicker.module.scss';
import classNames from 'classnames';
import { DayPicker, DateRange, ClassNames, Matcher } from 'react-day-picker';
import rdrStyles from 'react-day-picker/dist/style.module.css';
import { usePopper } from 'react-popper';
import FocusTrap from 'focus-trap-react';
import { Button } from 'shared/ui/Button/Button';
import { useWindowSize } from 'shared/lib/hooks/useWindowSize/useWindowSize';
import { MobileFilterWrapper } from 'widgets/MobileFilterWrapper';
import { DateFilterType } from '../model/types/types';
import { format } from 'date-fns';
import { CustomCaption } from './CustomCaption/CustomCaption';

export type DateFormat = 'range' | 'firstRange' | 'secondRange';

interface DatePickerProps {
  className?: string;
  handleChange: (date: DateFilterType) => void;
  handleResetDate: () => void;
  defaultValue?: DateFilterType;
  disabled?: Matcher | Matcher[] | undefined;
  showPeriodFilter?: boolean;
  showMonthSelect?: boolean;
  showYearSelect?: boolean;
  showMonthFilter?: boolean;
  dateFormat?: string;
  showDateFormat?: DateFormat;
  disableNavigation?: boolean;
}

const DatePicker: FC<DatePickerProps> = memo((props) => {
  const {
    className,
    handleChange,
    handleResetDate,
    defaultValue,
    showPeriodFilter,
    disabled,
    showMonthSelect,
    showYearSelect,
    showMonthFilter,
    dateFormat = 'dd.MM.yyyy',
    showDateFormat = 'range',
    disableNavigation
  } = props;

  const popperRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);

  const [windowSize] = useWindowSize();

  const popper = usePopper(popperRef.current, popperElement, {
    placement: 'bottom-start'
  });

  const [isOpen, setIsOpen] = useState(false);

  const [range, setRange] = useState<DateRange | undefined>();

  useEffect(() => {
    if (defaultValue) {
      setRange({ from: defaultValue?.dateFrom, to: defaultValue?.dateTo });
    } else {
      setRange(undefined);
    }
  }, [defaultValue]);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const closePopper = () => {
    handleClose();
    buttonRef?.current?.focus();
  };

  const handleDaySelect = (date: DateRange | undefined) => {
    if (date) {
      setRange(date);
      handleChange({ dateFrom: date.from, dateTo: date.to });
      if (date.from && date.to) {
        closePopper();
      }
    } else {
      setRange(undefined);
    }
  };

  const resetDate = () => {
    handleResetDate();
    setRange(undefined);
  };

  const rdrClassNames: ClassNames = {
    ...rdrStyles,
    root: `${styles.customRoot} ${rdrStyles.root}`,
    months: styles.months,
    caption_dropdowns: styles.captionDropdowns,
    caption: styles.caption,
    nav: styles.nav,
    nav_button: styles.navButton,
    day_selected: `${styles.daySelected} ${rdrStyles.day_selected}`,
    table: `${styles.customTable} ${rdrStyles.table}`
  };

  const today = new Date();
  today.setDate(today.getDate() + 1);

  return (
    <div>
      <div ref={popperRef}>
        <div className={classNames(styles.dateValue, { [styles.isHaveDate]: range?.from && range.to })}>
          <Button
            size="sizeS"
            ref={buttonRef}
            type="button"
            aria-label="Pick a date"
            className={classNames(styles.pickDateBtn, { [styles.isHaveDate]: range?.from && range.to })}
            onClick={handleOpen}
            lineHeight="1.3"
          >
            {showDateFormat === 'range' &&
              range?.from &&
              range.to &&
              `${format(range.from, dateFormat)}-${format(range.to, dateFormat)}`}
            {showDateFormat === 'firstRange' && range?.from && format(range.from, dateFormat)}
            {showDateFormat === 'secondRange' && range?.to && format(range.to, dateFormat)}
            {(!range?.from || !range.to) && 'Date'}
          </Button>
          {range?.from && range.to && (
            <Button type="button" theme="clear" onClick={resetDate} className={styles.resetFilterBtn}></Button>
          )}
        </div>
      </div>
      {windowSize > 768 ? (
        isOpen && (
          <FocusTrap
            active
            focusTrapOptions={{
              initialFocus: false,
              allowOutsideClick: true,
              clickOutsideDeactivates: true,
              onDeactivate: closePopper,
              fallbackFocus: buttonRef.current ?? undefined
            }}
          >
            <div
              tabIndex={-1}
              style={popper.styles.popper}
              className={styles.dialog}
              {...popper.attributes.popper}
              ref={setPopperElement}
              role="dialog"
              aria-label="DayPicker calendar"
            >
              <DayPicker
                disableNavigation={disableNavigation}
                weekStartsOn={1}
                initialFocus={isOpen}
                mode="range"
                selected={range}
                defaultMonth={
                  (defaultValue?.dateFrom && new Date(defaultValue?.dateFrom)) ?? (range?.from && new Date(range.from))
                }
                onSelect={handleDaySelect}
                captionLayout="dropdown-buttons"
                fromYear={2021}
                toYear={new Date().getFullYear()}
                className={classNames(styles.datePicker, [className])}
                classNames={rdrClassNames}
                components={{
                  Caption: (props) => {
                    return (
                      <CustomCaption
                        setRange={setRange}
                        closePopper={closePopper}
                        handleChange={handleChange}
                        showPeriodFilter={showPeriodFilter}
                        showMonthSelect={showMonthSelect}
                        showYearSelect={showYearSelect}
                        showMonthFilter={showMonthFilter}
                        {...props}
                      />
                    );
                  }
                }}
                disabled={disabled ?? { from: today, to: new Date(2100, 0, 1) }}
              />
            </div>
          </FocusTrap>
        )
      ) : (
        <MobileFilterWrapper isOpen={isOpen} handleClose={handleClose}>
          <DayPicker
            initialFocus={isOpen}
            mode="range"
            selected={range}
            defaultMonth={
              (defaultValue?.dateFrom && new Date(defaultValue?.dateFrom)) ?? (range?.from && new Date(range.from))
            }
            disableNavigation={disableNavigation}
            onSelect={handleDaySelect}
            captionLayout="dropdown-buttons"
            fromYear={2021}
            toYear={new Date().getFullYear()}
            className={classNames(styles.datePicker, [className])}
            classNames={rdrClassNames}
            components={{
              Caption: (props) => {
                return (
                  <CustomCaption
                    setRange={setRange}
                    closePopper={closePopper}
                    handleChange={handleChange}
                    showMonthSelect={showMonthSelect}
                    showPeriodFilter={showPeriodFilter}
                    showYearSelect={showYearSelect}
                    showMonthFilter={showMonthFilter}
                    {...props}
                  />
                );
              }
            }}
            disabled={disabled ?? { from: today, to: new Date(2100, 0, 1) }}
          />
        </MobileFilterWrapper>
      )}
    </div>
  );
});

export { DatePicker };
