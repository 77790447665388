import { PreferencesItem } from 'entities/Preferences';
import { AmountValues } from 'entities/Preferences/model/types/preferences';
import { CRYPTO_SHORT_NAMES, CRYPTO_VALUES_TO_DIVIDE } from 'shared/constants/crypto/crypto-names';

export const getCryptoMinValues = (preferences?: PreferencesItem[]) => {
  return preferences?.reduce((prev, curr) => {
    if (curr.key.includes('min-amount-')) {
      const currency = curr.key.replace(/^min-amount-/, '');
      const divider = CRYPTO_VALUES_TO_DIVIDE[currency.toUpperCase() as CRYPTO_SHORT_NAMES];

      prev[currency] = Number(curr.value) / divider;

      return prev;
    }
    return prev;
  }, {} as AmountValues);
};
