import { NormalDate } from './getNormalDateFromISO';
import { transformToCorrectForm } from './transformToCorrectForm';

export interface CorrectData {
  date: string;
  month: string;
  year: string;
  hours: string;
  minutes: string;
  seconds: string;
}

export const getCorrectData = (normalDate: NormalDate) => {
  const { date, month, year, hours, minutes, seconds } = normalDate;

  return {
    date: transformToCorrectForm(date),
    month: transformToCorrectForm(month + 1),
    year: String(year),
    hours: transformToCorrectForm(hours),
    minutes: transformToCorrectForm(minutes),
    seconds: transformToCorrectForm(seconds)
  };
};
