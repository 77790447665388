import { createContext, useContext } from 'react';

export interface UnsavedChangesContextProps {
  unsavedChanges?: boolean;
  setUnsavedChanges?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UnsavedChangesContext = createContext<UnsavedChangesContextProps>({});

export const useUnsavedChanges = () => useContext(UnsavedChangesContext);
