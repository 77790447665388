import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { ListBox } from 'shared/ui/ListBox/ListBox';
import { periodOptions } from '../../lib/constants/options/periodOptions/periodOptions';
import { DateFilterType, PeriodFilterValue } from '../../model/types/types';
import { DateRange, useNavigation, useSelectRange } from 'react-day-picker';
import {
  FIRST_DATE_OF_CURRENT_MONTH,
  FIRST_DATE_OF_LAST_MONTH,
  LAST_DATE_OF_CURRENT_MONTH,
  LAST_DATE_OF_LAST_MONTH,
  CURRENT_MONTH,
  PREVIOUS_MONTH,
  CURRENT_YEAR,
  FIRST_DAY_OF_CURRENT_MONTH,
  LAST_DAY_OF_CURRENT_MONTH,
  FIRST_DAY_OF_LAST_MONTH,
  LAST_DAY_OF_LAST_MONTH
} from 'features/DatePicker/lib/constants/time/months';
import { getMonthPeriod } from '../../lib/getMonthPeriod/getMonthPeriod';

interface PeriodFilterProps {
  setRange: Dispatch<SetStateAction<DateRange | undefined>>;
  handleChange: (date: DateFilterType) => void;
  closePopper: () => void;
}

const PeriodFilter: FC<PeriodFilterProps> = (props) => {
  const { setRange, closePopper, handleChange } = props;

  const [periodValue, setPeriodValue] = useState<PeriodFilterValue>();

  const { selected } = useSelectRange();
  const { goToDate } = useNavigation();

  const handleChangePeriod = (value: string | number) => {
    const period = value as PeriodFilterValue;

    switch (period) {
      case 'current':
        setRange({ from: FIRST_DATE_OF_CURRENT_MONTH, to: LAST_DATE_OF_CURRENT_MONTH });
        handleChange({ dateFrom: FIRST_DATE_OF_CURRENT_MONTH, dateTo: LAST_DATE_OF_CURRENT_MONTH });
        goToDate(new Date(CURRENT_YEAR, CURRENT_MONTH, 1));
        break;
      case 'previous':
        setRange({ from: FIRST_DATE_OF_LAST_MONTH, to: LAST_DATE_OF_LAST_MONTH });
        handleChange({ dateFrom: FIRST_DATE_OF_LAST_MONTH, dateTo: LAST_DATE_OF_LAST_MONTH });
        goToDate(new Date(CURRENT_YEAR, PREVIOUS_MONTH, 1));
        break;
    }
    closePopper();
    setPeriodValue(period);
  };

  useEffect(() => {
    const from = selected?.from?.getDay();
    const to = selected?.to?.getDay();

    if (periodValue) {
      const isCurrent = periodValue === 'current';
      const isPrevious = periodValue === 'previous';

      if (
        (isCurrent && (from !== FIRST_DAY_OF_CURRENT_MONTH || to !== LAST_DAY_OF_CURRENT_MONTH)) ||
        (isPrevious && (from !== FIRST_DAY_OF_LAST_MONTH || to !== LAST_DAY_OF_LAST_MONTH))
      ) {
        setPeriodValue('' as PeriodFilterValue);
      }
    } else {
      const monthPeriod = getMonthPeriod(from, to);
      setPeriodValue(monthPeriod);
    }
  }, [selected]);

  return (
    <ListBox
      items={periodOptions}
      filterName="Period"
      defaultValue=""
      onChange={handleChangePeriod}
      isAbsolute
      value={periodValue}
    />
  );
};

export { PeriodFilter };
