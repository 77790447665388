import { FC } from 'react';
import styles from './Signboard.module.scss';
import { getSignboardText } from 'shared/lib/utils';

const Signboard: FC = () => {
  const textBoard = getSignboardText();
  return (
    <div>
      {textBoard && <div className={styles.signboard}>{textBoard}</div>}
    </div>
  );
};

export { Signboard };
