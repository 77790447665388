import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError, ResponseError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { getTransactionsDateFilter, getTransactionsFilterStatus } from '../../selectors/filtration';

export interface DownloadCryptoTransactionsCsvProps {}

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const downloadCryptoTransactionsCsv = createAsyncThunk<
  { message: string },
  undefined,
  ThunkConfig<MyAxiosError<ResponseError>>
>('cryptoTransactions/downloadCryptoTransactionsCsv', async (_, { extra, getState, rejectWithValue }) => {
  const date = getTransactionsDateFilter(getState());
  const status = getTransactionsFilterStatus(getState());

  const { adjustTillDate } = await import('shared/lib/timeFunctions/dateFilter/ISOformat/adjustTillDate');

  let sinceData: string | undefined = '';
  let until: string | undefined = '';

  if (date?.dateFrom && date.dateTo) {
    const { since, till } = adjustTillDate(new Date(date.dateFrom), new Date(date.dateTo));

    sinceData = since;
    until = till;
  } else {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const pastDate = new Date(currentDate);
    pastDate.setFullYear(currentYear - 1);
    const { since, till } = adjustTillDate(pastDate, new Date());
    sinceData = since;
    until = till;
  }

  if (status) {
  }

  try {
    const response = await extra.apiWithAuth.post(
      '/v1/transactions/incoming/report',
      {
        since: sinceData,
        until,
        status: [status]
      },
      { responseType: 'blob' }
    );

    if (!response.data) {
      throw new Error('Ошибка при попытке выгрузить транзакции в .csv');
    }

    const blob = new Blob([response.data], { type: response.headers['content-type'] || 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'transactions.xlsx');
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(url);

    return { message: 'Выгрузка транзакций прошла успешно!' };
  } catch (e) {
    const error = e as MyAxiosError<ResponseError>;
    return rejectWithValue(error);
  }
});
