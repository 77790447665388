import axios from 'axios';
import { authUrl } from 'shared/constants/authUrl';

const url = `${authUrl}`;

export const $keycloak = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json'
  }
});
