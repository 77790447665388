import { Suspense, useEffect } from 'react';
import { AppRouter } from './providers/router';
import 'app/styles/main.scss';
import { useSelector } from 'react-redux';
import { LOCAL_STORAGE_ACCESS_TOKEN } from 'shared/constants/localstorage/localstorage';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { authorization, getIsUserAuthorized, userActions } from 'entities/User';
import { getIsUserLoading, getUserData } from 'entities/User/model/selectors/user';
import { Loader } from 'widgets/Loader';
import { getAllAvailableTokens, getBalance } from 'entities/Balance';
import { AlertContainer } from 'entities/Alert';
import { ScrollToTop } from 'features/ScrollToTop';
import { useLocation } from 'react-router-dom';
import { SplashWindow } from 'shared/ui/SplashWindow';
import { Signboard } from 'widgets/Signboard';

const App = () => {
  const dispatch = useAppDispatch();
  const isUserAuthorized = useSelector(getIsUserAuthorized);
  const isAuthorizing = useSelector(getIsUserLoading);
  const userData = useSelector(getUserData);
  const location = useLocation();

  useEffect(() => {
    dispatch(userActions.initUser());
  }, [dispatch]);

  useEffect(() => {
    const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);

    if (!isUserAuthorized && !accessToken && location.pathname !== '/demo-shop-login') {
      dispatch(authorization({}));
    }
  }, [dispatch, isUserAuthorized]);

  useEffect(() => {
    if (isUserAuthorized) {
      dispatch(getAllAvailableTokens());
      dispatch(getBalance());
    }
  }, [dispatch, isUserAuthorized]);

  return (
    <div className='app'>
      <Signboard />
      <ScrollToTop />
      {isAuthorizing ? (
        <Loader />
      ) : userData && isUserAuthorized ? (
        <Suspense fallback={<Loader />}>
          <AppRouter />
        </Suspense>
      ) : (
        <SplashWindow />
      )}
      <AlertContainer />
    </div>
  );
};

export default App;
