import { UserData, UserRole } from '../../model/types/user';

export const checkUserRole = (role: UserRole, userData: UserData | undefined) => {
  if (userData) {
    const roles = userData.realm_access?.roles;

    if (roles) {
      return roles.includes(role);
    }
  }

  return false;
};
