export interface PaginationPages {
  nextPage?: string | null;
  prevPage?: string | null;
  firstPage?: string | null;
  currentPage?: string | null;
}

export const getLinkHeader = (linkHeader: string): PaginationPages => {
  if (linkHeader) {
    const result: PaginationPages = {
      nextPage: null,
      prevPage: null,
      firstPage: null,
      currentPage: null
    };
    const links = linkHeader.split(',');

    for (const link of links) {
      const [url, rel] = link.split(';'); // разбить ссылку на URL и значение rel
      const urlMatch = url.match(/<(.+)>/); // использовать регулярное выражение для извлечения URL из угловых скобок

      if (urlMatch) {
        const url = urlMatch[1];
        const pageMatch = url.match(/page=([^&]+)/); // использовать регулярное выражение для поиска параметра "page"

        if (rel.includes('next')) {
          result.nextPage = pageMatch?.[1] ?? null;
        }
        if (rel.includes('prev')) {
          result.prevPage = pageMatch?.[1] ?? '';
        }
        if (rel.includes('first')) {
          result.firstPage = pageMatch?.[1] ?? null;
        }
      }
    }

    return result;
  } else {
    return {
      nextPage: null,
      prevPage: null,
      firstPage: null,
      currentPage: null
    };
  }
};
