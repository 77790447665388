import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AffiliateReportSchema,
  PeriodGroupFilterValue
} from '../../../../model/types/affiliate/report/affiliateReport';
import { DataFilterType } from '../../../../model/types/types';
import { getAffiliateReport } from '../../../../model/services/affiliate/report/getAffiliateReport';
import { FIRST_DATE_OF_CURRENT_MONTH, LAST_DATE_OF_CURRENT_MONTH } from 'features/DatePicker';

const initialState: AffiliateReportSchema = {
  error: undefined,
  isLoading: false,
  reportData: undefined,
  isSuccess: false,
  date: {
    dateFrom: FIRST_DATE_OF_CURRENT_MONTH.toString(),
    dateTo: LAST_DATE_OF_CURRENT_MONTH.toString()
  },
  isShowResetBtn: true
};

export const affiliateReport = createSlice({
  name: 'affiliateReport',

  initialState,
  reducers: {
    setResetBtnState: (state) => {
      if (state.currency || (state.date?.dateFrom && state.date.dateTo) || state.periodGroup) {
        state.isShowResetBtn = true;
      } else {
        state.isShowResetBtn = false;
      }
    },
    setCurrencyFilter: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
      affiliateReport.caseReducers.setResetBtnState(state);
    },
    setDate: (state, action: PayloadAction<DataFilterType>) => {
      state.date = action.payload;
      affiliateReport.caseReducers.setResetBtnState(state);
    },
    setPeriodGroup: (state, action: PayloadAction<PeriodGroupFilterValue>) => {
      state.periodGroup = action.payload;
      affiliateReport.caseReducers.setResetBtnState(state);
    },
    setResetFilters: (state) => {
      state.currency = undefined;
      state.date = undefined;
      state.periodGroup = undefined;
      state.isShowResetBtn = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAffiliateReport.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(getAffiliateReport.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.isSuccess = true;
        state.reportData = action.payload;
      })
      .addCase(getAffiliateReport.rejected, (state, action) => {
        state.error = action.payload;
        state.isSuccess = false;
        state.isLoading = false;
      });
  }
});

export const { actions: affiliateReportActions, reducer: affiliateReportReducer } = affiliateReport;
