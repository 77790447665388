import React, { FC, useMemo } from 'react';
import { AvailableCurrenciesContext } from '../lib/AvailableCurrenciesContext';
import { Option } from 'shared/ui/CustomSelect/CustomSelect';
import { useSelector } from 'react-redux';
import { getAllAvailableTokensData } from 'entities/Balance';
import { CryptoName, SELECT_CRYPTO_CODES } from 'shared/constants/crypto/crypto-names';
import { CRYPTO_ICONS } from 'shared/constants/crypto/crypto-icons';

interface AvailableCurrenciesProviderProps {
  children?: React.ReactNode;
}

const AvailableCurrenciesProvider: FC<AvailableCurrenciesProviderProps> = ({ children }) => {
  const availableTokens = useSelector(getAllAvailableTokensData);

  const selectOptions = useMemo<Option[]>(() => {
    const options = availableTokens
      .map((token) => {
        return {
          value: token.code,
          label: SELECT_CRYPTO_CODES[token.code as CryptoName]?.toUpperCase(),
          icon: CRYPTO_ICONS[token.code.toUpperCase()]
        } as Option;
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    options.unshift({
      label: 'All',
      value: ''
    });

    return options;
  }, [availableTokens]);

  const defaultProps = useMemo(() => selectOptions, [selectOptions]);

  return <AvailableCurrenciesContext.Provider value={defaultProps}>{children}</AvailableCurrenciesContext.Provider>;
};

export default AvailableCurrenciesProvider;
