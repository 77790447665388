import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { GetPaymentGateBalanceResponse, GetPaymentGateBalancesError } from '../../types/balance/balance';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const getPaymentGateBalances = createAsyncThunk<GetPaymentGateBalanceResponse,
undefined,
ThunkConfig<MyAxiosError<GetPaymentGateBalancesError>>>('paymentGateBalances/getPaymentGateBalances', async (_, {
  rejectWithValue,
  extra
}) => {
  try {
    const response = await extra.apiWithAuth.get<GetPaymentGateBalanceResponse>('/fiat/v1/merchant/balance', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (!response.data) {
      throw new Error();
    }
    return response.data;
  } catch (e) {
    const error = e as MyAxiosError<GetPaymentGateBalancesError>;
    return rejectWithValue(error);
  }
});
