export interface PaymentMethod {
  [key: string]: string;
}

export enum TRANSACTIONS_PAYMENT_METHOD_VALUE {
  VIRTUAL_ACCOUNT = 'virtual_account',
  CREDIT_CARD = 'credit_card',
  GRABPAY_EWALLET = 'grabpay_ewallet',
  GCASH_EWALLET = 'gcash_ewallet',
  ONLINE_BANKING = 'online_banking',
  MOBILE_MONEY = 'mobile_money',
  UPI = 'upi',
  CASH = 'cash',
  AGENT = 'agent',
  MOMO_EWALLET = 'momo_ewallet',
  QR_CODE = 'qr_code',
  PAYMAYA_EWALLET = 'paymaya_ewallet',
  TRUEMONEY_EWALLET = 'truemoney_ewallet',
  PAY_ID = 'pay_id',
  POLI_PAY = 'poli_pay'
}

export const TRANSACTIONS_PAYMENT_METHOD_NORMAL_NAME: Record<TRANSACTIONS_PAYMENT_METHOD_VALUE, string> = {
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.VIRTUAL_ACCOUNT]: 'Virtual Account',
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.CREDIT_CARD]: 'Credit card',
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.GRABPAY_EWALLET]: 'Grabpay Ewallet',
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.GCASH_EWALLET]: 'Gcash Ewallet',
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.ONLINE_BANKING]: 'Online Banking',
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.MOBILE_MONEY]: 'Mobile Money',
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.UPI]: 'UPI',
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.CASH]: 'Cash',
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.AGENT]: 'Agent',
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.MOMO_EWALLET]: 'MOMO',
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.QR_CODE]: 'QR Code',
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.PAYMAYA_EWALLET]: 'PayMaya',
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.TRUEMONEY_EWALLET]: 'TrueMoney',
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.PAY_ID]: 'PayID',
  [TRANSACTIONS_PAYMENT_METHOD_VALUE.POLI_PAY]: 'Poli Pay'
};

export enum WITHDRAWALS_PAYMENT_METHOD_VALUE {
  BANK_TRANSFER = 'bank_transfer'
}

export const WITHDRAWALS_PAYMENT_METHOD_NORMAL_NAME: PaymentMethod = {
  [WITHDRAWALS_PAYMENT_METHOD_VALUE.BANK_TRANSFER]: 'Bank Transfer'
};
