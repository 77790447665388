import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError, ResponseError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { PaymentGatewayTransactionDetails } from '../types/transaction-details';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const getTransactionById = createAsyncThunk<
  PaymentGatewayTransactionDetails,
  string,
  ThunkConfig<MyAxiosError<ResponseError>>
>('paymentGatewayTransactionDetails/getTransactionById', async (id, { rejectWithValue, extra }) => {
  try {
    const response = await extra.apiWithAuth.get<PaymentGatewayTransactionDetails>('/fiat/v1/transaction', {
      params: {
        transaction_id: id
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.data) {
      throw new Error();
    }

    return response.data;
  } catch (e) {
    const error = e as MyAxiosError<ResponseError>;
    return rejectWithValue(error);
  }
});
