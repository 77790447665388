import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const getNewTransactionState = (state: StateSchema) => state.newTransaction;

export const getIsNewTransactionLoading = createSelector(getNewTransactionState, (state) => state?.isLoading ?? false);

export const getNewTransactionError = createSelector(getNewTransactionState, (state) => state?.error ?? undefined);

export const getIsSuccessNewTransaction = createSelector(getNewTransactionState, (state) => state?.isSuccess ?? false);

export const getNewTransactionData = createSelector(getNewTransactionState, (state) => state?.newTransaction);

export const getDataToCreateNewTransaction = createSelector(
  getNewTransactionState,
  (state) => state?.dataToCreateNewTransaction
);
