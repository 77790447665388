export interface NewInvoiceError {
  [key: string]: string;
}

export enum CODES {
  CODE_3 = 3,
  CODE_6 = 6,
  CODE_13 = 13
}

export const NEW_INVOICE_FORM_ERROR: NewInvoiceError = {
  [CODES.CODE_3]: 'Amount requested less than minimum value',
  [CODES.CODE_6]: 'Already have transaction with same Client order'
};

export const NEW_INVOICE_ERROR: NewInvoiceError = {
  [CODES.CODE_3]: 'Invalid amount requested',
  [CODES.CODE_13]: 'Internal server error'
};
