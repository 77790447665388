import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { CancelTransactionResponse, GetWithdrawalByIdError } from '../types/withdrawal-details';
import { AlertType, alertActions } from 'entities/Alert';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const cancelTransaction = createAsyncThunk<
  CancelTransactionResponse,
  number,
  ThunkConfig<MyAxiosError<GetWithdrawalByIdError>>
>('paymentGatewayWithdrawalDetails/cancelTransaction', async (id, { rejectWithValue, dispatch, extra }) => {
  try {
    const response = await extra.apiWithAuth.put<CancelTransactionResponse>(
      `/fiat/v1/withdrawal/cancel/${id}`,
      {},
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (!response.data) {
      throw new Error();
    }

    dispatch(
      alertActions.setAlert({
        id: window.crypto.randomUUID(),
        message: 'Withdrawal is canceled',
        timeout: 3000,
        type: AlertType.SUCCESS
      })
    );

    return response.data;
  } catch (e) {
    const error = e as MyAxiosError<GetWithdrawalByIdError>;

    dispatch(
      alertActions.setAlert({
        id: window.crypto.randomUUID(),
        message: error.response?.data.error ?? error.response?.data.message ?? 'Server error',
        timeout: 5000,
        type: AlertType.ERROR
      })
    );

    return rejectWithValue(error);
  }
});
