import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import {
  getPaymentGatewayTransactionsIsLoading,
  getPaymentGatewayTransactionsNumberOfPage
} from '../../selectors/paymentGatewayTransactions/payment-gateway';
import { getPaymentGatewayTransactions } from './getPaymentGatewayTransactions';
import { paymentGatewayTransactionsActions } from '../../slices/transactions/payment-gateway-transactions';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const fetchPrevTransactionsPage = createAsyncThunk<void, void, ThunkConfig<string>>(
  'fiatGateway/fetchPrevTransactionsPage',
  async (_, { dispatch, getState }) => {
    const page = getPaymentGatewayTransactionsNumberOfPage(getState());
    const isLoading = getPaymentGatewayTransactionsIsLoading(getState());

    if (!isLoading && page !== 1) {
      dispatch(paymentGatewayTransactionsActions.setPage(page - 1));
      dispatch(getPaymentGatewayTransactions({ page: page - 1, replace: true }));
    }
  }
);
