import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';
import { ExchangePageSchema } from '../types/exchange';
import { PaginationPages } from 'shared/lib/pagination/requests/getLinkHeader';
import { getExchangeTransactions } from '../services/getExchangeTransactions';
import { DataFilterType, ExchangeTransaction } from 'entities/Transaction';

const exchangeAdapter = createEntityAdapter<ExchangeTransaction>({
  selectId: (transaction) => transaction.id
});

export const getExchangeTransactionsData = exchangeAdapter.getSelectors<StateSchema>(
  (state) => state.exchangeTransactions ?? exchangeAdapter.getInitialState()
);

const exchangeSlice = createSlice({
  name: 'exchangeSlice',
  initialState: exchangeAdapter.getInitialState<ExchangePageSchema>({
    isLoading: false,
    isSuccess: false,
    error: undefined,
    ids: [],
    entities: {},
    status: '',
    page: 1,
    hasMore: true,
    paginationPages: undefined,
    date: {
      dateFrom: null,
      dateTo: null
    },
    isShowResetBtn: false
  }),
  reducers: {
    setResetBtnState: (state) => {
      if ((state.date?.dateFrom && state.date.dateTo) || state.status) {
        state.isShowResetBtn = true;
      } else {
        state.isShowResetBtn = false;
      }
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
      exchangeSlice.caseReducers.setResetBtnState(state);
    },
    setDate: (state, action: PayloadAction<DataFilterType>) => {
      state.date = action.payload;
      exchangeSlice.caseReducers.setResetBtnState(state);
    },
    setResetFilters: (state) => {
      state.status = '';
      state.date = {
        dateFrom: null,
        dateTo: null
      };
      state.isShowResetBtn = false;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    setPaginationPages: (state, action: PayloadAction<PaginationPages>) => {
      state.paginationPages = { ...state.paginationPages, ...action.payload };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getExchangeTransactions.pending, (state, action) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
        if (action.meta.arg.replace) {
          exchangeAdapter.removeAll(state);
        }
      })
      .addCase(getExchangeTransactions.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.hasMore = action.payload.length === 20;
        if (action.meta.arg.replace) {
          exchangeAdapter.setAll(state, action.payload);
        } else {
          exchangeAdapter.addMany(state, action.payload);
        }
        state.isSuccess = true;
      })
      .addCase(getExchangeTransactions.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      });
  }
});

export const { reducer: exchangeReducer, actions: exchangeActions } = exchangeSlice;
