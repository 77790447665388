import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataToCreateNewTransaction, NewTransactionSchema } from '../../types/newTransaction/newTransaction';
import { createNewTransaction } from '../../services/newTransaction/createNewTransaction/createNewTransaction';

const initialState: NewTransactionSchema = {
  error: undefined,
  isLoading: false,
  isSuccess: false,
  dataToCreateNewTransaction: undefined,
  newTransaction: undefined
};

export const newTransaction = createSlice({
  name: 'newTransaction',
  initialState,
  reducers: {
    setDataToCreateNewTransaction: (state, action: PayloadAction<DataToCreateNewTransaction>) => {
      state.dataToCreateNewTransaction = {
        ...state.dataToCreateNewTransaction,
        ...action.payload
      };
    },
    setResetData: (state) => {
      state.dataToCreateNewTransaction = undefined;
      state.isSuccess = false;
      state.newTransaction = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewTransaction.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(createNewTransaction.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.isSuccess = true;
        state.newTransaction = action.payload;
      })
      .addCase(createNewTransaction.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      });
  }
});

export const { actions: newTransactionActions } = newTransaction;
export const { reducer: newTransactionReducer } = newTransaction;
