import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataToCreateNewWithdrawal, NewWithdrawalSchema } from '../types/new-withdrawal';
import { createNewWithdrawal } from '../services/createNewWithdrawal';
import { Destination } from '../types/form';
import BigNumber from 'bignumber.js';
import { NEW_WITHDRAW_FORM_ERROR } from '../../lib/constants/errors/new-withdrawal-errors';

export const INITIAL_DESTINATION = [{ address: '', amount: '', behavior: 'fee_from_rest_amount' }];

const initialState: NewWithdrawalSchema = {
  error: undefined,
  isLoading: false,
  dataToCreateNewWithdrawal: {
    code: '',
    destination: INITIAL_DESTINATION,
    fromAddress: []
  },
  isSuccess: false,

  selectedWalletsBalance: '0',
  currentBalance: undefined,
  formErrors: {
    NOT_ENOUGH_MONEY: null
  }
};

export const newWithdrawalSlice = createSlice({
  name: 'newWithdrawal',
  initialState,
  reducers: {
    setNewWithdrawalData: (state, action: PayloadAction<DataToCreateNewWithdrawal>) => {
      state.dataToCreateNewWithdrawal = {
        ...state.dataToCreateNewWithdrawal,
        ...action.payload
      };
    },
    addFromAddressesValues: (state, action: PayloadAction<string[]>) => {
      state.dataToCreateNewWithdrawal = {
        ...state.dataToCreateNewWithdrawal,
        fromAddress: action.payload
      };
    },
    addDestinationItem: (state, action: PayloadAction<Destination>) => {
      const destination = state.dataToCreateNewWithdrawal?.destination;

      state.dataToCreateNewWithdrawal = {
        ...state.dataToCreateNewWithdrawal,
        destination: [...(destination ?? []), action.payload]
      };
    },
    removeDestinationItem: (state, action: PayloadAction<number>) => {
      const destination = state.dataToCreateNewWithdrawal?.destination;
      destination?.splice(action.payload, 1);
    },
    setDestinationItemFieldValue: (
      state,
      action: PayloadAction<{ index: number; value: string; key: 'address' | 'amount' }>
    ) => {
      const { index, value, key } = action.payload;
      const destination = state.dataToCreateNewWithdrawal?.destination;

      if (destination) {
        const updatedDestinationItem = { ...destination?.[index], [key]: value };
        destination[index] = updatedDestinationItem;
      }
    },
    setResetData: (state) => {
      state.dataToCreateNewWithdrawal = undefined;
      state.newWithdrawal = undefined;
      state.error = undefined;
    },
    setResetBalance: (state) => {
      state.currentBalance = undefined;
      state.formErrors = { NOT_ENOUGH_MONEY: null };
      const destination = state.dataToCreateNewWithdrawal?.destination;
      if (state.dataToCreateNewWithdrawal) {
        state.dataToCreateNewWithdrawal.destination = destination?.map(el => ({
          amount: '',
          address: '',
          behavior: ''
        }));
      }
    },
    setSelectedWalletsBalance: (state, action: PayloadAction<string>) => {
      state.selectedWalletsBalance = action.payload;
    },
    calculateCurrentBalance: (state) => {
      const destination = state.dataToCreateNewWithdrawal?.destination;

      const currentBalance = new BigNumber(state.selectedWalletsBalance ?? '0');
      const newCurrentBalance = destination?.reduce((acc, elem) => {
        if (elem.amount !== '') {
          const operationValue = new BigNumber(elem.amount);
          acc = acc.minus(operationValue);
        }
        return acc;
      }, currentBalance);

      state.currentBalance = newCurrentBalance?.toString() === 'NaN' ? currentBalance.toString() : newCurrentBalance?.toString();

      if (newCurrentBalance && newCurrentBalance.toNumber() < 0) {
        state.formErrors = { ...state.formErrors, NOT_ENOUGH_MONEY: NEW_WITHDRAW_FORM_ERROR.NOT_ENOUGH_MONEY };
      } else {
        state.formErrors = { ...state.formErrors, NOT_ENOUGH_MONEY: null };
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewWithdrawal.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(createNewWithdrawal.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.isSuccess = true;
        state.newWithdrawal = action.payload;
      })
      .addCase(createNewWithdrawal.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      });
  }
});

export const { actions: newWithdrawalActions } = newWithdrawalSlice;
export const { reducer: newWithdrawalReducer } = newWithdrawalSlice;
