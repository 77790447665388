import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { GetWithdrawalByIdError, PaymentGatewayWithdrawalDetails } from '../types/withdrawal-details';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const getWithdrawalById = createAsyncThunk<
  PaymentGatewayWithdrawalDetails,
  string,
  ThunkConfig<MyAxiosError<GetWithdrawalByIdError>>
>('paymentGatewayWithdrawalDetails/getWithdrawalById', async (id, { rejectWithValue, extra }) => {
  try {
    const response = await extra.apiWithAuth.get<PaymentGatewayWithdrawalDetails>('/fiat/v1/withdrawal', {
      params: {
        withdrawal_id: id
      },
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.data) {
      throw new Error();
    }

    return response.data;
  } catch (e) {
    const error = e as MyAxiosError<GetWithdrawalByIdError>;
    return rejectWithValue(error);
  }
});
