import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { ListBox } from 'shared/ui/ListBox/ListBox';
import { DateFilterType } from '../../model/types/types';
import { DateRange, useNavigation } from 'react-day-picker';
import { months } from '../../lib/constants/time/months';
import { getFirstAndLastDaysOfMonth } from '../../lib/getFirstAndLastDaysOfMonth/getFirstAndLastDaysOfMonth';

interface MonthFilterProps {
  setRange: Dispatch<SetStateAction<DateRange | undefined>>;
  handleChange: (date: DateFilterType) => void;
  closePopper: () => void;
}

const MonthFilter: FC<MonthFilterProps> = (props) => {
  const { setRange, closePopper, handleChange } = props;

  const [monthValue, setMonthValue] = useState<string>();
  const [yearValue, setYearValue] = useState('');

  const { goToDate, currentMonth } = useNavigation();

  useEffect(() => {
    setMonthValue(months[currentMonth.getMonth()].value);
    setYearValue(currentMonth.getFullYear().toString());
  }, [currentMonth]);

  const handleChangeMonth = (value: string | number) => {
    const monthIndex = Number(value);
    const newDate = new Date(Number(yearValue), monthIndex, 1);
    goToDate(newDate);
    const days = getFirstAndLastDaysOfMonth(monthIndex, Number(yearValue));

    if (days) {
      const { firstDay, lastDay } = days;
      setRange({ from: firstDay, to: lastDay });
      handleChange({ dateFrom: firstDay, dateTo: lastDay });
    } else {
      return;
    }
    closePopper();
    setMonthValue(value as string);
  };

  return (
    <ListBox
      items={months}
      filterName="Months"
      defaultValue=""
      onChange={handleChangeMonth}
      isAbsolute
      value={monthValue}
    />
  );
};

export { MonthFilter };
