import { AnyAction, Dispatch, Middleware } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { AlertType, alertActions } from 'entities/Alert';

interface MyActionType {
  type: string;
  payload: {
    data: any;
    status: number;
    message: string;
  };
}

export const errorMiddleware = (): Middleware => (store) => (next: Dispatch<AnyAction>) => (action: MyActionType) => {
  const dispatch = store.dispatch;
  if (action.type.endsWith('/rejected')) {
    if (action.payload.status === 408) {
      dispatch(
        alertActions.setAlert({ id: uuidv4(), message: action.payload.message, timeout: 5000, type: AlertType.ERROR })
      );
    }
  }
  return next(action);
};
