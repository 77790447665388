import { createSlice } from '@reduxjs/toolkit';
import { NewExchangeEstimateSchema } from '../../types/newExchangeEstimate/newExchangeEstimate';
import { getNewExchangeEstimate } from '../../services/newExchangeEstimate/newExchangeEstimate';

const initialState: NewExchangeEstimateSchema = {
  error: undefined,
  isLoading: false,
  isSuccess: false,
  newExchangeEstimate: undefined
};

export const newExchangeEstimateSlice = createSlice({
  name: 'newExchangeEstimate',
  initialState,
  reducers: {
    setResetData: (state) => {
      state.error = undefined;
      state.isLoading = false;
      state.isSuccess = false;
      state.newExchangeEstimate = undefined;
    },
    resetError: (state) => {
      state.error = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNewExchangeEstimate.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(getNewExchangeEstimate.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.isSuccess = true;
        state.newExchangeEstimate = action.payload;
      })
      .addCase(getNewExchangeEstimate.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      });
  }
});

export const { actions: newExchangeEstimateActions } = newExchangeEstimateSlice;
export const { reducer: newExchangeEstimateReducer } = newExchangeEstimateSlice;
