import { FC } from 'react';
import styles from './NotFoundPage.module.scss';
import { Box, BoxAlign, BoxJustify } from 'shared/ui/Box/Box';
import { Text } from 'shared/ui/Text/Text';
import { AppLink } from 'shared/ui/AppLink/AppLink';
import notFoundImage from 'app/assets/images/not-found-page.png';

interface NotFoundPageProps {}

const NotFoundPage: FC<NotFoundPageProps> = () => {
  return (
    <section className={styles.notFoundPage}>
      <Box className={styles.wrapper} align={BoxAlign.CENTER} justify={BoxJustify.CENTER}>
        <Text title="404" size="sizeXL" theme="primary" className={styles.text}>
          Sorry, page not found
        </Text>
        <AppLink to="/" className={styles.link} theme="secondary">
          Back to Main
        </AppLink>
        <img src={notFoundImage} alt="Not Found Page" className={styles.image} />
      </Box>
    </section>
  );
};

export { NotFoundPage };
