import { useEffect } from 'react';

type AnyEvent = MouseEvent | TouchEvent;

export const useOnClickOutside = (refs: Array<React.RefObject<HTMLElement>>, handler: () => void) => {
  useEffect(() => {
    const listener = (event: AnyEvent) => {
      const isOutsideClick = refs.every((ref) => !ref.current?.contains(event.target as Node));

      if (isOutsideClick) {
        handler();
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [refs, handler]);
};
