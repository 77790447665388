import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const getNewExchangeState = (state: StateSchema) => state.newExchange;

export const getIsNewExchangeLoading = createSelector(getNewExchangeState, (state) => state?.isLoading ?? false);

export const getNewExchangeError = createSelector(getNewExchangeState, (state) => state?.error ?? undefined);

export const getIsSuccessNewExchange = createSelector(getNewExchangeState, (state) => state?.isSuccess ?? false);

export const getNewExchangeData = createSelector(getNewExchangeState, (state) => state?.newExchange);

export const getDataToCreateNewExchange = createSelector(
  getNewExchangeState,
  (state) => state?.dataToCreateNewExchange
);

export const getNewExchangeFormError = createSelector(getNewExchangeState, (state) => state?.formErrors);

export const getNewExchangeFormValues = createSelector(getNewExchangeState, (state) => state?.formValues);

export const getNewExchangeDataValid = createSelector(getNewExchangeState, (state) => state?.isDataValid);
