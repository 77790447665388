export interface NormalDate {
  date: number;
  month: number;
  year: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export const getNormalDateFromISO = (isoString: string): NormalDate => {
  const date = new Date(isoString);

  return {
    date: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    hours: date.getHours(),
    minutes: date.getMinutes(),
    seconds: date.getSeconds()
  };
};
