/* eslint-disable @typescript-eslint/no-unsafe-argument */
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'app/App';
import { StoreProvider } from 'app/providers/StoreProvider';
import { UnsavedChangesProvider } from 'app/providers/UnsavedChangesProvider';
import { Web3ContextProvider } from 'app/providers/Web3Provider';
import { AvailableCurrenciesProvider } from 'app/providers/AvailableCurrenciesProvider';
import { ErrorBoundary } from 'app/providers/ErrorBoundary';

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <BrowserRouter>
    <ErrorBoundary>
      <StoreProvider>
        <Web3ContextProvider>
          <UnsavedChangesProvider>
            <AvailableCurrenciesProvider>
              <App />
            </AvailableCurrenciesProvider>
          </UnsavedChangesProvider>
        </Web3ContextProvider>
      </StoreProvider>
    </ErrorBoundary>
  </BrowserRouter>
);
