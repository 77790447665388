import { createEntityAdapter, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getCryptoTransactions } from '../services/getCryptoTransactions';
import { CryptoTransactionsPageSchema } from '../types/crypto-transactions';
import { StateSchema } from 'app/providers/StoreProvider';
import { PaginationPages } from 'shared/lib/pagination/requests/getLinkHeader';
import { CryptoTransaction } from 'entities/Transaction';
import { getCryptoTransactionOutgoing } from 'pages/CryptoTransactionsPage/model/services/getCryptoTransactionOutgoing';
import { downloadCryptoTransactionsCsv } from '../services/downloadCryptoTransactionsCsv/downloadCryptoTransactionsCsv';

const transactionsAdapter = createEntityAdapter<CryptoTransaction>({
  selectId: (data) => data.id
});

export const getTransactionsState = transactionsAdapter.getSelectors<StateSchema>(
  (state) => state.cryptoTransactionsPage ?? transactionsAdapter.getInitialState()
);

export const cryptoTransaction = createSlice({
  name: 'cryptoTransactions',

  initialState: transactionsAdapter.getInitialState<CryptoTransactionsPageSchema>({
    error: undefined,
    isLoading: false,
    ids: [],
    entities: {},
    paginationPages: undefined,
    limit: 20,
    hasMore: false,

    isTransactionsCsvDownLoading: false,
    isTransactionsCsvDownLoadingSuccess: false,
    transactionsCsvDownLoadingError: undefined
  }),
  reducers: {
    setPaginationPages: (state, action: PayloadAction<PaginationPages | undefined>) => {
      state.paginationPages = { ...state.paginationPages, ...action.payload };
    },
    resetCsvDownloading: (state) => {
      state.isTransactionsCsvDownLoading = false;
      state.isTransactionsCsvDownLoadingSuccess = false;
      state.transactionsCsvDownLoadingError = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCryptoTransactions.pending, (state, action) => {
        state.error = undefined;
        state.isLoading = true;
        if (action.meta.arg.replace) {
          transactionsAdapter.setAll(state, []);
        }
      })
      .addCase(getCryptoTransactions.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        const data = action.payload.transactions.sort((a, b) => {
          const dateA = new Date(a.createdAt).getTime();
          const dateB = new Date(b.createdAt).getTime();
          return dateB - dateA;
        });
        state.hasMore = action?.payload?.transactions.length === 20;
        if (action.meta.arg.replace) {
          transactionsAdapter.setAll(state, data);
        } else {
          transactionsAdapter.addMany(state, data);
        }
      })
      .addCase(getCryptoTransactions.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(downloadCryptoTransactionsCsv.pending, (state) => {
        state.isTransactionsCsvDownLoading = true;
        state.isTransactionsCsvDownLoadingSuccess = false;
        state.transactionsCsvDownLoadingError = undefined;
      })
      .addCase(downloadCryptoTransactionsCsv.fulfilled, (state) => {
        state.isTransactionsCsvDownLoading = false;
        state.isTransactionsCsvDownLoadingSuccess = true;
      })
      .addCase(downloadCryptoTransactionsCsv.rejected, (state, action) => {
        state.isTransactionsCsvDownLoading = false;
        state.transactionsCsvDownLoadingError = action.payload;
      });
  }
});

export const { actions: cryptoTransactionsActions } = cryptoTransaction;
export const { reducer: cryptoTransactionsReducer } = cryptoTransaction;
