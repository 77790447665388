import { createSlice } from '@reduxjs/toolkit';
import { getBalance } from '../services/getBalance';
import { BalanceSchema, Token } from '../types/balance';
import { CRYPTO_SHORT_NAMES, CRYPTO_VALUES_TO_DIVIDE, CryptoName } from 'shared/constants/crypto/crypto-names';
import { CURRENCY_WEIGHT } from 'shared/constants/crypto/currency-weight';
import { getAllAvailableTokens } from '../services/getAllAvailableTokens';

const initialState: BalanceSchema = {
  error: undefined,
  data: undefined,
  normalBalance: undefined,
  isLoading: false
};

export const balanceSlice = createSlice({
  name: 'balance',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBalance.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(getBalance.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.data = action.payload;
        state.normalBalance = action.payload.balances
          .sort((a, b) => CURRENCY_WEIGHT[b.code as CryptoName] - CURRENCY_WEIGHT[a.code as CryptoName])
          .map((item) => {
            if (item.balance.length !== 0) {
              const { code, value, exchangeRate } = item.balance[0];
              const divider = CRYPTO_VALUES_TO_DIVIDE[code.toUpperCase() as CRYPTO_SHORT_NAMES];

              const newData = Object.assign({ value: 0 }, { code, exchangeRate });
              newData.value = Number(value) / divider;

              return {
                balance: [newData],
                code: item.code,
                provider: item.provider
              };
            } else {
              return {
                balance: [{ value: 0, code: item.code, exchangeRate: 0 }],
                code: item.code,
                provider: item.provider
              };
            }
          });
      })
      .addCase(getBalance.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getAllAvailableTokens.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(getAllAvailableTokens.fulfilled, (state, action) => {
        state.error = undefined;
        state.blockchains = action.payload.blockchains;
        state.availableTokens = action.payload.blockchains
          .reduce((prev: Token[], curr: Token) => {
            if (curr.tokens && curr.tokens.length > 0) {
              prev.push(...curr.tokens, curr);
            } else {
              prev.push(curr);
            }
            return prev;
          }, [])
          .filter((token) => token.code !== 'bsc');
        state.isLoading = false;
      })
      .addCase(getAllAvailableTokens.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  }
});

export const { actions: balanceActions } = balanceSlice;
export const { reducer: balanceReducer } = balanceSlice;
