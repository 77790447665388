import { PeriodFilterValue } from '../../model/types/types';
import {
  FIRST_DAY_OF_CURRENT_MONTH,
  FIRST_DAY_OF_LAST_MONTH,
  LAST_DAY_OF_CURRENT_MONTH,
  LAST_DAY_OF_LAST_MONTH
} from '../constants/time/months';

export const getMonthPeriod = (
  fromDay: number | undefined,
  toDay: number | undefined
): PeriodFilterValue | undefined => {
  if (fromDay === FIRST_DAY_OF_CURRENT_MONTH && toDay === LAST_DAY_OF_CURRENT_MONTH) {
    return 'current';
  } else if (fromDay === FIRST_DAY_OF_LAST_MONTH && toDay === LAST_DAY_OF_LAST_MONTH) {
    return 'previous';
  }
};
