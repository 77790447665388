import { FC, ReactNode } from 'react';
import styles from './Container.module.scss';
import classNames from 'classnames';

export type ContainerTheme = 'wrapper_with_bg';
export type ContainerBoxShadow = 'box-shadow';
export type ContainerBorderRadius = '4';

const themeClasses: Record<ContainerTheme, string> = {
  wrapper_with_bg: styles.wrapper_with_bg
};

const boxShadowClasses: Record<ContainerBoxShadow, string> = {
  'box-shadow': styles['box-shadow']
};

const borderRadiusClasses: Record<ContainerBorderRadius, string> = {
  4: styles.br4
};

interface ContainerProps {
  className?: string;
  children?: ReactNode;
  theme?: ContainerTheme;
  border?: boolean;
  boxShadow?: ContainerBoxShadow;
  radius?: ContainerBorderRadius;
}

const Container: FC<ContainerProps> = (props) => {
  const { className, children, theme, border = false, boxShadow, radius } = props;

  const classes = [
    className,
    theme && themeClasses[theme],
    boxShadow && boxShadowClasses[boxShadow],
    radius && borderRadiusClasses[radius]
  ];

  const mods = {
    [styles.border]: border
  };

  return <div className={classNames(styles.container, mods, classes)}>{children}</div>;
};

export { Container };
