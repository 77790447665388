import { TableHeader } from 'widgets/Table';

export const transactionsHeaders: TableHeader[] = [
  {
    id: 0,
    text: 'Date',
    value: 'date'
  },
  {
    id: 1,
    text: 'Client order',
    value: 'client_order'
  },
  {
    id: 2,
    text: 'Amount',
    value: 'amount'
  },
  {
    id: 3,
    text: 'Currency',
    value: 'amount_currency'
  },
  {
    id: 4,
    text: 'Payment Method',
    value: 'payment_method'
  },
  {
    id: 5,
    text: 'Description',
    value: 'description'
  },
  {
    id: 6,
    text: 'Status',
    value: 'status'
  },
  {
    id: 7,
    text: '',
    value: 'resend_callback'
  }
];
