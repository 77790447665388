import { envURL } from 'shared/constants/envUrl';

export const getDataFromUrl = () => {
  const params = new URLSearchParams(window.location.href);

  const authContext = params.get(`${envURL}/?auth_context`);
  const code = params.get('code');

  return { authContext, code };
};
