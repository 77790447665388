import React from 'react';
import styles from './CustomSelect.module.scss';
import classNames from 'classnames';
import Select, { GroupBase, Props } from 'react-select';
import { Label, LabelSize } from 'shared/ui/Label/Label';

export interface Option {
  label: string;
  value: string;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  description?: string;
}

export interface SelectProps<Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>> extends Props<Option, IsMulti, Group> {
  className?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  id?: string;
  label?: string;
  labelSize?: LabelSize;
  disabled?: boolean;
  selectRef?: React.ForwardedRef<HTMLElement>;
}

function CustomSelect<Option, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: SelectProps<Option, IsMulti, Group>
) {
  const { className, id, label, labelSize, disabled, selectRef, onBlur, ...otherProps } = props;
  return (
    <div className={classNames(styles.box, className)}>
      {label && (
        <Label size={labelSize} htmlFor={id} weight='500' className={styles.label}>
          {label}
        </Label>
      )}
      <Select onBlur={onBlur} inputId={id} {...otherProps}/>
    </div>
  );
}

export { CustomSelect };
