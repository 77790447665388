import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

const getAffiliateState = (state: StateSchema) => state.affiliateReport;

export const getAffiliateReportData = createSelector(getAffiliateState, (state) => state?.reportData);
export const getIsAffiliateReportDataLoading = createSelector(getAffiliateState, (state) => state?.isLoading ?? false);
export const getIsAffiliateReportDataSuccess = createSelector(getAffiliateState, (state) => state?.isSuccess ?? false);

// Filters
export const getAffiliateReportCurrencyFilter = createSelector(getAffiliateState, (state) => state?.currency);
export const getAffiliateReportDateFilter = createSelector(getAffiliateState, (state) => state?.date);
export const getAffiliateReportPeriodGroupFilter = createSelector(getAffiliateState, (state) => state?.periodGroup);
export const getIsShowAffiliateReportDateFiltersResetBtn = createSelector(
  getAffiliateState,
  (state) => state?.isShowResetBtn
);
