import { Option } from 'shared/ui/CustomSelect/CustomSelect';

export const periodOptions: Option[] = [
  {
    label: 'Current month',
    value: 'current'
  },
  {
    label: 'Previous month',
    value: 'previous'
  }
];
