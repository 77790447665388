export enum AlertType {
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error'
}

export interface TAlert {
  id: string;
  message: string;
  type: AlertType;
  timeout: number;
  infinite?: boolean;
}

export interface AlertSchema {
  alerts: TAlert[];
}
