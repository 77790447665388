/* eslint-disable @typescript-eslint/no-unused-vars */
import { CombinedState, configureStore, getDefaultMiddleware, Reducer, ReducersMapObject } from '@reduxjs/toolkit';
import { StateSchema } from './StateSchema';
import { createReducerManager } from './reducerManager';
import { $keycloak } from 'shared/api/keycloak';
import { userReducer } from 'entities/User';
import { $apiWithAuth } from 'shared/api/axiosWithAuth';
import { balanceReducer } from 'entities/Balance/model/slices/balance';
import { $api } from 'shared/api/api';
import { newInvoiceReducer } from 'features/NewInvoiceForm';
import { newWithdrawalReducer } from 'features/NewWithdrawalForm';
import { errorMiddleware } from '../middlewares/errorMiddleware';
import { alertReducer } from 'entities/Alert';
import { newTransactionReducer } from 'features/NewTransactionForm/model/slices/newTransaction/newTransaction';
import { newPaymentGatewayWithdrawalReducer } from 'features/NewPaymentGateWithdrawalForm';
import { newExchangeReducer } from 'features/NewExchangeForm';
import { exchangeRateReducer } from 'features/ExchangeRate/model/slices/exchange-rate';
import { newPaymentlinkReducer } from 'features/NewPaymentLinkForm/model/slices/create-payment-link';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const createReduxStore = (initialState?: StateSchema, asyncReducers?: ReducersMapObject<StateSchema>) => {
  const rootReducer: ReducersMapObject<StateSchema> = {
    ...asyncReducers,
    user: userReducer,
    balance: balanceReducer,
    newInvoice: newInvoiceReducer,
    exchangeRate: exchangeRateReducer,
    newPaymentLink: newPaymentlinkReducer,
    newWithdrawal: newWithdrawalReducer,
    newTransaction: newTransactionReducer,
    newPaymentGatewayWithdrawal: newPaymentGatewayWithdrawalReducer,
    newExchange: newExchangeReducer,
    alert: alertReducer
  };

  const reducerManager = createReducerManager(rootReducer);

  const store = configureStore({
    reducer: reducerManager.reduce as Reducer<CombinedState<StateSchema>>,
    devTools: import.meta.env.VITE_VERCEL_ENV === 'development' || import.meta.env.VITE_VERCEL_ENV === 'preview',
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: {
            keycloak: $keycloak,
            apiWithAuth: $apiWithAuth,
            api: $api
          }
        }
      }).concat(errorMiddleware())
  });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment, @typescript-eslint/prefer-ts-expect-error
  // @ts-ignore
  store.reducerManager = reducerManager;

  return store;
};

export type AppDispatch = ReturnType<typeof createReduxStore>['dispatch'];
