import { TRANSACTIONS_STATUSES } from 'shared/constants/crypto/transactions-status';
import { Option } from 'shared/ui/CustomSelect/CustomSelect';

export const paymentGateTransactionsStatuses: Option[] = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Pending',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_PENDING
  },
  {
    label: 'Sending',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_SENDING
  },
  {
    label: 'Waiting',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_WAITING
  },
  {
    label: 'Confirmed',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_CONFIRMED
  },
  {
    label: 'Processed',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_PROCESSED
  },
  {
    label: 'Failed',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_FAILED
  },
  {
    label: 'Cancelled',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_CANCELED
  },
  {
    label: 'Skipped',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_SKIPPED
  },
  {
    label: 'Dust',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_DUST
  },
  {
    label: 'Processing',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_PROCESSING
  },
  {
    label: 'Check required',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_CHECK_REQUIRED
  },
  {
    label: 'Approve required',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_APPROVE_REQUIRED
  },
  {
    label: 'Refund',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_REFUND
  },
  {
    label: 'Refunded',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_REFUNDED
  },
  {
    label: 'Refunding',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_REFUNDING
  },
  {
    label: 'Expired',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXPIRED
  }
];
