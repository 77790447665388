import { FC, ReactNode } from 'react';
import styles from './TableBodyItem.module.scss';
import classNames from 'classnames';
import { TransactionStatus } from 'widgets/TransactionStatus';
import { TextTheme } from 'shared/ui/Text/Text';

interface TableBodyItemProps {
  children?: ReactNode;
  status?: string;
  className?: string;
  theme?: TextTheme;
}

const TableBodyItem: FC<TableBodyItemProps> = (props) => {
  const { children, status, className } = props;

  return (
    <td className={classNames(styles.td, [className])}>
      {status ? <TransactionStatus status={status} className={styles.status} /> : children}
    </td>
  );
};

export { TableBodyItem };
