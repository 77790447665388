import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const getNewWithdrawalState = (state: StateSchema) => state.newWithdrawal;

export const getDataToCreateNewWithdrawal = createSelector(
  getNewWithdrawalState,
  (state) => state?.dataToCreateNewWithdrawal
);

export const getIsCreateNewWithdrawalLoading = createSelector(
  getNewWithdrawalState,
  (state) => state?.isLoading ?? false
);

export const getCreateNewWithdrawalError = createSelector(getNewWithdrawalState, (state) => state?.error ?? undefined);

export const getNewWithdrawalData = createSelector(getNewWithdrawalState, (state) => state?.newWithdrawal ?? undefined);

export const getIsSuccessCreateWithdrawal = createSelector(getNewWithdrawalState, (state) => state?.isSuccess ?? false);

export const getSelectedWalletsBalance = createSelector(
  getNewWithdrawalState,
  (state) => state.selectedWalletsBalance ?? '0'
);

export const getCurrentBalance = createSelector(getNewWithdrawalState, (state) => state.currentBalance ?? '0');

export const getNewWithdrawalFormErrors = createSelector(getNewWithdrawalState, (state) => state.formErrors ?? '0');
