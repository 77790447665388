import { FC, useCallback, useEffect } from 'react';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { Button } from 'shared/ui/Button/Button';
import styles from './SendingCallbackButton.module.scss';
import { useAsync } from 'shared/lib/hooks/useAsync/useAsync';
import { AlertType, alertActions } from 'entities/Alert';
import { resendCallback } from '../../model/services/resendCallback/resendCallback';

interface SendingCallbackButtonProps {
  id: number;
}

const SendingCallbackButton: FC<SendingCallbackButtonProps> = ({ id }) => {
  const dispatch = useAppDispatch();

  const {
    execute: resendCallbackExecute,
    loading: resendCallbackLoading,
    status: resendCallbackStatus,
    value: resendCallbackValue
  } = useAsync(async () => await resendCallback(id), false);

  const handleResendCallback = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      e.stopPropagation();
      resendCallbackExecute();
    },
    [resendCallbackExecute]
  );

  useEffect(() => {
    if (resendCallbackStatus === 'success') {
      dispatch(
        alertActions.setAlert({
          id: crypto.randomUUID(),
          message: 'Callback is successfully sended',
          timeout: 3000,
          type: AlertType.SUCCESS
        })
      );
    }
  }, [resendCallbackStatus]);

  return (
    <Button
      onClick={handleResendCallback}
      type="button"
      size="sizeM"
      theme="primary"
      radius="4px"
      disabled={resendCallbackLoading}
      className={styles.resendBtn}
    >
      {resendCallbackLoading ? 'Resending...' : 'Re-send Callback'}
    </Button>
  );
};

export { SendingCallbackButton };
