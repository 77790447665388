/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import Web3 from 'web3';

const initializeGlobalWeb3 = () => {
  window.web3 = window.web3 && new Web3(window.ethereum);
};

export default initializeGlobalWeb3;
