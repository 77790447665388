import { PayloadAction, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';
import { PaymentGatewayPageTransactionsSchema } from '../../types/transactions/payment-gateway-transactions';
import { getPaymentGatewayTransactions } from '../../services/transactions/getPaymentGatewayTransactions';
import { downloadPaymentGateTransactionsCsv, PaymentGatewayTransaction } from 'entities/PaymentGateway';
import { DataFilterType } from 'entities/Transaction';

const paymentGatewayAdapter = createEntityAdapter<PaymentGatewayTransaction>({
  selectId: (transaction) => transaction.id
});

export const getPaymentGatewayTransactionsData = paymentGatewayAdapter.getSelectors<StateSchema>(
  (state) => state.paymentGatewayTransactions ?? paymentGatewayAdapter.getInitialState()
);

const paymentGatewayTransactionsSlice = createSlice({
  name: 'paymentGatewayTransactionsSlice',
  initialState: paymentGatewayAdapter.getInitialState<PaymentGatewayPageTransactionsSchema>({
    isLoading: false,
    error: undefined,
    ids: [],
    entities: {},
    status: '',
    hasMore: true,
    page: 1,
    date: {
      dateFrom: null,
      dateTo: null
    },
    currency: '',
    paymentMethod: '',
    isPgTransactionsReporting: false,
    isPgTransactionsReportingSuccess: false,
    pgTransactionsReportingError: undefined
  }),
  reducers: {
    setResetBtnState: (state) => {
      if (state.currency || (state.date?.dateFrom && state.date.dateTo) || state.status) {
        state.isShowResetBtn = true;
      } else {
        state.isShowResetBtn = false;
      }
    },
    setCurrency: (state, action: PayloadAction<string>) => {
      state.currency = action.payload;
      paymentGatewayTransactionsSlice.caseReducers.setResetBtnState(state);
    },
    setPaymentMethod: (state, action: PayloadAction<string>) => {
      state.paymentMethod = action.payload;
      paymentGatewayTransactionsSlice.caseReducers.setResetBtnState(state);
    },
    setDate: (state, action: PayloadAction<DataFilterType>) => {
      state.date = action.payload;
      paymentGatewayTransactionsSlice.caseReducers.setResetBtnState(state);
    },
    setStatus: (state, action: PayloadAction<string>) => {
      state.status = action.payload;
      paymentGatewayTransactionsSlice.caseReducers.setResetBtnState(state);
    },
    setResetFilters: (state) => {
      state.status = '';
      state.currency = '';
      state.paymentMethod = '';
      state.date = undefined;
      state.isShowResetBtn = false;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentGatewayTransactions.pending, (state, action) => {
        state.error = undefined;
        state.isLoading = true;

        if (action.meta.arg.replace) {
          paymentGatewayAdapter.removeAll(state);
        }
      })
      .addCase(getPaymentGatewayTransactions.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.hasMore = action.payload.length === 20;

        if (action.meta.arg.replace) {
          paymentGatewayAdapter.setAll(state, action.payload);
        } else {
          paymentGatewayAdapter.addMany(state, action.payload);
        }
      })
      .addCase(getPaymentGatewayTransactions.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })

      .addCase(downloadPaymentGateTransactionsCsv.pending, (state, action) => {
        state.isPgTransactionsReporting = true;
        state.isPgTransactionsReportingSuccess = false;
        state.pgTransactionsReportingError = undefined;
      })
      .addCase(downloadPaymentGateTransactionsCsv.fulfilled, (state, action) => {
        state.isPgTransactionsReporting = false;
        state.isPgTransactionsReportingSuccess = true;
      })
      .addCase(downloadPaymentGateTransactionsCsv.rejected, (state, action) => {
        state.isPgTransactionsReporting = false;
        state.pgTransactionsReportingError = action.payload;
      });
  }
});

export const { reducer: paymentGatewayTransactionsReducer, actions: paymentGatewayTransactionsActions } =
  paymentGatewayTransactionsSlice;
