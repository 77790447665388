import { FC, memo, useCallback, useRef, useState } from 'react';
import styles from './UserInfo.module.scss';
import classNames from 'classnames';
import { Text } from 'shared/ui/Text/Text';
import { Button } from 'shared/ui/Button/Button';
import { ReactComponent as ArrowIcon } from 'app/assets/icons/arrow.svg';
import { Icon } from 'shared/ui/Icon/Icon';
import { LogoutButton } from '../LogoutButton/LogoutButton';
import { useSelector } from 'react-redux';
import { getUserData } from 'entities/User/model/selectors/user';
import { useOnClickOutside } from 'shared/lib/hooks/useOnClickOutside/useOnClickOutside';

export enum TextSize {
  SIZE_M = 'size_m',
  SIZE_L = 'size_l',
  SIZE_XL = 'size_xl'
}

interface UserInfoProps {
  className?: string;
  size?: TextSize;
}

const UserInfo: FC<UserInfoProps> = memo(({ className, size = TextSize.SIZE_M }) => {
  const [isLogoutActive, setIsLogoutActive] = useState(false);

  const buttonRef = useRef<HTMLButtonElement>(null);
  const menuRef = useRef<HTMLButtonElement>(null);

  const userData = useSelector(getUserData);

  const mods = {
    [styles[size]]: size
  };

  const logoutMods = {
    [styles.isLogoutActive]: isLogoutActive
  };

  const handleLogoutSwitcher = useCallback(() => {
    setIsLogoutActive((prev) => !prev);
  }, []);

  useOnClickOutside([buttonRef, menuRef], () => setIsLogoutActive(false));

  return (
    <div className={classNames(styles.userInfo, mods, [className])}>
      <div className={styles.wrapper}>
        {userData && (
          <Text theme="greyColor400">
            {userData.given_name!} {userData.family_name!}
          </Text>
        )}
        <Button ref={buttonRef} square size="sizeM" className={styles.arrowButton} onClick={handleLogoutSwitcher}>
          <Icon SVG={ArrowIcon} className={styles.arrow} />
        </Button>
      </div>
      {isLogoutActive && <LogoutButton menuRef={menuRef} className={classNames(styles.logoutButton, logoutMods)} />}
    </div>
  );
});

export { UserInfo };
