import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataToCreateNewPaymentLink, NewPaymentLinkSchema, ResponseError } from '../types/new-payment-link';
import { createNewPaymentLink } from '../services/createNewPaymentLink';
import { MyAxiosError } from 'shared/types/axios';
import { getMinFiatAmount } from 'features/NewPaymentLinkForm/model/services/getMinFiatAmount';

const initialState: NewPaymentLinkSchema = {
  error: undefined,
  isLoading: false,
  dataToCreateNewPaymentLink: undefined,
  newPaymentLink: undefined,
  minAmount: undefined,
  isSuccess: false
};

export const newPaymentLinkSlice = createSlice({
  name: 'newPaymentLink',
  initialState,
  reducers: {
    setNewPaymentLinkData: (state, action: PayloadAction<DataToCreateNewPaymentLink>) => {
      state.dataToCreateNewPaymentLink = {
        ...state.dataToCreateNewPaymentLink,
        ...action.payload
      };
    },
    setResetData: (state) => {
      state.dataToCreateNewPaymentLink = undefined;
    },
    setError: (state, action: PayloadAction<MyAxiosError<ResponseError> | undefined>) => {
      state.error = action.payload;
    },
    setSuccess: (state, action: PayloadAction<boolean | undefined>) => {
      state.isSuccess = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewPaymentLink.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(createNewPaymentLink.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.isSuccess = true;
        state.newPaymentLink = action.payload;
      })
      .addCase(createNewPaymentLink.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      });
    builder
      .addCase(getMinFiatAmount.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(getMinFiatAmount.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        // state.isSuccess = true;
        state.minAmount = action.payload.amount;
      })
      .addCase(getMinFiatAmount.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      });
  }
});

export const { actions: newPaymentlinkActions } = newPaymentLinkSlice;
export const { reducer: newPaymentlinkReducer } = newPaymentLinkSlice;
