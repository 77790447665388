import { FC, memo } from 'react';
import { Loader } from 'widgets/Loader';

interface TableLoaderProps {
  className?: string;
}

const TableLoader: FC<TableLoaderProps> = memo(({ className }) => {
  return (
    <tbody>
      <tr>
        <td>
          <Loader className={className} />
        </td>
      </tr>
    </tbody>
  );
});

export { TableLoader };
