import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import styles from './Balance.module.scss';
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { getPaymentGateBalances } from '../../model/services/balances/getPaymentGateBalances';
import { useSelector } from 'react-redux';
import { Text } from 'shared/ui/Text/Text';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { TRANSACTIONS_STATUS } from 'shared/constants/crypto/transactions-status';
import { PaymentGateSortedBalance } from '../../model/types/balance/balance';
import { getPaymentGatewayBalanceData } from '../../model/selectors/balances/balances';
import { Button } from 'shared/ui/Button/Button';
import { Icon } from 'shared/ui/Icon/Icon';
import { ReactComponent as ArrowIcon } from 'app/assets/icons/dropdown-icon.svg';
import { useLocation } from 'react-router-dom';

interface BalanceProps {
  handleFilterByBalance: (status: string, currency: string) => void;
}

const Balance: FC<BalanceProps> = memo((props) => {
  const { handleFilterByBalance } = props;

  const dispatch = useAppDispatch();

  const balance = useSelector(getPaymentGatewayBalanceData);

  const [showMoreData, setShowMoreData] = useState(false);
  const contentRef = useRef<HTMLUListElement>(null);
  const [contentHeight, setContentHeight] = useState<string>('0px');

  const location = useLocation();

  const isTransactions = location.pathname === '/fiat-gateway/transactions';
  // const isWithdrawals = location.pathname === '/fiat-gateway/withdrawals';

  const status = isTransactions ? 'transaction_status_' : '';

  const handleToggle = () => {
    setShowMoreData(!showMoreData);
  };

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(showMoreData ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [showMoreData]);

  useEffect(() => {
    dispatch(getPaymentGateBalances());
  }, [dispatch]);

  const sortedBalance = useMemo(() => {
    return balance?.reduce((prev, curr) => {
      const status = curr.status;

      if (prev[status]) {
        prev[status].push({ amount: curr.amount, currency: curr.currency });
      } else {
        prev[status] = [{ amount: curr.amount, currency: curr.currency }];
      }
      return prev;
    }, {} as PaymentGateSortedBalance);
  }, [balance]);

  const balanceData = useMemo(() => {
    if (sortedBalance) {
      return Object.entries(sortedBalance).filter((item) => {
        const [key, value] = item;

        return key === 'complete';
      });
    }

    return null;
  }, [sortedBalance]);

  const otherData = useMemo(() => {
    if (sortedBalance) {
      return Object.entries(sortedBalance).filter((item) => {
        const [key, value] = item;

        return key !== 'complete';
      });
    }

    return null;
  }, [sortedBalance]);
  if (!sortedBalance) return null;

  return (
    <>
      <div className={styles.balanceItem}>
        {balanceData?.length !== 0 && (
          <div className={styles.wrapper}>
            <p className={classNames(styles.balanceStatus, styles.complete)}>Balance:</p>
            <div className={styles.balanceWrapper}>
              {balanceData?.[0][1].map(({ amount, currency }) => {
                const correctAmount = new BigNumber(amount);
                return (
                  <Text
                    key={currency}
                    size='sizeS'
                    weight='500'
                    theme='greyColor100'
                    className={styles.value}
                    onClick={() => handleFilterByBalance(status + 'confirmed', currency)}
                  >
                    {correctAmount.toString()}&nbsp;{currency}
                  </Text>
                );
              })}
            </div>
          </div>
        )}
        <Button
          type='button'
          className={classNames(styles.dropdownBtn, { [styles.opened]: showMoreData })}
          square
          size='sizeL'
          onClick={handleToggle}
        >
          <Icon SVG={ArrowIcon} />
        </Button>
      </div>
      <ul className={styles.balanceList} style={{ height: contentHeight }} ref={contentRef}>
        {otherData?.map((item) => {
          const [key, value] = item;

          return (
            <li className={styles.balanceItem} key={key}>
              <p className={classNames(styles.balanceStatus, { [styles[key]]: true })}>
                {TRANSACTIONS_STATUS[`transaction_status_${key}`]}:
              </p>
              <div className={styles.balanceWrapper}>
                {value.map(({ amount, currency }) => {
                  const correctAmount = new BigNumber(amount);
                  return (
                    <Text
                      key={currency}
                      size='sizeS'
                      weight='500'
                      theme='greyColor100'
                      className={styles.value}
                      onClick={() => handleFilterByBalance(status + key, currency)}
                    >
                      {correctAmount.toString()}&nbsp;{currency}
                    </Text>
                  );
                })}
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
});

export { Balance };
