import { WITHDRAWALS_STATUSES } from 'shared/constants/crypto/withdrawals-status';
import { Option } from 'shared/ui/CustomSelect/CustomSelect';

export const paymentGateWithdrawalsStatuses: Option[] = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Pending',
    value: WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_PENDING
  },
  {
    label: 'Waiting',
    value: WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_WAITING
  },
  {
    label: 'Completed',
    value: WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_COMPLETED
  },
  {
    label: 'Failed',
    value: WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_FAILED
  },
  {
    label: 'Cancelled',
    value: WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_CANCELED
  }
];
