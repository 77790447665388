import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const getPaymentGatewayPageTransactionsState = (state: StateSchema) => state?.paymentGatewayTransactions;

export const getPaymentGatewayTransactionsIsLoading = createSelector(
  getPaymentGatewayPageTransactionsState,
  (state) => state?.isLoading ?? false
);

export const getPaymentGatewayTransactionsHasMore = createSelector(
  getPaymentGatewayPageTransactionsState,
  (state) => state?.hasMore ?? false
);

export const getPaymentGatewayTransactionsError = createSelector(
  getPaymentGatewayPageTransactionsState,
  (state) => state?.error
);

export const getPaymentGatewayTransactionsNumberOfPage = createSelector(
  getPaymentGatewayPageTransactionsState,
  (state) => state?.page ?? 1
);

export const getIsPaymentGatewayTransactionsFiltersResetBtn = createSelector(
  getPaymentGatewayPageTransactionsState,
  (state) => state?.isShowResetBtn
);

// Report
export const getIsPgTransactionsReporting = createSelector(
  getPaymentGatewayPageTransactionsState,
  (state) => state?.isPgTransactionsReporting
);
export const getIsPgTransactionsReportingSuccess = createSelector(
  getPaymentGatewayPageTransactionsState,
  (state) => state?.isPgTransactionsReportingSuccess
);
export const getPgTransactionsReportingError = createSelector(
  getPaymentGatewayPageTransactionsState,
  (state) => state?.pgTransactionsReportingError
);

// Filter
export const getPaymentGateTransactionPaymentMethodFilter = createSelector(
  getPaymentGatewayPageTransactionsState,
  (state) => state?.paymentMethod ?? ''
);
export const getPaymentGatewayTransactionsCurrency = createSelector(
  getPaymentGatewayPageTransactionsState,
  (state) => state?.currency ?? ''
);
export const getPaymentGatewayTransactionsStatus = createSelector(
  getPaymentGatewayPageTransactionsState,
  (state) => state?.status ?? ''
);
export const getPaymentGatewayTransactionsDateFilter = createSelector(
  getPaymentGatewayPageTransactionsState,
  (state) => state?.date
);
