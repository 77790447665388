import { Dispatch, FC, SetStateAction } from 'react';
import styles from './CustomCaption.module.scss';
import { CaptionNavigation, CaptionProps, DateRange } from 'react-day-picker';
import { CustomDropdown } from '../CustomDropdown/CustomDropdown';
import { PeriodFilter } from '../PeriodFilter/PeriodFilter';
import { DateFilterType } from '../../model/types/types';
import { MonthFilter } from '../MonthFilter/MonthFilter';

interface CustomCaptionProps extends CaptionProps {
  showPeriodFilter?: boolean;
  setRange: Dispatch<SetStateAction<DateRange | undefined>>;
  handleChange: (date: DateFilterType) => void;
  closePopper: () => void;
  showMonthSelect?: boolean;
  showYearSelect?: boolean;
  showMonthFilter?: boolean;
}

const CustomCaption: FC<CustomCaptionProps> = (props) => {
  const {
    showPeriodFilter,
    setRange,
    closePopper,
    handleChange,
    showMonthSelect,
    showYearSelect,
    showMonthFilter,
    ...captionProps
  } = props;

  return (
    <div className={styles.customCaption}>
      <div className={styles.head}>
        <CustomDropdown showMonthSelect={showMonthSelect} showYearSelect={showYearSelect} />
        {CaptionNavigation(captionProps)}
      </div>
      <div className={styles.customFilters}>
        {showPeriodFilter && <PeriodFilter setRange={setRange} closePopper={closePopper} handleChange={handleChange} />}
        {showMonthFilter && <MonthFilter setRange={setRange} closePopper={closePopper} handleChange={handleChange} />}
      </div>
    </div>
  );
};

export { CustomCaption };
