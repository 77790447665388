import { FC, memo } from 'react';
import styles from './BurgerMenu.module.scss';
import classNames from 'classnames';
import { Button } from '../Button/Button';

interface BurgerMenuProps {
  className?: string;
  active?: boolean;
  onClick?: () => void;
  showCloseIcon?: boolean;
}

const BurgerMenu: FC<BurgerMenuProps> = memo(({ className, active, onClick, showCloseIcon }) => {
  const mods = {
    [styles.active]: active && showCloseIcon
  };

  return (
    <Button type="button" className={classNames(styles.burger, mods, className)} size="sizeL" onClick={onClick}>
      <span></span>
    </Button>
  );
});

export { BurgerMenu };
