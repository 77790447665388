import React, { FC, memo } from 'react';
import styles from './Logo.module.scss';
import classNames from 'classnames';
import logo from 'app/assets/images/logo.png';
import collapsedLogo from 'app/assets/images/logo-collapsed.png';

export type LogoSize = 'sizeM' | 'sizeL' | 'max';

interface LogoProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  size?: LogoSize;
  collapsed?: boolean;
}

const Logo: FC<LogoProps> = memo(({ className, size = 'sizeM', collapsed, ...otherProps }) => {
  const mods = {
    [styles[size]]: size,
    [styles.collapsed]: collapsed
  };

  return (
    <img
      className={classNames(styles.logo, mods, [className])}
      alt="Logo"
      src={collapsed ? collapsedLogo : logo}
      {...otherProps}
    />
  );
});

export { Logo };
