import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError, ResponseError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import {
  getPaymentGateTransactionPaymentMethodFilter,
  getPaymentGatewayTransactionsCurrency,
  getPaymentGatewayTransactionsDateFilter,
  getPaymentGatewayTransactionsNumberOfPage,
  getPaymentGatewayTransactionsStatus
} from '../../selectors/paymentGatewayTransactions/payment-gateway';
import {
  getPaymentGateBalances,
  GetPaymentGatewayTransactionResponse,
  PaymentGatewayTransaction
} from 'entities/PaymentGateway';

export interface GetCryptoTransactionsProps {
  page?: number;
  replace?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const getPaymentGatewayTransactions = createAsyncThunk<
  PaymentGatewayTransaction[],
  GetCryptoTransactionsProps,
  ThunkConfig<MyAxiosError<ResponseError>>
>('fiatGateway/getPaymentGatewayTransactions', async (_, { rejectWithValue, extra, dispatch, getState }) => {
  const currency = getPaymentGatewayTransactionsCurrency(getState());
  const status = getPaymentGatewayTransactionsStatus(getState());
  const paymentMethod = getPaymentGateTransactionPaymentMethodFilter(getState());
  const page = getPaymentGatewayTransactionsNumberOfPage(getState());
  const date = getPaymentGatewayTransactionsDateFilter(getState());

  const params = new URLSearchParams({ per_page: '20', page: page.toString() });

  if (status !== '') {
    params.append('status', status);
  }

  if (currency !== '') {
    params.append('currency', currency);
  }

  if (paymentMethod !== '') {
    params.append('payment_method', paymentMethod);
  }

  let dateFrom = '';
  let dateTo = '';

  if (date?.dateFrom && date.dateTo) {
    const { adjustTillDate } = await import('shared/lib/timeFunctions/dateFilter/ISOformat/adjustTillDate');
    const { since, till } = adjustTillDate(new Date(date.dateFrom), new Date(date.dateTo));

    dateFrom = since;
    dateTo = till;
    params.append('since', since);
    params.append('until', till);
  }

  try {
    const { addQueryParams } = await import('shared/lib/requests/params/addQueryParams/addQueryParams');
    addQueryParams({ status, currency, since: dateFrom, until: dateTo, page: page.toString() });

    const response = await extra.apiWithAuth.get<GetPaymentGatewayTransactionResponse>('/fiat/v1/transactions', {
      params,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    });

    if (!response.data) {
      throw new Error();
    }
    await dispatch(getPaymentGateBalances());
    return response.data.transactions;
  } catch (e) {
    const error = e as MyAxiosError<ResponseError>;
    return rejectWithValue(error);
  }
});
