import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError, ResponseError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { getLinkHeader } from 'shared/lib/pagination/requests/getLinkHeader';
import {
  getExchangeDateFilter,
  getExchangePaginationPages,
  getExchangeStatus
} from '../selectors/exchangeTransactions/exchange';
import { exchangeActions } from '../slices/exchange';
import { ExchangeTransaction, GetExchangeTransactionResponse } from 'entities/Transaction';

export interface GetCryptoWithdrawalProps {
  replace?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const getExchangeTransactions = createAsyncThunk<ExchangeTransaction[],
GetCryptoWithdrawalProps,
ThunkConfig<MyAxiosError<ResponseError>>>('exchangeTransactions/getExchangeTransactions', async (props, {
  rejectWithValue,
  dispatch,
  extra,
  getState
}) => {
  const status = getExchangeStatus(getState());
  const date = getExchangeDateFilter(getState());
  const page = getExchangePaginationPages(getState())?.currentPage;

  const params = new URLSearchParams();

  if (page) {
    params.append('page', page);
  }

  if (status) {
    params.append('status', status);
  }

  let dateFrom = '';
  let dateTo = '';

  if (date?.dateFrom && date.dateTo) {
    const { adjustTillDate } = await import('shared/lib/timeFunctions/dateFilter/ISOformat/adjustTillDate');
    const { since, till } = adjustTillDate(new Date(date.dateFrom), new Date(date.dateTo));

    dateFrom = since;
    dateTo = till;
    params.append('since', since);
    params.append('until', till);
  }

  try {
    const { addQueryParams } = await import('shared/lib/requests/params/addQueryParams/addQueryParams');
    addQueryParams({ status, since: dateFrom, until: dateTo }, props.replace);

    const response = await extra.apiWithAuth.get<GetExchangeTransactionResponse>(
      '/v1/transactions/outgoing?type=transaction_type_exchange',
      {
        params,
        headers: {
          Accept: 'application/json'
        }
      }
    );

    const linkHeader = response.headers.link as string;

    const pages = getLinkHeader(linkHeader);

    dispatch(exchangeActions.setPaginationPages(pages));

    if (!response.data) {
      throw new Error();
    }

    return response.data.transactions;
  } catch (e) {
    const error = e as MyAxiosError<ResponseError>;
    return rejectWithValue(error);
  }
});
