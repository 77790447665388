import { createSlice } from '@reduxjs/toolkit';
import { WithdrawalEstimateSchema } from '../types/withdrawal-estimate';
import { getWithdrawalEstimate } from '../services/getWithdrawalEstimate';

const initialState: WithdrawalEstimateSchema = {
  isLoading: false,
  isSuccess: false
};

export const withdrawalEstimate = createSlice({
  name: 'withdrawalEstimate',
  initialState,
  reducers: {
    setResetData: (state) => {
      state.estimate = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWithdrawalEstimate.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(getWithdrawalEstimate.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.isSuccess = true;
        state.estimate = action.payload;
      })
      .addCase(getWithdrawalEstimate.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      });
  }
});

export const { actions: withdrawalEstimateActions } = withdrawalEstimate;
export const { reducer: withdrawalEstimateReducer } = withdrawalEstimate;
