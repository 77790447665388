import { createAsyncThunk } from '@reduxjs/toolkit';
import { BalanceResponse } from '../types/balance';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { MyAxiosError } from 'shared/types/axios';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const getBalance = createAsyncThunk<BalanceResponse, undefined, ThunkConfig<MyAxiosError<Error>>>(
  'balance/getBalance',
  async (_, { rejectWithValue, extra }) => {
    try {
      const response = await extra.apiWithAuth.get<BalanceResponse>('/v1/wallets/balance', {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.data) {
        throw new Error();
      }

      return response.data;
    } catch (e) {
      const error = e as MyAxiosError<Error>;
      error.message = 'The waiting time for the request to receive your balance has been exceeded';
      return rejectWithValue(error);
    }
  }
);
