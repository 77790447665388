import { ReactComponent as BtcIcon } from 'app/assets/icons/crypto/btc.svg';
import { ReactComponent as BchIcon } from 'app/assets/icons/crypto/bch.svg';
import { ReactComponent as BtgIcon } from 'app/assets/icons/crypto/btg.svg';
import { ReactComponent as DashIcon } from 'app/assets/icons/crypto/dash.svg';
import { ReactComponent as EthIcon } from 'app/assets/icons/crypto/eth.svg';
import { ReactComponent as LtsIcon } from 'app/assets/icons/crypto/ltc.svg';
import { ReactComponent as QtumIcon } from 'app/assets/icons/crypto/qtum.svg';
import { ReactComponent as UsdtIcon } from 'app/assets/icons/crypto/usdt.svg';
import { ReactComponent as UsdcIcon } from 'app/assets/icons/crypto/usdc.svg';
import { ReactComponent as ZecIcon } from 'app/assets/icons/crypto/zec.svg';
import { ReactComponent as TronIcon } from 'app/assets/icons/crypto/tron.svg';
import { ReactComponent as TronUsdtIcon } from 'app/assets/icons/crypto/tron-usdt.svg';
// import { ReactComponent as BscIcon } from 'app/assets/icons/crypto/bsc.svg';
import { ReactComponent as BusdIcon } from 'app/assets/icons/crypto/busd.svg';
import { ReactComponent as BnbIcon } from 'app/assets/icons/crypto/bnb.svg';
import { ReactComponent as BscUsdIcon } from 'app/assets/icons/crypto/bscusd.svg';

import { ReactComponent as BtcIconSq } from 'app/assets/icons/paymentlink/BTC.svg';
import { ReactComponent as BchIconSq } from 'app/assets/icons/paymentlink/BCH.svg';
import { ReactComponent as BtgIconSq } from 'app/assets/icons/paymentlink/BTG.svg';
import { ReactComponent as DashIconSq } from 'app/assets/icons/paymentlink/DASH.svg';
import { ReactComponent as EthIconSq } from 'app/assets/icons/paymentlink/ETH.svg';
import { ReactComponent as LtsIconSq } from 'app/assets/icons/paymentlink/LTC.svg';
import { ReactComponent as QtumIconSq } from 'app/assets/icons/paymentlink/QTUM.svg';
import { ReactComponent as UsdtIconSq } from 'app/assets/icons/paymentlink/USDT.svg';
import { ReactComponent as UsdcIconSq } from 'app/assets/icons/paymentlink/USDC.svg';
import { ReactComponent as ZecIconSq } from 'app/assets/icons/paymentlink/ZEC.svg';
import { ReactComponent as TronIconSq } from 'app/assets/icons/paymentlink/TRX.svg';
import { ReactComponent as TronUsdtIconSq } from 'app/assets/icons/paymentlink/USDT TRC.svg';
// import { ReactComponent as BscIconSq } from 'app/assets/icons/crypto/bsc.svg';
import { ReactComponent as BusdIconSq } from 'app/assets/icons/paymentlink/BUSD.svg';
import { ReactComponent as BnbIconSq } from 'app/assets/icons/paymentlink/BNB.svg';
import { ReactComponent as BscUsdIconSq } from 'app/assets/icons/paymentlink/BSCUSDsq.svg';

import { CRYPTO_SHORT_NAMES } from './crypto-names';

export interface CryptoIcons {
  [key: string]: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const CRYPTO_ICONS: CryptoIcons = {
  [CRYPTO_SHORT_NAMES.BTC]: BtcIcon,
  [CRYPTO_SHORT_NAMES.ETH]: EthIcon,
  [CRYPTO_SHORT_NAMES.USDT]: UsdtIcon,
  [CRYPTO_SHORT_NAMES.USDT_TRC20]: TronUsdtIcon,
  [CRYPTO_SHORT_NAMES.USDC]: UsdcIcon,
  [CRYPTO_SHORT_NAMES.LTC]: LtsIcon,
  [CRYPTO_SHORT_NAMES.TRX]: TronIcon,
  [CRYPTO_SHORT_NAMES.BCH]: BchIcon,
  [CRYPTO_SHORT_NAMES.ZEC]: ZecIcon,
  [CRYPTO_SHORT_NAMES.QTUM]: QtumIcon,
  [CRYPTO_SHORT_NAMES.BTG]: BtgIcon,
  [CRYPTO_SHORT_NAMES.DASH]: DashIcon,
  // [CRYPTO_SHORT_NAMES.BSC]: BscIcon,
  [CRYPTO_SHORT_NAMES.BSCUSD]: BscUsdIcon,
  [CRYPTO_SHORT_NAMES.BUSD]: BusdIcon,
  [CRYPTO_SHORT_NAMES.BNB]: BnbIcon
};

export const CRYPTO_ICONS_SQ: CryptoIcons = {
  [CRYPTO_SHORT_NAMES.BTC]: BtcIconSq,
  [CRYPTO_SHORT_NAMES.ETH]: EthIconSq,
  [CRYPTO_SHORT_NAMES.USDT]: UsdtIconSq,
  [CRYPTO_SHORT_NAMES.USDT_TRC20]: TronUsdtIconSq,
  [CRYPTO_SHORT_NAMES.USDC]: UsdcIconSq,
  [CRYPTO_SHORT_NAMES.LTC]: LtsIconSq,
  [CRYPTO_SHORT_NAMES.TRX]: TronIconSq,
  [CRYPTO_SHORT_NAMES.BCH]: BchIconSq,
  [CRYPTO_SHORT_NAMES.ZEC]: ZecIconSq,
  [CRYPTO_SHORT_NAMES.QTUM]: QtumIconSq,
  [CRYPTO_SHORT_NAMES.BTG]: BtgIconSq,
  [CRYPTO_SHORT_NAMES.DASH]: DashIconSq,
  // [CRYPTO_SHORT_NAMES.BSC]: BscIconSq,
  [CRYPTO_SHORT_NAMES.BSCUSD]: BscUsdIconSq,
  [CRYPTO_SHORT_NAMES.BUSD]: BusdIconSq,
  [CRYPTO_SHORT_NAMES.BNB]: BnbIconSq
};
