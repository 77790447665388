import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData, UserSchema } from '../types/user';
import { LOCAL_STORAGE_USER_DATA } from 'shared/constants/localstorage/localstorage';
import { logout } from '../services/logOut';
import { authorization } from '../services/authorization';

const initialState: UserSchema = {
  isAuthorized: false
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.userData = action.payload;
      localStorage.setItem(LOCAL_STORAGE_USER_DATA, JSON.stringify(action.payload));
    },
    initUser: (state) => {
      const userData = localStorage.getItem(LOCAL_STORAGE_USER_DATA);
      if (userData) {
        state.userData = JSON.parse(userData) as UserData;
        state.isAuthorized = true;
      } else {
        state.isAuthorized = false;
      }
    },
    setUserIsAuthorized: (state, action: PayloadAction<boolean>) => {
      state.isAuthorized = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(authorization.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isAuthorized = false;
      })
      .addCase(authorization.fulfilled, (state) => {
        state.error = undefined;
        state.isLoading = false;
        state.isAuthorized = true;
      })
      .addCase(authorization.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isAuthorized = false;
      })

      .addCase(logout.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isAuthorized = false;
      })
      .addCase(logout.fulfilled, (state) => {
        state.error = undefined;
        localStorage.removeItem(LOCAL_STORAGE_USER_DATA);
        state.userData = undefined;
        state.isLoading = false;
        state.isAuthorized = true;
      })
      .addCase(logout.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isAuthorized = false;
      });
  }
});

export const { actions: userActions } = userSlice;
export const { reducer: userReducer } = userSlice;
