import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError, ResponseError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { AlertType, alertActions } from 'entities/Alert';
import {
  calculateMinFiatAmountPayload,
  CreateExchangeRateEstimateResponse
} from 'features/ExchangeRate/model/types/exchangerate-estimate';
import { getCryptoMinValues } from 'shared/lib/preferences/getCryptoMinValues/getCryptoMinValues';
import { CRYPTO_VALUES_TO_DIVIDE_WITHDRAWAL } from 'shared/constants/crypto/crypto-names';
import BigNumber from 'bignumber.js';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const calculateMinFiatAmount = createAsyncThunk<calculateMinFiatAmountPayload,
undefined,
ThunkConfig<MyAxiosError<ResponseError>>>('exchangeRate/calculateMinFiatAmount', async (_, {
  rejectWithValue,
  dispatch,
  extra,
  getState
}) => {
  try {
    const merchantPreferences = getCryptoMinValues(getState().merchantPreferences?.merchantPreferences);
    const code = getState().newInvoice?.dataToCreateNewInvoice?.code;
    if (merchantPreferences && code) {
      const mult1 = new BigNumber(merchantPreferences[code]);
      const mult2 = new BigNumber(CRYPTO_VALUES_TO_DIVIDE_WITHDRAWAL[code?.toUpperCase()]);
      const multAmount = mult1.multipliedBy(mult2);
      const data = {
        code,
        amount: multAmount.toString(),
        direction: 'FORWARD',
        currency: 'usd'
      };

      const response = await extra.apiWithAuth.post<CreateExchangeRateEstimateResponse>('/v1/exchangerate/estimate',
        JSON.stringify(data), {
          headers: {
            'Content-Type': 'application/json'
          }
        });

      if (!response.data) {
        throw new Error();
      }

      return response.data;
    } else {
      return { amount: undefined };
    }
  } catch (e) {
    const error = e as MyAxiosError<ResponseError>;

    const errorMessage = error.response?.data.message || 'Server error';

    dispatch(
      alertActions.setAlert({
        id: crypto.randomUUID(),
        message: errorMessage,
        timeout: 5000,
        type: AlertType.ERROR
      })
    );
    return rejectWithValue(e as MyAxiosError<ResponseError>);
  }
});
