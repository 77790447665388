import { FC } from 'react';
import styles from './AppLink.module.scss';
import classNames from 'classnames';
import { Link, LinkProps, NavLink, useLocation } from 'react-router-dom';

export type TextSize = 'sizeXS' | 'sizeS' | 'size18px' | 'size20px' | 'sizeM' | 'sizeL' | 'sizeXL';
export type TextWeight = '300' | '400' | '500' | '600' | '700';
export type AppLinkTheme =
  | 'clear'
  | 'primary'
  | 'secondary'
  | 'primaryBg'
  | 'third'
  | 'fourth'
  | 'sidebarTheme'
  | 'tabStylePrimary'
  | 'tabStylePrimaryActive';
export type TextTransform = 'uppercase';
export type TextDecoration = 'none' | 'underline';

const textTransformClasses: Record<TextTransform, string> = {
  uppercase: styles.uppercase
};

const textDecorationClasses: Record<TextDecoration, string> = {
  none: styles.none,
  underline: styles.underline
};

const themeClasses: Record<AppLinkTheme, string> = {
  clear: styles.clear,
  primary: styles.primary,
  primaryBg: styles.primaryBg,
  secondary: styles.secondary,
  third: styles.third,
  fourth: styles.fourth,
  sidebarTheme: styles.sidebarTheme,
  tabStylePrimary: styles.tabStylePrimary,
  tabStylePrimaryActive: styles.tabStylePrimaryActive
};

const activeThemeClasses: Record<AppLinkTheme, string> = {
  clear: '',
  primary: '',
  primaryBg: '',
  secondary: '',
  third: '',
  fourth: '',
  sidebarTheme: styles.sidebarThemeActive,
  tabStylePrimary: styles.tabStylePrimaryActive,
  tabStylePrimaryActive: ''
};

const weightClasses: Record<TextWeight, string> = {
  300: styles.weight300,
  400: styles.weight400,
  500: styles.weight500,
  600: styles.weight600,
  700: styles.weight700
};

const sizeClasses: Record<TextSize, string> = {
  sizeXS: styles.sizeXS,
  sizeS: styles.sizeS,
  sizeM: styles.sizeM,
  size18px: styles.size18px,
  size20px: styles.size20px,
  sizeL: styles.sizeL,
  sizeXL: styles.sizeXL
};

interface AppLinkProps extends LinkProps {
  className?: string;
  theme?: AppLinkTheme;
  size?: TextSize;
  weight?: TextWeight;
  active?: boolean;
  textTransform?: TextTransform;
  isNavLink?: boolean;
  textDecoration?: TextDecoration;
}

const AppLink: FC<AppLinkProps> = (props) => {
  const {
    className,
    children,
    to,
    theme,
    size,
    weight,
    active,
    textTransform,
    isNavLink = false,
    textDecoration,
    onClick,
    ...otherProps
  } = props;

  const classes = [
    className,
    theme && themeClasses[theme],
    size && sizeClasses[size],
    weight && weightClasses[weight],
    active && theme && activeThemeClasses[theme],
    textTransform && textTransformClasses[textTransform],
    textDecoration && textDecorationClasses[textDecoration]
  ];

  const location = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const isActiveLink = location.pathname + location.search + location.hash === to;

    if (isActiveLink) {
      event.preventDefault();
    }
    onClick?.(event);
  };

  return isNavLink ? (
    <NavLink
      to={to}
      className={({ isActive, isPending }) => {
        return classNames(
          styles.appLink,
          { [activeThemeClasses[theme || 'clear']]: isActive, [styles.activeRoute]: isActive },
          classes
        );
      }}
      onClick={handleClick}
      {...otherProps}
    >
      {children}
    </NavLink>
  ) : (
    <Link to={to} className={classNames(styles.appLink, {}, classes)} onClick={handleClick} {...otherProps}>
      {children}
    </Link>
  );
};

export { AppLink };
