import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const getExchangeState = (state: StateSchema) => state?.exchangeTransactions;

export const getExchangeIsLoading = createSelector(getExchangeState, (state) => state?.isLoading ?? false);

export const getExchangeIsSuccess = createSelector(getExchangeState, (state) => state?.isSuccess ?? false);

export const getExchangeStatus = createSelector(getExchangeState, (state) => state?.status ?? '');

export const getExchangeNumberOfPage = createSelector(getExchangeState, (state) => state?.page ?? 1);

export const getExchangeHasMore = createSelector(getExchangeState, (state) => state?.hasMore ?? false);

export const getExchangeError = createSelector(getExchangeState, (state) => state?.error);

export const getExchangePaginationPages = createSelector(getExchangeState, (state) => state?.paginationPages ?? {});

export const getExchangeDateFilter = createSelector(getExchangeState, (state) => state?.date ?? null);

export const getIsShowExchangeFiltersResetBtn = createSelector(getExchangeState, (state) => state?.isShowResetBtn);
