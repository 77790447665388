import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ExchangeRateEstimateData, ResponseError } from '../types/exchangerate-estimate';
import { MyAxiosError } from 'shared/types/axios';
import { CreateExchangeRateEstimateSchema } from 'features/ExchangeRate/model/types/exchangerate-estimate';
import { createExchangeRateEstimate } from 'features/ExchangeRate/model/services/createExchangeRateEstimate';
import { calculateMinFiatAmount } from 'features/ExchangeRate/model/services/calculateMinFiatAmount';

const initialState: CreateExchangeRateEstimateSchema = {
  error: undefined,
  isLoading: false,
  createExchangeRateEstimate: undefined,
  exchangeRateEstimateData: undefined,
  isSuccess: false,
  minFiatAmount: undefined
};

export const exchangeRateSlice = createSlice({
  name: 'exchangeRate',
  initialState,
  reducers: {
    setError: (state, action: PayloadAction<MyAxiosError<ResponseError> | undefined>) => {
      state.error = action.payload;
    },
    setNewExchangeData: (state, action: PayloadAction<ExchangeRateEstimateData>) => {
      state.exchangeRateEstimateData = { ...state.exchangeRateEstimateData, ...action.payload };
    },
    setMinFiatAmount: (state, action: PayloadAction<string | undefined>) => {
      state.minFiatAmount = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createExchangeRateEstimate.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(createExchangeRateEstimate.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.isSuccess = true;
        state.createExchangeRateEstimate = action.payload;
      })
      .addCase(createExchangeRateEstimate.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      });

    builder
      .addCase(calculateMinFiatAmount.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(calculateMinFiatAmount.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.isSuccess = true;
        state.minFiatAmount = action.payload.amount;
      })
      .addCase(calculateMinFiatAmount.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      });
  }
});

export const { actions: exchangeRateActions } = exchangeRateSlice;
export const { reducer: exchangeRateReducer } = exchangeRateSlice;
