import { ResendCallbackResponse } from 'entities/PaymentGateway';
import { $apiWithAuth } from 'shared/api/axiosWithAuth';

export const resendCallback = async (id: number) => {
  const response = await $apiWithAuth.post<ResendCallbackResponse>(
    '/fiat/v1/callback/resend',
    JSON.stringify({ transaction_id: id }),
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  );

  if (!response.data) {
    throw new Error();
  }

  return response.data.success;
};
