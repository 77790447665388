import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { ResponseError } from '../types/transaction-details';
import { CryptoTransaction } from 'pages/CryptoTransactionsPage/model/types/crypto-transactions';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const getTransactionById = createAsyncThunk<CryptoTransaction, string, ThunkConfig<MyAxiosError<ResponseError>>>(
  'transactionDetails/getTransactionById',
  async (id, { rejectWithValue, dispatch, extra }) => {
    try {
      const response = await extra.apiWithAuth.get<CryptoTransaction>(`/v1/transaction/incoming/${id}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.data) {
        throw new Error();
      }

      return response.data;
    } catch (e) {
      const error = e as MyAxiosError<ResponseError>;
      error.message = 'The waiting time for a request to receive transaction data has been exceeded';
      return rejectWithValue(error);
    }
  }
);
