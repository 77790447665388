/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ReactNode, useState, useEffect, useCallback } from 'react';
import initializeGlobalWeb3 from '../lib/initializeGlobalWeb3/initializeGlobalWeb3';
import Web3Context from '../lib/context/web3Context';

const Web3ContextProvider = (props: { children: ReactNode }): JSX.Element => {
  const [walletType, setWalletType] = useState<'metamask' | 'tronLink'>('metamask');
  const [isAccountConnecting, setIsAccountConnecting] = useState(false);
  const [connectedAccount, setConnectedAccount] = useState<string | undefined>();
  const [trxAddresses, setTrxAddresses] = useState<string[]>([]);
  const [ethAddresses, setEthAddresses] = useState<string[]>([]);

  const [isAccountChanged, setIsAccountChanged] = useState(false);
  const [isAccountConnected, setIsAccountConnected] = useState(false);

  const requestAndSetAccount = useCallback(async () => {
    const accounts = (await window.ethereum.request({ method: 'eth_requestAccounts' })) as string[];
    if (accounts.length > 0) {
      const connectedAccount = accounts[0];
      setConnectedAccount(connectedAccount);
      setEthAddresses(accounts);
      console.log('Connected account (METAMASK):', connectedAccount);
    }
  }, []);

  const subscribeToAccountChanges = useCallback(async () => {
    setIsAccountConnected(false);
    if (walletType === 'metamask') {
      await window.ethereum.on('accountsChanged', function (accounts: string[]) {
        if (accounts.length > 0) {
          const connectedAccount = accounts[0];
          setIsAccountConnected(true);
          setIsAccountChanged(true);
          setConnectedAccount(connectedAccount);
          setEthAddresses(accounts);
          setTrxAddresses([]);
          console.log(`Connected account changed to ${connectedAccount}`);
        }
      });
    } else if (walletType === 'tronLink') {
      window.tron.on('accountsChanged', (accounts: string[]) => {
        if (window.tron.tronWeb) {
          if (accounts.length !== 0) {
            const connectedAccount = accounts[0];
            setIsAccountChanged(true);
            setConnectedAccount(connectedAccount);
            setTrxAddresses(accounts);
            setEthAddresses([]);
            setIsAccountConnected(true);
          }
        } else {
          setIsAccountConnected(false);
          setTrxAddresses([]);
          setEthAddresses([]);
        }
      });
    }
  }, [walletType]);

  const subscribeToChainChanges = useCallback(async () => {
    if (walletType === 'metamask') {
      await window.ethereum.on('chainChanged', function (networkId: string) {
        console.log(`Chain changed to ${networkId}`);
        window.location.reload();
      });
    } else if (walletType === 'tronLink') {
      window.addEventListener('message', (e) => {
        if (e.data.message && e.data.message.action === 'setNode') {
          console.log('setNode event', e.data.message);
          if (e.data.message.data.node.chain === '_') {
            console.log('tronLink currently selects the main chain');
          } else {
            console.log('tronLink currently selects the side chain');
          }
        }
      });
    }
  }, [walletType]);

  const tryToGetCurrentAccount = useCallback(async () => {
    if (!connectedAccount) {
      if (walletType === 'metamask') {
        const accountPromise = new Promise<string[]>((resolve, reject) => {
          window.web3.eth.getAccounts((err: Error, accounts: string[]) => {
            if (err) {
              reject(new Error('Error retrieving current account'));
            }
            if (accounts.length > 0) {
              resolve(accounts);
            } else {
              reject(new Error('Current accounts not found'));
            }
          });
        });

        try {
          const accounts = await accountPromise;
          const connectedAccount = accounts[0];
          setConnectedAccount(connectedAccount);
          setEthAddresses(accounts);
          setTrxAddresses([]);
          setIsAccountConnected(true);
        } catch (err: any) {
          console.log(err);
          setIsAccountConnected(false);
        }
      } else if (walletType === 'tronLink') {
        try {
          const tronWeb = window.tron.tronWeb;
          setIsAccountConnected(true);
          setConnectedAccount(tronWeb.defaultAddress.base58);
          setTrxAddresses([tronWeb.defaultAddress.base58]);
          setEthAddresses([]);
        } catch {
          setIsAccountConnected(false);
        }
      }
    }
  }, [connectedAccount, walletType]);

  const init = useCallback(async () => {
    try {
      initializeGlobalWeb3();

      if (!window.ethereum && !window.tron) return;

      await subscribeToAccountChanges();
      await subscribeToChainChanges();
      await tryToGetCurrentAccount();
    } finally {
      setIsAccountChanged(false);
    }
  }, [subscribeToAccountChanges, tryToGetCurrentAccount, subscribeToChainChanges]);

  useEffect(() => {
    init();
  }, [init]);

  const connectMetamask = useCallback(async () => {
    try {
      setIsAccountConnecting(true);
      await requestAndSetAccount();
      setWalletType('metamask');
      setTrxAddresses([]);
      setIsAccountConnected(true);
    } catch (err) {
      setIsAccountConnecting(false);
      throw new Error(err as string);
    } finally {
      setIsAccountConnecting(false);
    }
  }, [requestAndSetAccount]);

  const connectTronLink = useCallback(async () => {
    setIsAccountConnecting(true);
    try {
      const res = await window.tron.request({ method: 'eth_requestAccounts' });
      if (res) {
        setWalletType('tronLink');
        const connectedAccount = res[0];
        setConnectedAccount(connectedAccount);
        setTrxAddresses(res);
        setEthAddresses([]);
        setIsAccountConnected(true);
      }
    } catch (err) {
      console.log(err);
      setIsAccountConnected(false);
    } finally {
      setIsAccountConnecting(false);
    }
  }, []);

  const value = {
    connectedAccount,
    isAccountConnecting,
    isAccountChanged,
    isMetamaskInstalled: !!window.ethereum && window.ethereum.isMetaMask,
    isTronLinkInstalled: !!window.tron && window.tron.isTronLink,
    connectMetamask,
    connectTronLink,
    walletType,
    isAccountConnected,
    trxAddresses,
    ethAddresses
  };

  return <Web3Context.Provider value={value} {...props} />;
};

export { Web3ContextProvider };
