import { memo, Suspense, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { AppRoutesProps, routeConfig } from 'shared/config/routeConfig/routeConfig';
import { AppLayout } from 'shared/ui/AppLayout/AppLayout';
import { Layout } from 'shared/ui/Layout/Layout';
import { Loader } from 'widgets/Loader/ui/Loader';
import { checkUserRole, getUserData } from 'entities/User';

const AppRouter = memo(() => {
  const userData = useSelector(getUserData);

  const renderWithWrapper = useCallback(
    (route: AppRoutesProps) => {
      const { path, element, withLayout, nestedRoutes, title, roleRequested } = route;

      let isUserHasAllRoles = true;

      if (roleRequested) {
        for (let i = 0; i < roleRequested?.length; i++) {
          const role = roleRequested[i];
          const isUserHaveRole = checkUserRole(role, userData);
          if (isUserHaveRole) {
            continue;
          } else {
            isUserHasAllRoles = false;
            break;
          }
        }
      }

      const content = isUserHasAllRoles ? element : <Navigate replace to="/no-access" />;

      return (
        <Route
          key={path}
          path={path}
          element={withLayout ? <AppLayout title={title}>{content}</AppLayout> : <Layout>{content}</Layout>}
        >
          {nestedRoutes?.map(({ path, element }) => {
            return <Route path={path} key={path} element={element} />;
          })}
        </Route>
      );
    },
    [userData]
  );

  return (
    <Suspense fallback={<Loader />}>
      <Routes>{Object.values(routeConfig).map(renderWithWrapper)}</Routes>
    </Suspense>
  );
});

export { AppRouter };
