export const LOCAL_STORAGE_ACCESS_TOKEN = 'accessToken';
export const LOCAL_STORAGE_REFRESH_TOKEN = 'refreshToken';

export const LOCAL_STORAGE_USER_DATA = 'USER_DATA';

export const LOCAL_STORAGE_SIDEBAR = 'sidebar';

export const LOCAL_STORAGE_SETTINGS_TAB_VALUE = 'settings_tab_value';

export const LOCAL_STORAGE_IS_REDIRECTED_TO_KEYCLOAK = 'is_redirected';

export const LOCAL_STORAGE_KEYCLOAK_REDIRECTED_QUERY = 'redirected_query';

export const LOCAL_STORAGE_IS_REDIRECTED_TO_API_KEY = 'api_key_link';

export const LOCAL_STORAGE_ONBOARDING_DATA = 'onboarding_data_v1';

export const LOCAL_STORAGE_IS_ONBOARDING_ACTIVE = 'is_onboarding_active';
