import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const getTransactionsFilterState = (state: StateSchema) => state?.cryptoTransactionsPageFilter;

export const getTransactionsFilterStatus = createSelector(getTransactionsFilterState, (state) => state?.status ?? '');

export const getTransactionsFilterCurrency = createSelector(
  getTransactionsFilterState,
  (state) => state?.currency ?? ''
);

export const getTransactionsDateFilter = createSelector(getTransactionsFilterState, (state) => state?.date);
export const getIsShowTransactionsFiltersResetBtn = createSelector(
  getTransactionsFilterState,
  (state) => state?.isShowResetBtn
);

export const getTransactionsIdSearch = createSelector(
  getTransactionsFilterState,
  (state) => state?.transactionIdSearch ?? ''
);
