import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { CreateNewPaymentLinkResponse, DataToCreateNewPaymentLink, ResponseError } from '../types/new-payment-link';
import { alertActions, AlertType } from 'entities/Alert';
import { NEW_PAYMENT_LINK_FORM_ERROR } from 'features/NewPaymentLinkForm/lib/contants/errors/new-payment-link-errors';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const createNewPaymentLink = createAsyncThunk<
  CreateNewPaymentLinkResponse,
  DataToCreateNewPaymentLink,
  ThunkConfig<ResponseError | undefined>
>('newPayment/createNewPayment', async (data, { rejectWithValue, extra, dispatch }) => {
  try {
    const response = await extra.apiWithAuth.post<CreateNewPaymentLinkResponse>(
      '/v1/payment-link',
      JSON.stringify(data),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (!response.data) {
      throw new Error();
    }
    return response.data;
  } catch (e) {
    const error = e as MyAxiosError<ResponseError>;

    if (error.response) {
      const errorMessage = NEW_PAYMENT_LINK_FORM_ERROR[error.response.data.code];

      dispatch(
        alertActions.setAlert({
          id: crypto.randomUUID(),
          message: errorMessage,
          timeout: 5000,
          type: AlertType.ERROR
        })
      );
      return rejectWithValue(error.response.data);
    }

    return rejectWithValue(undefined);
  }
});
