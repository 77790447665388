import { TRANSACTIONS_STATUSES } from 'shared/constants/crypto/transactions-status';
import { Option } from 'shared/ui/CustomSelect/CustomSelect';

export const exchangeTransactionsStatuses: Option[] = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Waiting',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXCHANGE_WAITING
  },
  {
    label: 'Exchanging',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXCHANGING
  },
  {
    label: 'Sending',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXCHANGE_SENDING
  },
  {
    label: 'Failed',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXCHANGE_FAILED
  },
  {
    label: 'Confirmed',
    value: TRANSACTIONS_STATUSES.TRANSACTION_STATUS_CONFIRMED
  }
];

export const paymentLinkStatuses: Option[] = [
  {
    label: 'All',
    value: ''
  },
  {
    label: 'Pending',
    value: 'Pending'
  },
  {
    label: 'Expired',
    value: 'Expired'
  },
  {
    label: 'Paid',
    value: 'Paid'
  }
];
