import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  DataToCreateNewPaymentGatewayWithdrawal,
  NewPaymentGatewayWithdrawalSchema
} from '../types/paymentWithdrawal/paymentWithdrawal';
import { createNewPaymentGatewayWithdrawal } from '../services/createNewPaymentGatewayWithdrawal';

const initialState: NewPaymentGatewayWithdrawalSchema = {
  error: undefined,
  isLoading: false,
  isSuccess: false,
  dataToCreateNewWithdrawal: undefined,
  newWithdrawal: undefined
};

export const newPaymentGatewayWithdrawalSlice = createSlice({
  name: 'newPaymentGatewayWithdrawal',
  initialState,
  reducers: {
    setDataToCreateNewTransaction: (state, action: PayloadAction<DataToCreateNewPaymentGatewayWithdrawal>) => {
      state.dataToCreateNewWithdrawal = {
        ...state.dataToCreateNewWithdrawal,
        ...action.payload
      };
    },
    setResetData: (state) => {
      state.dataToCreateNewWithdrawal = undefined;
      state.isSuccess = false;
      state.newWithdrawal = undefined;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(createNewPaymentGatewayWithdrawal.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(createNewPaymentGatewayWithdrawal.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.isSuccess = true;
        state.newWithdrawal = action.payload;
      })
      .addCase(createNewPaymentGatewayWithdrawal.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      });
  }
});

export const { actions: newPaymentGatewayWithdrawalActions } = newPaymentGatewayWithdrawalSlice;
export const { reducer: newPaymentGatewayWithdrawalReducer } = newPaymentGatewayWithdrawalSlice;
