import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const getUserState = (state: StateSchema) => state.user;

export const getIsUserAuthorized = createSelector(getUserState, (state) => state?.isAuthorized ?? false);

export const getUserData = createSelector(getUserState, (state) => state?.userData);

export const getIsUserLoading = createSelector(getUserState, (state) => state.isLoading ?? false);
