import styles from './CustomDropdown.module.scss';
import { months } from '../../lib/constants/time/months';
import { years } from '../../lib/constants/time/years';
import { FC, memo, useEffect, useState } from 'react';
import { useNavigation } from 'react-day-picker';
import { ListBox } from 'shared/ui/ListBox/ListBox';

interface CustomDropdownProps {
  showMonthSelect?: boolean;
  showYearSelect?: boolean;
}

const CustomDropdown: FC<CustomDropdownProps> = memo((props) => {
  const { showMonthSelect = true, showYearSelect = true } = props;
  const [monthValue, setMonthValue] = useState('');
  const [yearValue, setYearValue] = useState('');

  const { currentMonth, goToDate } = useNavigation();

  useEffect(() => {
    setMonthValue(months[currentMonth.getMonth()].value);
    setYearValue(currentMonth.getFullYear().toString());
  }, [currentMonth]);

  const handleChangeMonth = (value: string | number) => {
    const monthIndex = value as number;
    const newDate = new Date(Number(yearValue), monthIndex, 1);
    goToDate(newDate);
  };

  const handleChangeYear = (value: string | number) => {
    const newDate = new Date(Number(value), Number(monthValue), 1);
    goToDate(newDate);
  };

  if (!showYearSelect && !showMonthSelect) {
    return null;
  }

  return (
    <div className={styles.dateWrapper}>
      {showMonthSelect && <ListBox items={months} onChange={handleChangeMonth} isAbsolute value={monthValue} />}
      {showYearSelect && <ListBox items={years} onChange={handleChangeYear} isAbsolute value={yearValue} />}
    </div>
  );
});

export { CustomDropdown };
