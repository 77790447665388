import React, { FC, memo } from 'react';
import { Option } from 'shared/ui/CustomSelect/CustomSelect';
import { components, GroupBase, OptionProps } from 'react-select';

const AmountCurrencyFilterOption: FC<OptionProps<Option, false, GroupBase<Option>>> = memo((props) => {
  return (
    <components.Option {...props}>
      {props.label}
    </components.Option>
  );
});

export { AmountCurrencyFilterOption };
