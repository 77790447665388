import React, { FC, useCallback, useMemo } from 'react';
import { CustomSelect, Option } from 'shared/ui/CustomSelect/CustomSelect';
import { ActionMeta } from 'react-select';
import { currencyFilterStyles } from 'features/Table/lib/constants/select/styles/currencyFilterStyles';
import { useSelector } from 'react-redux';
import { AmountCurrencyFilterOption } from 'features/Table/ui/AmountCurrencyFilterOption/AmountCurrencyFilterOption';
import { AmountCurrencySingleValue } from 'features/Table/ui/AmountCurrencySingleValue/AmountCurrencySingleValue';
import { getPaymentGatewayBalanceData } from 'entities/PaymentGateway';

interface CurrencyFilterProps {
  className?: string;
  onChange?: (status: string) => void;
  value: string;
}

const AmountCurrencyFilter: FC<CurrencyFilterProps> = (props) => {
  const { onChange, value } = props;

  const balance = useSelector(getPaymentGatewayBalanceData);

  const filteredCurrents = useMemo(() => {
    const newBalance = (balance ?? []).map(el => el.currency);
    return Array.from(new Set(newBalance));
  }, [balance]);

  const handleSelectChange = useCallback((option: Option | null, actionMeta: ActionMeta<Option>) => {
    if (option) {
      onChange?.(option?.value);
    }
  }, []);

  const selectOptions = useMemo<Option[]>(() => {
    const options = filteredCurrents
      .map((currency) => {
        return {
          value: currency,
          label: currency
        } as Option;
      })
      .sort((a, b) => a.label.localeCompare(b.label));
    options.unshift({
      label: 'All',
      value: ''
    });

    return options;
  }, [filteredCurrents]);

  const currentOption = useMemo(() => {
    return selectOptions.find((option) => option.value === value);
  }, [value, selectOptions]);

  return (
    <CustomSelect<Option, false>
      id='status'
      isMulti={false}
      onChange={handleSelectChange}
      defaultValue={{ label: 'All', value: '' }}
      options={selectOptions}
      value={currentOption}
      styles={currencyFilterStyles}
      components={{ Option: AmountCurrencyFilterOption, SingleValue: AmountCurrencySingleValue }}
      isSearchable={false}
      placeholder=''
    />
  );
};

export { AmountCurrencyFilter };
