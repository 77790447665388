import React, { FC, memo } from 'react';
import styles from './PageTitle.module.scss';
import classNames from 'classnames';
import { TextSize, Text, TextWeight, TextTheme, TextTransform } from '../Text/Text';

interface PageTitleProps {
  className?: string;
  title?: string;
  transform?: TextTransform;
  size?: TextSize;
  weight?: TextWeight;
  theme?: TextTheme;
}

const PageTitle: FC<PageTitleProps> = memo((props) => {
  const { className, title, theme = 'greyColor100', transform, size = 'sizeL', weight } = props;

  return (
    <Text
      size={size}
      theme={theme}
      weight={weight}
      transform={transform}
      className={classNames(styles.title, {}, className)}
    >
      {title}
    </Text>
  );
});

export { PageTitle };
