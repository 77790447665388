import { ArrowButton } from './ArrowButton/ArrowButton';
import styles from './PaginationArrows.module.scss';
import React, { memo } from 'react';
import classNames from 'classnames';

interface PaginationArrowsProps {
  className?: string;
  handleNextPage: () => void;
  handlePrevPage: () => void;
  nextDisabled: boolean;
  prevDisabled: boolean;
}

export const PaginationArrows = memo((props: PaginationArrowsProps) => {
  const { className, handleNextPage, handlePrevPage, nextDisabled, prevDisabled } = props;

  return (
    <div className={classNames(styles.pagination, className)}>
      <ArrowButton onClick={handlePrevPage} disabled={prevDisabled} className={styles.arrow}>
        Prev
      </ArrowButton>
      <ArrowButton onClick={handleNextPage} disabled={nextDisabled} className={styles.arrow}>
        Next
      </ArrowButton>
    </div>
  );
});
