import { getAlertData } from '../../model/selectors/alert';
import { memo } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from '../Alert/Alert';
import { Portal } from 'shared/ui/Portal/Portal';

const alertRoot = document.getElementById('alert-root');

const AlertContainer = memo(() => {
  const alerts = useSelector(getAlertData);

  if (alerts.length === 0 || !alerts) {
    return null;
  }

  return (
    <Portal element={alertRoot!}>
      {alerts.map((alert) => (
        <Alert key={alert.id} alert={alert} />
      ))}
    </Portal>
  );
});

export { AlertContainer };
