import { createSlice } from '@reduxjs/toolkit';
import { getPaymentGateBalances } from '../../services/balances/getPaymentGateBalances';
import { PaymentGateBalancesSchema } from '../../types/balance/balance';

const initialState: PaymentGateBalancesSchema = {
  error: undefined,
  balance: undefined,
  isLoading: false
};

export const paymentGateBalancesSlice = createSlice({
  name: 'paymentGateBalances',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentGateBalances.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
      })
      .addCase(getPaymentGateBalances.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.balance = action.payload.balances;
      })
      .addCase(getPaymentGateBalances.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  }
});

export const { actions: paymentGateBalancesActions } = paymentGateBalancesSlice;
export const { reducer: paymentGateBalancesReducer } = paymentGateBalancesSlice;
