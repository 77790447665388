import React, { Component, ErrorInfo } from 'react';
import errorBoundarySrc from 'app/assets/images/not-found-page.png';
import styles from './ErrorBoundary.module.scss';
import { Text } from 'shared/ui/Text/Text';
import { Button } from 'shared/ui/Button/Button';

interface Props {
  children: React.ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error: ', error, errorInfo);
    this.setState({ hasError: true });
  }

  refreshPage = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      return (
        <div className={styles.errorBoundary}>
          <Text title="404" size="sizeXL" theme="primary" className={styles.text}>
            Something went wrong.
          </Text>
          <Text title="404" size="sizeXL" theme="primary" className={styles.text}>
            Try reloading the page or try again later
          </Text>
          <Button
            type="button"
            transform="uppercase"
            size="sizeL"
            weight="500"
            theme="third"
            className={styles.refreshBtn}
            radius="4px"
            alignItems="center"
            justifyContent="center"
            onClick={this.refreshPage}
          >
            refresh
          </Button>
          <img src={errorBoundarySrc} alt="Something Went Wrong" className={styles.errorImage} />
        </div>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
