import { RouteProps } from 'react-router-dom';
import { CryptoTransactionsPage } from 'pages/CryptoTransactionsPage';
import { DashboardPage } from 'pages/DashboardPage';
import { NewInvoicePage } from 'pages/NewInvoicePage';
import { CryptoTransactionDetailsPage } from 'pages/CryptoTransactionDetailsPage';
import { SettingsPage } from 'pages/SettingsPage';
import { CryptoWithdrawalsPage } from 'pages/CryptoWithdrawalsPage';
import { NewWithdrawalPage } from 'pages/NewWithdrawalPage';
import { CryptoWithdrawalDetailsPage } from 'pages/CryptoWithdrawalDetailsPage';
import { CryptoCurrencyPage } from 'pages/CryptoCurrencyPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { PaymentGatewayPage } from 'pages/PaymentGatewayPage';
import { PaymentGatewayTransactionDetailsPage } from 'pages/PaymentGatewayTransactionDetailsPage';
import { NewTransactionPage } from 'pages/NewTransactionPage';
import { PaymentGatewayWithdrawalDetailsPage } from 'pages/PaymentGatewayWithdrawalDetailsPage';
import { NewPaymentGateWithdrawalPage } from 'pages/NewPaymentGateWithdrawalPage';
import { NewExchangePage } from 'pages/NewExchangePage';
import { ExchangePage } from 'pages/ExchangePage';
import { ExchangeTransactionDetails } from 'pages/ExchangeTransactionDetails';
import { Integrations } from 'pages/Integrations';
import { DemoShopLogin } from 'pages/DemoShopLogin';
import { PartnerProgram } from 'pages/PartnerProgram';
import { NoAccessPage } from 'pages/NoAccessPage';
import { UserRole } from 'entities/User';
import { SwitcherePage } from 'pages/SwitcherePage';
import { PaymentLinkPage } from 'pages/PaymentLinkPage';

export type AppRoutesProps = RouteProps & {
  authOnly?: boolean;
  withLayout?: boolean;
  title?: string;
  nestedRoutes?: RouteProps[];
  roleRequested?: UserRole[];
};

export enum AppRoutes {
  DASHBOARD = 'dashboard',
  CRYPTO_TRANSACTIONS = 'crypto-transactions',
  CRYPTO_WITHDRAWALS = 'crypto-withdrawals',
  FIAT_GATEWAY = 'fiat-gateway',
  PAYMENT_LINK = 'payment-link',
  EXCHANGE = 'exchange',
  CRYPTO_TRANSACTIONS_DETAILS = 'crypto-transaction-details',
  CRYPTO_WITHDRAWAL_DETAILS = 'crypto-withdrawal-details',
  PAYMENT_GATEWAY_TRANSACTION_DETAILS = 'fiat-gateway-transaction-details',
  PAYMENT_GATEWAY_WITHDRAWAL_DETAILS = 'fiat-gateway-withdrawal-details',
  EXCHANGE_TRANSACTION_DETAILS = 'exchange-transaction-details',
  SETTINGS = 'settings',
  NEW_INVOICE = 'new-invoice',
  NEW_WITHDRAWAL = 'new-withdrawal',
  NEW_PAYMENT_GATEWAY_TRANSACTION = 'new-fiat-gateway-transaction',
  NEW_PAYMENT_GATEWAY_WITHDRAWAL = 'new-fiat-gateway-withdrawal',
  NEW_EXCHANGE = 'new-exchange',
  CRYPTOCURRENCY_PAGE = 'cryptocurrency',
  INTEGRATIONS = 'integrations',
  DEMO_SHOP_LOGIN = 'demo-shop-login',
  PARTNER_PROGRAM = 'partner-program',
  SWITCHERE = 'switchere',
  NO_ACCESS = 'no-access',
  NOT_FOUND = 'not-found'
}

export const RoutePath: Record<AppRoutes, string> = {
  [AppRoutes.DASHBOARD]: '/',
  [AppRoutes.CRYPTO_TRANSACTIONS]: '/crypto-transactions',
  [AppRoutes.CRYPTO_WITHDRAWALS]: '/crypto-withdrawals',
  [AppRoutes.FIAT_GATEWAY]: '/fiat-gateway/*',
  [AppRoutes.PAYMENT_LINK]: '/payment-link/*',
  [AppRoutes.EXCHANGE]: '/exchange',
  [AppRoutes.CRYPTO_TRANSACTIONS_DETAILS]: '/crypto-transaction-details/:id',
  [AppRoutes.CRYPTO_WITHDRAWAL_DETAILS]: '/crypto-withdrawal-details/:id',
  [AppRoutes.PAYMENT_GATEWAY_TRANSACTION_DETAILS]: '/fiat-gateway-transaction-details/:id',
  [AppRoutes.PAYMENT_GATEWAY_WITHDRAWAL_DETAILS]: '/fiat-gateway-withdrawal-details/:id',
  [AppRoutes.EXCHANGE_TRANSACTION_DETAILS]: '/exchange-transaction-details/:id',
  [AppRoutes.NEW_INVOICE]: '/new-invoice',
  [AppRoutes.NEW_WITHDRAWAL]: '/new-withdrawal',
  [AppRoutes.NEW_PAYMENT_GATEWAY_TRANSACTION]: '/new-fiat-gateway-transaction',
  [AppRoutes.NEW_PAYMENT_GATEWAY_WITHDRAWAL]: '/new-fiat-gateway-withdrawal',
  [AppRoutes.NEW_EXCHANGE]: '/new-exchange',
  [AppRoutes.SETTINGS]: '/settings/*',
  [AppRoutes.CRYPTOCURRENCY_PAGE]: '/cryptocurrency/:code/*',
  [AppRoutes.INTEGRATIONS]: '/integrations/*',
  [AppRoutes.DEMO_SHOP_LOGIN]: '/demo-shop-login',
  [AppRoutes.PARTNER_PROGRAM]: '/partner-program/*',
  [AppRoutes.SWITCHERE]: '/topup/:code',
  [AppRoutes.NO_ACCESS]: '/no-access',
  [AppRoutes.NOT_FOUND]: '*'
};

export const routeConfig: Record<AppRoutes, AppRoutesProps> = {
  [AppRoutes.DASHBOARD]: {
    path: RoutePath.dashboard,
    element: <DashboardPage />,
    withLayout: true,
    title: 'Dashboard'
  },
  [AppRoutes.CRYPTO_TRANSACTIONS]: {
    path: RoutePath['crypto-transactions'],
    element: <CryptoTransactionsPage />,
    withLayout: true,
    title: 'Crypto transactions'
  },
  [AppRoutes.CRYPTO_WITHDRAWALS]: {
    path: RoutePath['crypto-withdrawals'],
    element: <CryptoWithdrawalsPage />,
    withLayout: true,
    title: 'Crypto withdrawals'
  },
  [AppRoutes.FIAT_GATEWAY]: {
    path: RoutePath['fiat-gateway'],
    element: <PaymentGatewayPage />,
    withLayout: true,
    title: 'Payment Gateway'
  },
  [AppRoutes.PAYMENT_LINK]: {
    path: RoutePath['payment-link'],
    element: <PaymentLinkPage />,
    withLayout: true,
    title: 'Payment Link'
  },
  [AppRoutes.EXCHANGE]: {
    path: RoutePath.exchange,
    element: <ExchangePage />,
    withLayout: true,
    title: 'Exchange'
  },
  [AppRoutes.PAYMENT_GATEWAY_WITHDRAWAL_DETAILS]: {
    path: RoutePath['fiat-gateway-withdrawal-details'],
    element: <PaymentGatewayWithdrawalDetailsPage />,
    withLayout: true,
    title: 'Payment Gateway withdrawal details'
  },
  [AppRoutes.CRYPTO_TRANSACTIONS_DETAILS]: {
    path: `${RoutePath['crypto-transaction-details']}`,
    element: <CryptoTransactionDetailsPage />,
    withLayout: true,
    title: 'Crypto transactions details'
  },
  [AppRoutes.CRYPTO_WITHDRAWAL_DETAILS]: {
    path: `${RoutePath['crypto-withdrawal-details']}`,
    element: <CryptoWithdrawalDetailsPage />,
    withLayout: true,
    title: 'Crypto withdrawals details'
  },
  [AppRoutes.PAYMENT_GATEWAY_TRANSACTION_DETAILS]: {
    path: RoutePath['fiat-gateway-transaction-details'],
    element: <PaymentGatewayTransactionDetailsPage />,
    withLayout: true,
    title: 'Payment Gateway transaction details'
  },
  [AppRoutes.EXCHANGE_TRANSACTION_DETAILS]: {
    path: RoutePath['exchange-transaction-details'],
    element: <ExchangeTransactionDetails />,
    withLayout: true,
    title: 'Exchange transaction details'
  },
  [AppRoutes.SETTINGS]: {
    path: RoutePath.settings,
    element: <SettingsPage />,
    withLayout: true,
    title: 'Settings'
  },
  [AppRoutes.NEW_INVOICE]: {
    path: RoutePath['new-invoice'],
    element: <NewInvoicePage />,
    withLayout: true,
    title: 'New invoice'
  },
  [AppRoutes.NEW_WITHDRAWAL]: {
    path: RoutePath['new-withdrawal'],
    element: <NewWithdrawalPage />,
    withLayout: true,
    title: 'New withdrawal'
  },
  [AppRoutes.NEW_PAYMENT_GATEWAY_TRANSACTION]: {
    path: RoutePath['new-fiat-gateway-transaction'],
    element: <NewTransactionPage />,
    withLayout: true,
    title: 'New payment gateway transaction'
  },
  [AppRoutes.NEW_PAYMENT_GATEWAY_WITHDRAWAL]: {
    path: RoutePath['new-fiat-gateway-withdrawal'],
    element: <NewPaymentGateWithdrawalPage />,
    withLayout: true,
    title: 'New payment gateway withdrawal'
  },
  [AppRoutes.NEW_EXCHANGE]: {
    path: RoutePath['new-exchange'],
    element: <NewExchangePage />,
    withLayout: true,
    title: 'New exchange'
  },
  [AppRoutes.CRYPTOCURRENCY_PAGE]: {
    path: RoutePath.cryptocurrency,
    element: <CryptoCurrencyPage />,
    withLayout: true
  },
  [AppRoutes.INTEGRATIONS]: {
    path: RoutePath.integrations,
    element: <Integrations />,
    withLayout: true,
    title: 'Integrations'
  },
  [AppRoutes.DEMO_SHOP_LOGIN]: {
    path: RoutePath['demo-shop-login'],
    element: <DemoShopLogin />,
    withLayout: false,
    title: 'Demo shop login'
  },
  [AppRoutes.PARTNER_PROGRAM]: {
    path: RoutePath['partner-program'],
    element: <PartnerProgram />,
    withLayout: true,
    title: 'Partner program',
    roleRequested: ['partner']
  },
  [AppRoutes.SWITCHERE]: {
    path: RoutePath.switchere,
    element: <SwitcherePage />,
    withLayout: true,
    title: 'Switchere'
  },
  [AppRoutes.NO_ACCESS]: {
    path: RoutePath['no-access'],
    element: <NoAccessPage />,
    withLayout: false,
    title: 'No access'
  },
  [AppRoutes.NOT_FOUND]: {
    path: RoutePath['not-found'],
    element: <NotFoundPage />,
    withLayout: false,
    title: 'Not found'
  }
};
