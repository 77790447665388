import React, { FC, useState } from 'react';
import { UnsavedChangesContext } from '../lib/UnsavedChangesContext';

interface UnsavedChangesProviderProps {
  children?: React.ReactNode;
}

export const UnsavedChangesProvider: FC<UnsavedChangesProviderProps> = (props) => {
  const { children } = props;

  const [unsavedChanges, setUnsavedChanges] = useState(false);

  return (
    <UnsavedChangesContext.Provider value={{ unsavedChanges, setUnsavedChanges }}>
      {children}
    </UnsavedChangesContext.Provider>
  );
};
