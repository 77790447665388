import React, { FC, useCallback } from 'react';
import styles from './LogoutButton.module.scss';
import classNames from 'classnames';
import { Button } from 'shared/ui/Button/Button';
import { Icon } from 'shared/ui/Icon/Icon';
import { Text } from 'shared/ui/Text/Text';
import { ReactComponent as LogoutIcon } from 'app/assets/icons/logout-icon.svg';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { logout } from 'entities/User';

interface LogoutButtonProps {
  className?: string;
  isMounted?: boolean;
  lazy?: boolean;
  menuRef: React.RefObject<HTMLButtonElement>;
}

const LogoutButton: FC<LogoutButtonProps> = (props) => {
  const { className, menuRef } = props;

  const dispatch = useAppDispatch();

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  return (
    <Button
      ref={menuRef}
      className={classNames(styles.logoutButton, className)}
      size="sizeM"
      type="button"
      onClick={handleLogout}
    >
      <Icon SVG={LogoutIcon} className={styles.icon} />
      <Text className={styles.text} size="sizeM">
        Logout
      </Text>
    </Button>
  );
};

export { LogoutButton };
