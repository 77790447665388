import { NEW_WITHDRAW_FORM_ERRORS } from '../../../model/types/new-withdrawal';

export interface NewWithdrawalError {
  [key: string]: string;
}

export enum CODES {
  CODE_10 = 10,
  CODE_13 = 13
}

export const NEW_WITHDRAWAL_ERROR: NewWithdrawalError = {
  [CODES.CODE_10]: 'You need to have funds in your wallet for commission',
  [CODES.CODE_13]: 'Internal server error'
};

export const NEW_WITHDRAW_FORM_ERROR: Record<NEW_WITHDRAW_FORM_ERRORS, string> = {
  NOT_ENOUGH_MONEY: 'Not enough money'
};
