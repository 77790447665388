import { CryptoName } from './crypto-names';

export const CURRENCY_WEIGHT: Record<CryptoName, number> = {
  btc: 15,
  eth: 14,
  usdt: 13,
  usdt_trc20: 12,
  bscusd: 11,
  bnb: 10,
  usdc: 9,
  busd: 8,
  ltc: 7,
  trx: 6,
  bch: 5,
  zec: 4,
  dash: 3,
  qtum: 2,
  btg: 1
};
