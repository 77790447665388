import { useState, useEffect, useCallback } from 'react';

export interface ResponseError {
  code: number;
  details: any[];
  message: string;
}

export const useAsync = <T, E = ResponseError>(asyncFunction: () => Promise<T>, immediate = true) => {
  const [status, setStatus] = useState<'idle' | 'pending' | 'success' | 'error'>('idle');
  const [value, setValue] = useState<T | undefined>();
  const [error, setError] = useState<E>();

  const execute = useCallback(async () => {
    setStatus('pending');
    setValue(undefined);
    setError(undefined);
    try {
      const response = await asyncFunction();
      setValue(response);
      setStatus('success');
    } catch (error: unknown) {
      setError(error as ResponseError);
      setStatus('error');
    }
  }, [asyncFunction]);

  useEffect(() => {
    if (immediate) {
      execute();
    }
  }, [execute, immediate]);
  return { execute, status, value, loading: status === 'pending', error };
};
