import { Fragment, FC } from 'react';
import styles from './ListBox.module.scss';
import classNames from 'classnames';
import { Listbox } from '@headlessui/react';
import { ReactComponent as ArrowIcon } from 'app/assets/icons/arrow.svg';
import { Icon } from 'shared/ui/Icon/Icon';
import { Text } from 'shared/ui/Text/Text';
import { Option } from 'shared/ui/CustomSelect/CustomSelect';
import { autoUpdate, flip, offset, shift, useFloating } from '@floating-ui/react';

interface ListBoxProps {
  className?: string;
  items: Option[];
  value?: string | number;
  defaultValue?: string;
  onChange: (value: string) => void;
  filterName?: string;
  label?: string;
  isAbsolute?: boolean;
}

const ListBox: FC<ListBoxProps> = (props) => {
  const { className, items, onChange, defaultValue, label, value, filterName, isAbsolute } = props;

  const { x, y, refs, strategy } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: 'bottom-start',
    middleware: [offset(10), flip(), shift()]
  });

  const currentItem = items.find((item) => item.value === value && value !== '');

  const listBoxMods = {
    [styles.isAbsolute]: isAbsolute
  };

  return (
    <Listbox
      as="div"
      className={classNames(styles.listBox, {}, [className])}
      value={value ?? defaultValue}
      onChange={onChange}
    >
      {({ disabled, open, value }) => {
        return (
          <>
            {label && <Listbox.Label className={styles.label}>{label}</Listbox.Label>}
            <div
              className={classNames(styles.buttonWrapper, {
                [styles.active]: value
              })}
            >
              <Listbox.Button className={styles.trigger} ref={refs.setReference}>
                <Text size="sizeS" weight="500" theme="primary" lineHeight="1.2">
                  {currentItem ? currentItem.label : filterName}
                </Text>
                {!value && <Icon SVG={ArrowIcon} />}
              </Listbox.Button>
            </div>
            <Listbox.Options
              className={classNames(styles.options, listBoxMods)}
              ref={refs.setFloating}
              style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0
              }}
            >
              {items.map((item) => (
                <Listbox.Option key={item.value} value={item.value} as={Fragment}>
                  {({ active, selected, disabled }) => (
                    <li
                      className={classNames(styles.item, {
                        [styles.active]: active,
                        [styles.selected]: selected,
                        [styles.disabled]: disabled
                      })}
                    >
                      {item.label}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </>
        );
      }}
    </Listbox>
  );
};

export { ListBox };
