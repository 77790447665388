import { createSelector } from '@reduxjs/toolkit';
import { StateSchema } from 'app/providers/StoreProvider';

export const getNewInvoiceState = (state: StateSchema) => state.newInvoice;

export const getDataToCreateNewInvoice = createSelector(
  getNewInvoiceState,
  (state) => state?.dataToCreateNewInvoice ?? {}
);

export const getIsCreateNewInvoiceLoading = createSelector(getNewInvoiceState, (state) => state?.isLoading ?? false);

export const getCreateNewInvoiceError = createSelector(getNewInvoiceState, (state) => state?.error ?? undefined);

export const getNewTransactionData = createSelector(getNewInvoiceState, (state) => state?.newTransaction ?? undefined);

export const getIsSuccessCreateInvoice = createSelector(getNewInvoiceState, (state) => state?.isSuccess ?? false);
