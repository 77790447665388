export type CryptoName =
  | 'eth'
  | 'btc'
  | 'bch'
  | 'btg'
  | 'zec'
  | 'dash'
  | 'ltc'
  | 'usdt'
  | 'usdc'
  | 'qtum'
  | 'trx'
  | 'usdt_trc20'
  | 'bscusd'
  | 'bnb'
  | 'busd';

export type BlockchainNetwork = 'usdt' | 'usdt_trc20' | 'usdc' | 'bscusd' | 'busd';

export const CRYPTO_NAME: Record<CryptoName, string> = {
  eth: 'Ethereum',
  btc: 'Bitcoin',
  bch: 'Bitcoin Cash',
  btg: 'Bitcoin Gold',
  zec: 'Zcash',
  dash: 'Dash',
  ltc: 'Litecoin',
  usdt: 'Usdt',
  usdc: 'Usdc',
  qtum: 'Qtum',
  trx: 'Tron',
  usdt_trc20: 'Usdt',
  bscusd: 'Usdt',
  bnb: 'BNB',
  busd: 'BUSD'
};

export const CRYPTO_CODES: Record<CryptoName, string> = {
  btc: 'BTC',
  eth: 'ETH',
  usdt: 'USDT (ERC-20)',
  usdc: 'USDC (ERC-20)',
  ltc: 'LTC',
  bch: 'BCH',
  zec: 'ZEC',
  qtum: 'QTUM',
  btg: 'BTG',
  dash: 'DASH',
  trx: 'TRX',
  usdt_trc20: 'USDT (TRC-20)',
  bscusd: 'USDT (BEP-20)',
  bnb: 'BNB',
  busd: 'BUSD (BEP-20)'
};

export const SELECT_CRYPTO_CODES: Record<CryptoName, string> = {
  btc: 'BITCOIN',
  eth: 'ETHEREUM',
  usdt: 'USDT (ERC-20)',
  usdc: 'USDC (ERC-20)',
  ltc: 'LITECOIN',
  bch: 'BITCOIN CASH',
  zec: 'ZCASH',
  qtum: 'QTUM',
  btg: 'BITCOIN GOLD',
  dash: 'DASH',
  trx: 'TRON',
  usdt_trc20: 'USDT (TRC-20)',
  bscusd: 'USDT (BEP-20)',
  bnb: 'BINANCE COIN',
  busd: 'BUSD (BEP-20)'
};

export const CRYPTO_SHORT_NAME: Record<CryptoName, string> = {
  btc: 'BTC',
  eth: 'ETH',
  usdt: 'USDT',
  usdc: 'USDC',
  ltc: 'LTC',
  bch: 'BCH',
  zec: 'ZEC',
  qtum: 'QTUM',
  btg: 'BTG',
  dash: 'DASH',
  trx: 'TRX',
  usdt_trc20: 'USDT',
  bscusd: 'USDT',
  bnb: 'BNB',
  busd: 'BUSD'
};

export enum CRYPTO_SHORT_NAMES {
  BTC = 'BTC',
  ETH = 'ETH',
  USDT = 'USDT',
  USDC = 'USDC',
  LTC = 'LTC',
  BCH = 'BCH',
  ZEC = 'ZEC',
  QTUM = 'QTUM',
  BTG = 'BTG',
  DASH = 'DASH',
  TRX = 'TRX',
  USDT_TRC20 = 'USDT_TRC20',
  BSCUSD = 'BSCUSD',
  BNB = 'BNB',
  BUSD = 'BUSD'
}

export const CRYPTO_OPERATION_NAME: Record<CryptoName, string> = {
  btc: 'BTC',
  eth: 'ETH',
  usdt: 'USDT',
  usdc: 'USDC',
  ltc: 'LTC',
  bch: 'BCH',
  zec: 'ZEC',
  qtum: 'QTUM',
  btg: 'BTG',
  dash: 'DASH',
  trx: 'TRX',
  usdt_trc20: 'USDT_TRC20',
  bscusd: 'BSCUSD',
  bnb: 'BNB',
  busd: 'BUSD'
};

export const CRYPTO_VALUES_TO_DIVIDE: Record<CRYPTO_SHORT_NAMES, number> = {
  BTC: 100000000,
  ETH: 1000000000000000000,
  USDT: 1000000,
  USDC: 1000000,
  LTC: 100000000,
  BCH: 100000000,
  ZEC: 100000000,
  QTUM: 100000000,
  BTG: 100000000,
  DASH: 100000000,
  TRX: 1000000,
  USDT_TRC20: 1000000,
  BNB: 1000000000000000000,
  BSCUSD: 1000000000000000000,
  BUSD: 1000000000000000000
};

export const CRYPTO_VALUES_TO_DIVIDE_WITHDRAWAL: Record<CRYPTO_SHORT_NAMES, number> = {
  BTC: 100000000,
  ETH: 1000000000000000000,
  USDT: 1000000,
  USDC: 1000000,
  LTC: 100000000,
  BCH: 100000000,
  ZEC: 100000000,
  QTUM: 100000000,
  BTG: 100000000,
  DASH: 100000000,
  TRX: 1000000,
  USDT_TRC20: 1000000,
  BNB: 1000000000000000000,
  BSCUSD: 1000000000000000000,
  BUSD: 1000000000000000000
};

export const CRYPTO_CURRENCY: Record<CRYPTO_SHORT_NAMES, string> = {
  BTC: 'Units',
  ETH: 'Wei',
  USDT: 'Units',
  USDC: 'Units',
  LTC: 'Units',
  BCH: 'Units',
  ZEC: 'Units',
  QTUM: 'Units',
  BTG: 'Units',
  DASH: 'Units',
  TRX: 'Sun',
  USDT_TRC20: 'Units',
  BNB: 'Units',
  BSCUSD: 'Units',
  BUSD: 'Units'
};

export const TRANSACTIONS_CRYPTO_CODES: Record<CryptoName, string> = {
  btc: 'BTC',
  eth: 'ETH',
  usdt: 'USDT',
  usdc: 'USDC',
  ltc: 'LTC',
  bch: 'BCH',
  zec: 'ZEC',
  qtum: 'QTUM',
  btg: 'BTG',
  dash: 'DASH',
  trx: 'TRX',
  usdt_trc20: 'USDT',
  bscusd: 'USDT',
  bnb: 'BNB',
  busd: 'BUSD'
};

export const BLOCKCHAIN_NETWORK: Record<BlockchainNetwork, string> = {
  usdt: 'ERC-20',
  usdt_trc20: 'TRC-20',
  usdc: 'ERC-20',
  bscusd: 'BEP-20',
  busd: 'BEP-20'
};

// eslint-disable-next-line max-len
export const cryptoArr = ['eth', 'btc', 'bch', 'btg', 'zec', 'dash', 'ltc', 'usdt', 'usdc', 'qtum', 'trx', 'usdt_trc20', 'bscusd', 'bnb', 'busd'];
