import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError, ResponseError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import {
  getPaymentGateTransactionPaymentMethodFilter,
  getPaymentGatewayTransactionsCurrency,
  getPaymentGatewayTransactionsDateFilter,
  getPaymentGatewayTransactionsStatus
} from 'pages/PaymentGatewayPage';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const downloadPaymentGateTransactionsCsv = createAsyncThunk<
  { message: string },
  undefined,
  ThunkConfig<ResponseError | undefined>
>(
  'paymentGatewayTransactionsSlice/downloadPaymentGateTransactionsCsv',
  async (_, { extra, getState, rejectWithValue }) => {
    const date = getPaymentGatewayTransactionsDateFilter(getState());
    const status = getPaymentGatewayTransactionsStatus(getState());
    const currency = getPaymentGatewayTransactionsCurrency(getState());
    const paymentMethod = getPaymentGateTransactionPaymentMethodFilter(getState());

    const { adjustTillDate } = await import('shared/lib/timeFunctions/dateFilter/ISOformat/adjustTillDate');

    const params = new URLSearchParams();

    let sinceData: string | undefined = '';
    let until: string | undefined = '';

    if (status !== '') {
      params.append('status', status);
    }

    if (currency !== '') {
      params.append('currency', currency);
    }

    if (paymentMethod !== '') {
      params.append('payment_method', paymentMethod);
    }

    if (date?.dateFrom && date.dateTo) {
      const { since, till } = adjustTillDate(new Date(date.dateFrom), new Date(date.dateTo));

      sinceData = since;
      until = till;
    } else {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const pastDate = new Date(currentDate);
      pastDate.setFullYear(currentYear - 1);
      const { since, till } = adjustTillDate(pastDate, new Date());
      sinceData = since;
      until = till;
    }

    params.append('since', sinceData);
    params.append('until', until);

    try {
      const response = await extra.apiWithAuth.get('/fiat/v1/reports/transactions', {
        params,
        responseType: 'blob'
      });

      if (!response.data) {
        throw new Error('Ошибка при попытке выгрузить транзакции в .csv');
      }

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;

      const fileName = 'transactions.xlsx';
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);

      return { message: 'Выгрузка транзакций прошла успешно!' };
    } catch (e) {
      const error = e as MyAxiosError<ResponseError>;
      if (error.response) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(undefined);
    }
  }
);
