export interface WithdrawalsStatus {
  [key: string]: string;
}

export enum WITHDRAWALS_STATUSES {
  WITHDRAWAL_STATUS_PENDING = 'withdrawal_status_pending',
  WITHDRAWAL_STATUS_COMPLETED = 'withdrawal_status_complete',
  WITHDRAWAL_STATUS_FAILED = 'withdrawal_status_failed',
  WITHDRAWAL_STATUS_WAITING = 'withdrawal_status_waiting',
  WITHDRAWAL_STATUS_CANCELED = 'withdrawal_status_canceled'
}

export const WITHDRAWAL_STATUS: WithdrawalsStatus = {
  [WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_PENDING]: 'Pending',
  [WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_COMPLETED]: 'Completed',
  [WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_FAILED]: 'Failed',
  [WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_WAITING]: 'Waiting',
  [WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_CANCELED]: 'Canceled'
};

export const WITHDRAWAL_STATUS_CLASSES: WithdrawalsStatus = {
  [WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_PENDING]: 'pending',
  [WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_COMPLETED]: 'confirmed',
  [WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_FAILED]: 'failed',
  [WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_WAITING]: 'waiting',
  [WITHDRAWALS_STATUSES.WITHDRAWAL_STATUS_CANCELED]: 'canceled'
};
