import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { getAuthUrl } from 'entities/User/model/services/getAuthUrl';
import { tokenChecker } from 'entities/User/model/services/tokenChecker';
import { baseUrl } from 'shared/constants/apiUrl';
import { authUrl } from 'shared/constants/authUrl';
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_IS_REDIRECTED_TO_KEYCLOAK,
  LOCAL_STORAGE_REFRESH_TOKEN,
  LOCAL_STORAGE_USER_DATA
} from 'shared/constants/localstorage/localstorage';

interface AuthConfig {
  token?: string | null;
}

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  _retry?: boolean;
}

interface TimeoutError extends Error {
  status: number;
}

export const axiosWithAuthInstance = (authConfig: AuthConfig, url?: string): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: url ?? baseUrl
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      config.timeout = 300000;
      const token = authConfig.token;
      if (token) {
        config.headers = Object.assign({}, config.headers, {
          Authorization: `Bearer ${token}`
        });
      }
      return config;
    },
    async (error: any) => {
      return await Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    async (error: AxiosError) => {
      const originalRequest = error.config as CustomAxiosRequestConfig;
      if ((error?.response?.status === 401 || error?.response?.status === 403) && !originalRequest?._retry) {
        originalRequest._retry = true;

        try {
          const data = await tokenChecker();
          const accessToken = data?.accessToken;
          if (accessToken) {
            authConfig.token = accessToken;
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          }

          return await axiosInstance(originalRequest);
        } catch (error) {
          localStorage.removeItem(LOCAL_STORAGE_IS_REDIRECTED_TO_KEYCLOAK);
          localStorage.removeItem(LOCAL_STORAGE_USER_DATA);
          localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
          localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
          const authUrl = await getAuthUrl();
          window.location.href = authUrl;
        }
      }

      if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
        console.log('Request timeout exceeded');
        const timeoutError = new Error('Request timeout exceeded') as TimeoutError;
        timeoutError.status = 408;
        return await Promise.reject(timeoutError);
      }

      return await Promise.reject(error);
    }
  );

  return axiosInstance;
};

const accessToken = localStorage.getItem(LOCAL_STORAGE_ACCESS_TOKEN);

const authConfig = {
  token: accessToken
};

export const $apiWithAuth = axiosWithAuthInstance(authConfig);

export const $keycloakWithAuth = axiosWithAuthInstance(authConfig, authUrl);
