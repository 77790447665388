import { $api } from 'shared/api/api';
import { envURL } from 'shared/constants/envUrl';
import { AuthorizationResponse } from '../types/user';

export const getAuthUrl = async (level: string = 'standard', query: string = '/') => {
  const body = {
    redirectUrl: envURL + query,
    level
  };

  const response = await $api.post<AuthorizationResponse>('v1/auth', body, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  return response.data.authUrl;
};
