import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { CryptoTransaction } from 'pages/CryptoTransactionsPage/model/types/crypto-transactions';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const approveTransaction = createAsyncThunk<void, string, ThunkConfig<MyAxiosError<any>>>(
  'transactionDetails/approveTransaction',
  async (txHash, { rejectWithValue, dispatch, extra }) => {
    try {
      const response = await extra.apiWithAuth.patch<CryptoTransaction>(`/v1/transaction/incoming/${txHash}/approve`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (!response.data) {
        throw new Error();
      }
    } catch (e) {
      const error = e as MyAxiosError<any>;
      error.message = 'The waiting time for a request to receive transaction data has been exceeded';
      return rejectWithValue(error);
    }
  }
);
