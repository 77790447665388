import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { MyAxiosError } from 'shared/types/axios';
import { envURL } from 'shared/constants/envUrl';
import { AuthTokensResponse, UserData } from '../types/user';
import { getDataFromUrl } from 'entities/User/lib/authUrl/getDataFromUrl';
import { parseJwt } from 'shared/lib/jwt/parseJwt';
import { userActions } from '../slices/user';
import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_IS_REDIRECTED_TO_KEYCLOAK,
  LOCAL_STORAGE_KEYCLOAK_REDIRECTED_QUERY,
  LOCAL_STORAGE_REFRESH_TOKEN,
  LOCAL_STORAGE_USER_DATA
} from 'shared/constants/localstorage/localstorage';
import { getAuthUrl } from './getAuthUrl';
import { AlertType, alertActions } from 'entities/Alert';

interface Authorization {
  level?: string;
  query?: string;
}

export const authorization = createAsyncThunk<AuthTokensResponse | null, Authorization, ThunkConfig<MyAxiosError<any>>>(
  'user/authorization',
  async ({ level, query = '/' }, { rejectWithValue, extra, dispatch }) => {
    localStorage.removeItem(LOCAL_STORAGE_USER_DATA);
    localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);

    try {
      const isRedirected = localStorage.getItem(LOCAL_STORAGE_IS_REDIRECTED_TO_KEYCLOAK) === 'true';
      const redirectedQuery = localStorage.getItem(LOCAL_STORAGE_KEYCLOAK_REDIRECTED_QUERY) ?? '/';

      if (!isRedirected) {
        localStorage.setItem(LOCAL_STORAGE_IS_REDIRECTED_TO_KEYCLOAK, 'true');
        const authUrl = await getAuthUrl(level, redirectedQuery);
        window.location.href = authUrl;
      }

      const { authContext, code } = getDataFromUrl();

      if (authContext && code) {
        const body = {
          code,
          authContext,
          redirectUrl: envURL + redirectedQuery + '?auth_context=' + authContext
        };

        const response = await extra.api.put<AuthTokensResponse>('v1/auth', body, {
          headers: {
            'Content-Type': 'application/json'
          }
        });

        localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, response.data.access_token);
        localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, response.data.refresh_token);
        localStorage.removeItem(LOCAL_STORAGE_KEYCLOAK_REDIRECTED_QUERY);
        const userData = parseJwt(response.data.access_token) as UserData;

        const { acr } = userData;

        if (acr === '2') {
          dispatch(
            alertActions.setAlert({
              id: crypto.randomUUID(),
              message: 'Two-factor authorization successful enabled',
              timeout: 3000,
              type: AlertType.SUCCESS
            })
          );
        }

        dispatch(userActions.setUserData(userData));

        return response.data;
      } else {
        return null;
      }
    } catch (e) {
      return rejectWithValue(e as MyAxiosError<any>);
    }
  }
);
