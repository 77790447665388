import { FC } from 'react';
import { GroupBase, SingleValueProps, components } from 'react-select';
import { Option } from 'shared/ui/CustomSelect/CustomSelect';

interface PaymentGatePaymentMethodSingleValueProps extends SingleValueProps<Option, false, GroupBase<Option>> {}

const PaymentGatePaymentMethodSingleValue: FC<PaymentGatePaymentMethodSingleValueProps> = (props) => {
  const { data } = props;

  return <components.SingleValue {...props}>&#40;{data.label}&#41;</components.SingleValue>;
};

export { PaymentGatePaymentMethodSingleValue };
