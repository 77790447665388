import { FC, MutableRefObject, ReactNode, memo, useRef } from 'react';
import styles from './MobileCardsList.module.scss';
import { CryptoTransaction } from '../../model/types/cryptoTransactions/cryptoTransactions';
import { CryptoWithdrawal } from '../../model/types/cryptoWithdrawals/cryptoWithdrawals';
import { Text } from 'shared/ui/Text/Text';
import { Loader } from 'widgets/Loader';
import { useInfiniteScroll } from 'shared/lib/hooks/useInfinityScroll/useInfinityScroll';
import { ExchangeTransaction } from '../../model/types/exchangeTransaction/exchangeTransaction';
import { PaymentGatewayTransaction, PaymentGatewayWithdrawal } from 'entities/PaymentGateway';
import { AffiliateTransaction } from 'entities/Transaction/model/types/affiliate/affiliate';

interface MobileCardsListProps<T extends TransactionData> {
  isLoading: boolean;
  data?: T[];
  children?: ReactNode;
  onScrollEnd?: () => void;
}

export type TransactionData =
  | CryptoTransaction
  | CryptoWithdrawal
  | ExchangeTransaction
  | PaymentGatewayTransaction
  | PaymentGatewayWithdrawal
  | AffiliateTransaction;

const MobileCardsList: FC<MobileCardsListProps<TransactionData>> = memo((props) => {
  const { data, children, isLoading, onScrollEnd } = props;

  const wrapperRef = useRef() as MutableRefObject<HTMLUListElement>;
  const triggerRef = useRef() as MutableRefObject<HTMLDivElement>;

  useInfiniteScroll({
    triggerRef,
    wrapperRef,
    callback: onScrollEnd
  });

  return (
    <div className={styles.wrapper}>
      {data && data.length > 0 ? (
        <ul className={styles.mobileCardsList} ref={wrapperRef} onClick={(e) => e.stopPropagation()}>
          {children}
        </ul>
      ) : (
        !isLoading && (
          <Text transform="uppercase" size="sizeL" theme="greyColor100" weight="500" className={styles.notFound}>
            Not Found
          </Text>
        )
      )}
      {isLoading && <Loader className={styles.loader} />}
      <div ref={triggerRef} />
    </div>
  );
});

export { MobileCardsList };
