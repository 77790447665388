import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { getAffiliateReport } from './getAffiliateReport';
import { affiliateReportActions } from '../../../../model/slices/affiliate/report/affiliateReport';
import { PeriodGroupFilterValue } from '../../../../model/types/affiliate/report/affiliateReport';
import { FIRST_DATE_OF_CURRENT_MONTH, LAST_DATE_OF_CURRENT_MONTH } from 'features/DatePicker';

interface InitCryptoTransactionProps {
  searchParams?: URLSearchParams;
}

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const initAffiliateReportsPage = createAsyncThunk<void, InitCryptoTransactionProps, ThunkConfig<string>>(
  'affiliate/initAffiliatePage',
  async ({ searchParams }, { dispatch }) => {
    if (searchParams) {
      const code = searchParams.get('code');
      const sinceTime = searchParams.get('since');
      const untilTime = searchParams.get('until');
      const groupPeriod = searchParams.get('groupPeriod');

      if (code) {
        dispatch(affiliateReportActions.setCurrencyFilter(code));
      }

      if (groupPeriod) {
        dispatch(affiliateReportActions.setPeriodGroup(groupPeriod as PeriodGroupFilterValue));
      }

      if (sinceTime && untilTime) {
        dispatch(
          affiliateReportActions.setDate({
            dateFrom: sinceTime,
            dateTo: untilTime.replace('T23:59:59.999Z', 'T00:00:00.000Z')
          })
        );
      } else {
        dispatch(
          affiliateReportActions.setDate({
            dateFrom: FIRST_DATE_OF_CURRENT_MONTH.toString(),
            dateTo: LAST_DATE_OF_CURRENT_MONTH.toString()
          })
        );
      }

      dispatch(getAffiliateReport({ searchParams }));
    }
  }
);
