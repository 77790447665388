export interface TransactionsStatus {
  [key: string]: string;
}

export enum TRANSACTIONS_STATUSES {
  TRANSACTION_STATUS_ALL = 'all',
  TRANSACTION_STATUS_PENDING = 'transaction_status_pending',
  TRANSACTION_STATUS_SENDING = 'transaction_status_sending',
  TRANSACTION_STATUS_WAITING = 'transaction_status_waiting',
  TRANSACTION_STATUS_CONFIRMED = 'transaction_status_confirmed',
  TRANSACTION_STATUS_COMPLETE = 'transaction_status_complete',
  TRANSACTION_STATUS_PROCESSED = 'transaction_status_processed',
  TRANSACTION_STATUS_FAILED = 'transaction_status_failed',
  TRANSACTION_STATUS_CANCELED = 'transaction_status_canceled',
  TRANSACTION_STATUS_SKIPPED = 'transaction_status_skipped',
  TRANSACTION_STATUS_DUST = 'transaction_status_dust',
  TRANSACTION_STATUS_PROCESSING = 'transaction_status_processing',
  TRANSACTION_STATUS_CHECK_REQUIRED = 'transaction_status_check_required',
  TRANSACTION_STATUS_APPROVE_REQUIRED = 'transaction_status_approve_required',
  TRANSACTION_STATUS_REFUND = 'transaction_status_refund',
  TRANSACTION_STATUS_REFUND_REQUIRED = 'transaction_status_refund_required',
  TRANSACTION_STATUS_REFUNDED = 'transaction_status_refunded',
  TRANSACTION_STATUS_REFUNDING = 'transaction_status_refunding',
  TRANSACTION_STATUS_EXPIRED = 'transaction_status_expired',
  TRANSACTION_STATUS_EXCHANGE_WAITING = 'transaction_status_exchange_waiting',
  TRANSACTION_STATUS_EXCHANGING = 'transaction_status_exchanging',
  TRANSACTION_STATUS_EXCHANGE_SENDING = 'transaction_status_exchange_sending',
  TRANSACTION_STATUS_EXCHANGE_FAILED = 'transaction_status_exchange_failed',
  TRANSACTION_STATUS_VERIFY_REQUIRED = 'transaction_status_verify_required'
}

export const TRANSACTIONS_STATUS: TransactionsStatus = {
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_ALL]: 'All',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_PENDING]: 'Pending',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_SENDING]: 'Sending',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_WAITING]: 'Waiting',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_CONFIRMED]: 'Confirmed',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_COMPLETE]: 'Complete',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_PROCESSED]: 'Processed',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_FAILED]: 'Failed',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_CANCELED]: 'Canceled',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_SKIPPED]: 'Skipped',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_DUST]: 'Dust',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_PROCESSING]: 'Processing',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_CHECK_REQUIRED]: 'Check required',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_APPROVE_REQUIRED]: 'Approve required',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_REFUND]: 'Refund',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_REFUND_REQUIRED]: 'Refund required',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_REFUNDED]: 'Refunded',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_REFUNDING]: 'Refunding',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXPIRED]: 'Expired',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXCHANGE_WAITING]: 'Exchange waiting',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXCHANGING]: 'Exchanging',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXCHANGE_SENDING]: 'Sending',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXCHANGE_FAILED]: 'Failed'
};

export const TRANSACTIONS_STATUS_CLASSES: TransactionsStatus = {
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_ALL]: 'all',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_PENDING]: 'pending',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_SENDING]: 'sending',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_WAITING]: 'waiting',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_CONFIRMED]: 'confirmed',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_COMPLETE]: 'complete',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_PROCESSED]: 'processed',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_FAILED]: 'failed',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_CANCELED]: 'canceled',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_SKIPPED]: 'skipped',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_DUST]: 'dust',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_PROCESSING]: 'processing',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_CHECK_REQUIRED]: 'check_required',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_APPROVE_REQUIRED]: 'approve_required',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_REFUND]: 'refund',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_REFUND_REQUIRED]: 'refund_required',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_REFUNDED]: 'refunded',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_REFUNDING]: 'refunding',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXPIRED]: 'expired',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXCHANGE_WAITING]: 'waiting',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXCHANGING]: 'processing',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXCHANGE_SENDING]: 'sending',
  [TRANSACTIONS_STATUSES.TRANSACTION_STATUS_EXCHANGE_FAILED]: 'failed'
};
