import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { AlertType, alertActions } from 'entities/Alert';
import {
  NewTransactionError,
  NewTransactionResponse
} from 'features/NewTransactionForm/model/types/newTransaction/newTransaction';
import { getDataToCreateNewTransaction } from 'features/NewTransactionForm/model/selectors/newTransaction/newTransaction';
import { GET_NEW_TRANSACTION_ERROR } from 'features/NewTransactionForm/lib/errors/newTransaction/newTransactionErrors';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const createNewTransaction = createAsyncThunk<
  NewTransactionResponse,
  undefined,
  ThunkConfig<NewTransactionError | undefined>
>('newTransaction/createNewTransaction', async (_, { rejectWithValue, dispatch, extra, getState }) => {
  try {
    const dataToCreateNewTransaction = getDataToCreateNewTransaction(getState());

    const response = await extra.apiWithAuth.post<NewTransactionResponse>(
      'fiat/v1/transaction',
      JSON.stringify(dataToCreateNewTransaction),
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );

    if (!response.data) {
      throw new Error();
    }

    return response.data;
  } catch (e) {
    const error = e as MyAxiosError<NewTransactionError>;

    if (error.response) {
      const errorMessage =
        error.response?.data.error ?? error.response?.data.message.replace('_', ' ') ?? 'Server error';

      dispatch(
        alertActions.setAlert({
          id: crypto.randomUUID(),
          message: errorMessage,
          timeout: 5000,
          type: AlertType.ERROR
        })
      );
      return rejectWithValue(error.response.data);
    }

    return rejectWithValue(undefined);
  }
});
