import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError, ResponseError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { AlertType, alertActions } from 'entities/Alert';
import {
  CreateExchangeRateEstimateResponse
} from 'features/ExchangeRate/model/types/exchangerate-estimate';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const createExchangeRateEstimate = createAsyncThunk<CreateExchangeRateEstimateResponse,
undefined,
ThunkConfig<MyAxiosError<ResponseError>>>('exchangeRate/createExchangeRateEstimate', async (_, {
  rejectWithValue,
  dispatch,
  extra,
  getState
}) => {
  try {
    const data = getState().exchangeRate?.exchangeRateEstimateData;
    const response = await extra.apiWithAuth.post<CreateExchangeRateEstimateResponse>('/v1/exchangerate/estimate',
      JSON.stringify(data), {
        headers: {
          'Content-Type': 'application/json'
        }
      });

    if (!response.data) {
      throw new Error();
    }

    return response.data;
  } catch (e) {
    const error = e as MyAxiosError<ResponseError>;

    const errorMessage = error.response?.data.message || 'Server error';

    dispatch(
      alertActions.setAlert({
        id: crypto.randomUUID(),
        message: errorMessage,
        timeout: 5000,
        type: AlertType.ERROR
      })
    );
    return rejectWithValue(e as MyAxiosError<ResponseError>);
  }
});
