import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { CreateNewWithdrawalResponse, ResponseError } from '../types/new-withdrawal';
import { getDataToCreateNewWithdrawal } from '../selectors/new-withdrawal';
import {
  CRYPTO_SHORT_NAME,
  CRYPTO_SHORT_NAMES,
  CRYPTO_VALUES_TO_DIVIDE_WITHDRAWAL,
  CryptoName
} from 'shared/constants/crypto/crypto-names';
import BigNumber from 'bignumber.js';
import { AlertType, alertActions } from 'entities/Alert';
import { withdrawalEstimateActions } from '../slices/withdrawal-estimate';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const createNewWithdrawal = createAsyncThunk<CreateNewWithdrawalResponse,
undefined,
ThunkConfig<MyAxiosError<ResponseError>>>('newWithdrawal/createNewWithdrawal', async (_, {
  rejectWithValue,
  extra,
  getState,
  dispatch
}) => {
  dispatch(alertActions.clearAll());

  try {
    const newTransactionData = getDataToCreateNewWithdrawal(getState());

    const multiplier =
      CRYPTO_VALUES_TO_DIVIDE_WITHDRAWAL[(newTransactionData?.code?.toUpperCase() as CRYPTO_SHORT_NAMES) || ''];
    const num2 = new BigNumber(multiplier);

    const newDestination = newTransactionData?.destination?.map((wallet) => {
      const num1 = new BigNumber(wallet.amount);

      const newAmount = num1.multipliedBy(num2).toFixed();
      // newAmount.precision(newAmount.toFixed().length - 4, BigNumber.ROUND_FLOOR).toFixed()
      return {
        ...wallet,
        amount: newAmount.split('.')[0]
      };
    });

    const data = {
      fromAddress: newTransactionData?.fromAddress,
      code: newTransactionData?.code,
      destination: newDestination
    };

    const response = await extra.apiWithAuth.post<CreateNewWithdrawalResponse>('/v1/withdrawal', JSON.stringify(data), {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.data) {
      throw new Error();
    }

    return response.data;
  } catch (e) {
    const error = e as MyAxiosError<ResponseError>;
    let errorMessage = error.response?.data.message || 'Server Error';

    if (error.response?.data.code === 10) {
      errorMessage = `${
        CRYPTO_SHORT_NAME[error.response.data.details[0].code as CryptoName]
      } required for transaction: insufficient funds.<br/>Needed is ${error.response.data.details[0].amountUnit} ${
        CRYPTO_SHORT_NAME[error.response.data.details[0].code as CryptoName]
      }`;
    }

    if (error.response?.status.toString()[0] === '4') {
      dispatch(
        alertActions.setAlert({
          id: crypto.randomUUID(),
          message: errorMessage,
          timeout: 5000,
          type: AlertType.WARNING,
          infinite: true
        })
      );
    } else {
      dispatch(
        alertActions.setAlert({
          id: crypto.randomUUID(),
          message: errorMessage,
          timeout: 5000,
          type: AlertType.ERROR
        })
      );
    }

    dispatch(withdrawalEstimateActions.setResetData());

    return rejectWithValue(e as MyAxiosError<ResponseError>);
  }
});
