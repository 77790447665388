import {
  LOCAL_STORAGE_ACCESS_TOKEN,
  LOCAL_STORAGE_IS_REDIRECTED_TO_KEYCLOAK,
  LOCAL_STORAGE_REFRESH_TOKEN,
  LOCAL_STORAGE_USER_DATA
} from 'shared/constants/localstorage/localstorage';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { MyAxiosError } from 'shared/types/axios';
import { authorization } from './authorization';

export const logout = createAsyncThunk<any, undefined, ThunkConfig<MyAxiosError<any>>>(
  'user/logout',
  async (_, { rejectWithValue, extra, dispatch }) => {
    try {
      const refresh_token = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);

      const body = {
        refresh_token
      };

      const response = await extra.api.post('/v1/auth/logout', body, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      localStorage.removeItem(LOCAL_STORAGE_ACCESS_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_USER_DATA);
      localStorage.removeItem(LOCAL_STORAGE_IS_REDIRECTED_TO_KEYCLOAK);
      dispatch(authorization({}));
    } catch (e) {
      return rejectWithValue(e as MyAxiosError<any>);
    }
  }
);
