import React, { FC, HTMLAttributes, ReactNode } from 'react';
import styles from './Box.module.scss';
import classNames from 'classnames';

export enum BoxDirection {
  COLUMN = 'column',
  ROW = 'row'
}

export enum BoxAlign {
  FLEX_START = 'align_flex_start',
  CENTER = 'align_center'
}

export enum BoxJustify {
  FLEX_START = 'justify_flex_start',
  CENTER = 'justify_center',
  FLEX_END = 'justify_flex_end'
}

export enum BoxMargin {
  NONE = '',
  MT_05EM = 'mt_05em',
  MT_1EM = 'mt_1em',
  MT_2EM = 'mt_2em'
}

interface BoxProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: ReactNode;
  direction?: BoxDirection;
  align?: BoxAlign;
  justify?: BoxJustify;
  margin?: BoxMargin;
}

const Box: FC<BoxProps> = (props) => {
  const {
    className,
    children,
    direction = BoxDirection.COLUMN,
    align = BoxAlign.FLEX_START,
    justify = BoxJustify.FLEX_START,
    margin = BoxMargin.NONE,
    ...otherProps
  } = props;

  const mods = {
    [styles[direction]]: direction,
    [styles[align]]: align,
    [styles[justify]]: justify,
    [styles[margin]]: margin
  };

  return (
    <div className={classNames(styles.box, mods, [className])} {...otherProps}>
      {children}
    </div>
  );
};

export { Box };
