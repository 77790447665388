import { LOCAL_STORAGE_ACCESS_TOKEN, LOCAL_STORAGE_REFRESH_TOKEN } from 'shared/constants/localstorage/localstorage';
import { AuthTokensResponse } from '../types/user';
import { $api } from 'shared/api/api';

export const getRefreshTokens = async () => {
  const refresh_token = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN);

  const body = {
    refresh_token
  };

  const response = await $api.post<AuthTokensResponse>('/v1/auth/refresh', body, {
    headers: {
      'Content-Type': 'application/json'
    }
  });

  localStorage.setItem(LOCAL_STORAGE_ACCESS_TOKEN, response.data.access_token as string);
  localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, response.data.refresh_token as string);

  return response.data;
};
