import { FC, useCallback, useEffect } from 'react';
import styles from './Navbar.module.scss';
import classNames from 'classnames';
import { BurgerMenu } from 'shared/ui/BurgerMenu/BurgerMenu';
import { useSelector } from 'react-redux';
import { UserInfo } from '../UserInfo/UserInfo';
import { getIsUserLoading } from 'entities/User/model/selectors/user';
import { Text } from 'shared/ui/Text/Text';
import { LOCAL_STORAGE_SIDEBAR } from 'shared/constants/localstorage/localstorage';
import { Logo } from 'shared/ui/Logo/Logo';
import { Link } from 'react-router-dom';
import { useWindowSize } from 'shared/lib/hooks/useWindowSize/useWindowSize';
import { getSignboardText } from 'shared/lib/utils';

interface NavbarProps {
  isSticky: boolean;
  setIsSticky: React.Dispatch<React.SetStateAction<boolean>>;
  isSidebarOpen: boolean;
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Navbar: FC<NavbarProps> = (props) => {
  const { isSticky, setIsSticky, isSidebarOpen, setIsSidebarOpen } = props;
  const isUserLoading = useSelector(getIsUserLoading);

  const [windowWidth] = useWindowSize();

  const handleSidebarSwitcher = useCallback(() => {
    localStorage.setItem(LOCAL_STORAGE_SIDEBAR, String(!isSidebarOpen));
    setIsSidebarOpen(!isSidebarOpen);
  }, [isSidebarOpen]);

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const mods = {
    [styles.sidebarOpen]: isSidebarOpen,
    [styles.sticky]: isSticky
  };

  const withSighboard = (getSignboardText() && isSticky) ? styles.withSighboard : '';

  return (
    <header className={classNames(styles.navbar, mods, [], withSighboard)}>
      <BurgerMenu active={isSidebarOpen} onClick={handleSidebarSwitcher} showCloseIcon={windowWidth <= 1440} />
      {isUserLoading ? (
        <Text size='sizeM'>Loading...</Text>
      ) : (
        <>
          <UserInfo className={styles.userInfo} />
          <Link to='/' className={styles.linkLogo} onClick={() => setIsSidebarOpen(false)}>
            <Logo size='sizeM' />
          </Link>
        </>
      )}
    </header>
  );
};

export { Navbar };
