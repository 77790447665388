import { Option } from 'shared/ui/CustomSelect/CustomSelect';

export const CURRENT_DATE = new Date();
export const CURRENT_MONTH = new Date().getMonth();
export const PREVIOUS_MONTH = CURRENT_MONTH - 1;
export const CURRENT_YEAR = new Date().getFullYear();

export const FIRST_DATE_OF_CURRENT_MONTH = new Date(CURRENT_DATE.getFullYear(), CURRENT_MONTH, 1);
export const LAST_DATE_OF_CURRENT_MONTH = new Date(CURRENT_DATE.getFullYear(), CURRENT_MONTH + 1, 0);

export const FIRST_DAY_OF_CURRENT_MONTH = FIRST_DATE_OF_CURRENT_MONTH.getDay();
export const LAST_DAY_OF_CURRENT_MONTH = LAST_DATE_OF_CURRENT_MONTH.getDay();

export const FIRST_DATE_OF_LAST_MONTH = new Date(CURRENT_DATE.getFullYear(), PREVIOUS_MONTH, 1);
export const LAST_DATE_OF_LAST_MONTH = new Date(CURRENT_DATE.getFullYear(), CURRENT_MONTH, 0);

export const FIRST_DAY_OF_LAST_MONTH = FIRST_DATE_OF_LAST_MONTH.getDay();
export const LAST_DAY_OF_LAST_MONTH = LAST_DATE_OF_LAST_MONTH.getDay();

export const FIRST_DATE_OF_CURRENT_MONTH_MILLISECONDS = FIRST_DATE_OF_CURRENT_MONTH.getMilliseconds();
export const LAST_DATE_OF_CURRENT_MONTH_MILLISECONDS = LAST_DATE_OF_CURRENT_MONTH.getMilliseconds();
export const FIRST_DATE_OF_LAST_MONTH_MILLISECONDS = FIRST_DATE_OF_LAST_MONTH.getMilliseconds();
export const LAST_DATE_OF_LAST_MONTH_MILLISECONDS = LAST_DATE_OF_LAST_MONTH.getMilliseconds();

export const FIRST_DATE_OF_CURRENT_MONTH_TIME = FIRST_DATE_OF_CURRENT_MONTH.getTime();
export const LAST_DATE_OF_CURRENT_MONTH_TIME = LAST_DATE_OF_CURRENT_MONTH.getTime();
export const FIRST_DATE_OF_LAST_MONTH_TIME = FIRST_DATE_OF_LAST_MONTH.getTime();
export const LAST_DATE_OF_LAST_MONTH_TIME = LAST_DATE_OF_LAST_MONTH.getTime();

export const getMonth = (date: Date) => {
  const month = date.getMonth();

  return months[month];
};

// export const months: Option[] = [
//   {
//     label: 'January',
//     value: 'January'
//   },
//   {
//     label: 'February',
//     value: 'February'
//   },
//   {
//     label: 'March',
//     value: 'March'
//   },
//   {
//     label: 'April',
//     value: 'April'
//   },
//   {
//     label: 'May',
//     value: 'May'
//   },
//   {
//     label: 'June',
//     value: 'June'
//   },
//   {
//     label: 'July',
//     value: 'July'
//   },
//   {
//     label: 'August',
//     value: 'August'
//   },
//   {
//     label: 'September',
//     value: 'September'
//   },
//   {
//     label: 'October',
//     value: 'October'
//   },
//   {
//     label: 'November',
//     value: 'November'
//   },
//   {
//     label: 'December',
//     value: 'December'
//   }
// ];

export const months: Option[] = [
  {
    label: 'January',
    value: '0'
  },
  {
    label: 'February',
    value: '1'
  },
  {
    label: 'March',
    value: '2'
  },
  {
    label: 'April',
    value: '3'
  },
  {
    label: 'May',
    value: '4'
  },
  {
    label: 'June',
    value: '5'
  },
  {
    label: 'July',
    value: '6'
  },
  {
    label: 'August',
    value: '7'
  },
  {
    label: 'September',
    value: '8'
  },
  {
    label: 'October',
    value: '9'
  },
  {
    label: 'November',
    value: '10'
  },
  {
    label: 'December',
    value: '11'
  }
];
