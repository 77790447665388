import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError, ResponseError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { GetAffiliateReportResponse } from '../../../../model/types/affiliate/report/affiliateReport';
import {
  getAffiliateReportCurrencyFilter,
  getAffiliateReportDateFilter,
  getAffiliateReportPeriodGroupFilter
} from '../../../../model/selectors/affiliate/report/affiliateReport';

interface GetAffiliateReportProps {
  searchParams?: URLSearchParams;
}

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const getAffiliateReport = createAsyncThunk<
  GetAffiliateReportResponse,
  GetAffiliateReportProps,
  ThunkConfig<MyAxiosError<ResponseError>>
>('affiliate/getAffiliateReport', async ({ searchParams }, { rejectWithValue, extra, getState }) => {
  const code = getAffiliateReportCurrencyFilter(getState());
  const date = getAffiliateReportDateFilter(getState());
  const groupPeriod = getAffiliateReportPeriodGroupFilter(getState());

  const sinceTime = searchParams?.get('since');
  const untilTime = searchParams?.get('until');

  const params = new URLSearchParams();

  if (code) {
    params.append('code', code);
  }

  if (groupPeriod) {
    params.append('periodGroup', groupPeriod);
  }

  let dateFrom = '';
  let dateTo = '';

  if (date?.dateFrom && date.dateTo) {
    const { adjustTillDate } = await import('shared/lib/timeFunctions/dateFilter/ISOformat/adjustTillDate');
    const { since, till } = adjustTillDate(new Date(date.dateFrom), new Date(date.dateTo));

    dateFrom = since;
    dateTo = till;
    params.append('since', since);
    params.append('until', till);
  }

  try {
    const { addQueryParams } = await import('shared/lib/requests/params/addQueryParams/addQueryParams');

    if (!sinceTime && !untilTime) {
      addQueryParams({ since: dateFrom, until: dateTo });
    }

    addQueryParams({ code, groupPeriod });

    const response = await extra.apiWithAuth.get<GetAffiliateReportResponse>('/v1/affiliate/report/crypto', {
      params,
      headers: {
        Accept: 'application/json'
      }
    });

    if (!response.data) {
      throw new Error();
    }

    return response.data;
  } catch (e) {
    const error = e as MyAxiosError<ResponseError>;
    return rejectWithValue(error);
  }
});
