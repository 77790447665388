import { createAsyncThunk } from '@reduxjs/toolkit';
import { MyAxiosError, ResponseError } from 'shared/types/axios';
import { ThunkConfig } from 'app/providers/StoreProvider';
import { AlertType, alertActions } from 'entities/Alert';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line @typescript-eslint/no-invalid-void-type
export const rejectTransaction = createAsyncThunk<void, number, ThunkConfig<MyAxiosError<ResponseError>>>(
  'paymentGatewayTransactionDetails/rejectTransaction',
  async (id, { rejectWithValue, dispatch, extra }) => {
    try {
      const response = await extra.apiWithAuth.patch(`/fiat/v1/transaction/${id}/reject`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });

      dispatch(
        alertActions.setAlert({
          id: uuidv4(),
          message: 'Transaction successfully rejected!',
          timeout: 3000,
          type: AlertType.SUCCESS
        })
      );

      setTimeout(() => {
        window.location.reload();
      }, 3000);

      if (!response.data) {
        throw new Error();
      }
    } catch (e) {
      const error = e as MyAxiosError<ResponseError>;
      return rejectWithValue(error);
    }
  }
);
