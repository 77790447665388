import { createSlice } from '@reduxjs/toolkit';
import { getWithdrawalById } from '../services/getTransactionById';
import { PaymentGatewayWithdrawalDetailsSchema } from '../types/withdrawal-details';
import { cancelTransaction } from '../services/cancelTransaction';

const initialState: PaymentGatewayWithdrawalDetailsSchema = {
  withdrawalData: undefined,
  error: undefined,
  isLoading: false,
  isSuccess: false,

  cancelResponseData: undefined,
  isCanceling: false,
  isCancelSuccess: false
};

export const paymentGatewayWithdrawalDetailsSlice = createSlice({
  name: 'paymentGatewayWithdrawalDetails',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWithdrawalById.pending, (state) => {
        state.error = undefined;
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(getWithdrawalById.fulfilled, (state, action) => {
        state.error = undefined;
        state.isLoading = false;
        state.isSuccess = true;
        state.withdrawalData = action.payload;
      })
      .addCase(getWithdrawalById.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.isSuccess = false;
      })

      .addCase(cancelTransaction.pending, (state) => {
        state.error = undefined;
        state.isCanceling = true;
        state.isCancelSuccess = false;
      })
      .addCase(cancelTransaction.fulfilled, (state, action) => {
        state.error = undefined;
        state.isCanceling = false;
        state.isCancelSuccess = true;
        state.cancelResponseData = action.payload;
      })
      .addCase(cancelTransaction.rejected, (state, action) => {
        state.error = action.payload;
        state.isCanceling = false;
        state.isCancelSuccess = false;
      });
  }
});

export const { actions: paymentGatewayWithdrawalDetailsActions } = paymentGatewayWithdrawalDetailsSlice;
export const { reducer: paymentGatewayWithdrawalDetailsReducer } = paymentGatewayWithdrawalDetailsSlice;
